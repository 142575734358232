
import { InteractionRequiredAuthError } from "@azure/msal-browser";

/**
 * Get an access token for speaking with the API.
 *
 * @returns {Promise<string>} - An access token
 */
async function getAccessTokenAsync(msalInstance) {  
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    // user is not signed in, throw error
    throw new Error("User is not signed in");
  }
  let scopesData = await (await fetch("/api/v1/system/msal/scopes")).json()
  const request = {
    scopes: scopesData.scopes,
    account: activeAccount || accounts[0],
  };

  try {
    // don't let this fool you, MSAL has an internal cache and will
    // get a token without network requests if the one in the cache
    // is still valid
    var tokenResponse = await msalInstance.acquireTokenSilent(request);    
    
    return tokenResponse.accessToken;
  } catch (error) {    
    if (error instanceof InteractionRequiredAuthError) {
      // fallback to user interaction      
      return await msalInstance.acquireTokenRedirect(request);
    }
  }
}

/**
 * Returns true if response status is 2xx
 * @param {Response} response - Response from the fetch.
 */
function isSuccessful(response) {
  return response.status >= 200 && response.status < 300;
}

async function getActiveAccount(msalInstance) {  
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    // user is not signed in, throw error
    throw new Error("User is not signed in");
  } else {
    var account = activeAccount || accounts[0];
  }
  return account;
}

export default {
  getAccessTokenAsync,
  isSuccessful,
  getActiveAccount,
};
