import React, { Fragment, useState, useRef } from "react";
import PropTypes from "prop-types";

import { Button, Modal, Form } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import Spinner from "../../components/Spinner";
import api from "./usersApi";
import { useMsal } from "@azure/msal-react";

/**
 * Callback for hiding the invite modal.
 *
 * @callback onHideCallback
 */

/**
 * Callback for successful inviting a user.
 *
 * @callback onSuccessCallback
 */

/**
 * Callback for failed to invite a user.
 *
 * @callback onFailureCallback
 * @param {string} errorMessage - A message describing what went wrong.
 */

/**
 * Show an invite modal with a form and a submit button.
 * @param {onHideCallback} onHide - Gets called when user closes the modal.
 * @param {onSuccessCallback} onSuccess - Gets called when user successfully invites a user.
 * @param {onFailureCallback} onFailure - Gets called when invitation fails.
 * @returns
 */
function InviteModal({ show, onHide, onSuccess, onFailure }) {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  const formRef = useRef();
  const nameRef = useRef();
  const emailRef = useRef();

  async function inviteUser() {
    // validate the form input
    const form = formRef.current;
    if (form.checkValidity() == false) {
      // form input not valid
    } else {
      // form is valid
      setIsLoading(true);
      const invitation = {
        displayName: nameRef.current.value,
        email: emailRef.current.value,
      };
      try {
        const invite = await api.inviteUserAsync(instance, invitation);
        onSuccess && onSuccess(invite);
      } catch (error) {
        onFailure && onFailure(error.message);
      } finally {
        onHide();
      }
    }

    // form has been validated
    setValidated(true);
  }

  return (
    <Modal show={show} onHide={onHide} data-testid="invite-modal">
      <Modal.Header closeButton>
        <Modal.Title>Bjud in användare</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          validated={validated}
          onSubmit={inviteUser}
          ref={formRef}
        >
          <Form.Group controlId="nameInput">
            <Form.Label>Namn*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Namn"
              required
              ref={nameRef}
            />
            <Form.Control.Feedback type="valid">
              Bra jobbat!
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Du måste ange ett namn.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="emailInput">
            <Form.Label>E-post*</Form.Label>
            <Form.Control
              type="email"
              placeholder="E-post"
              required
              ref={emailRef}
            />
            <Form.Control.Feedback type="valid">
              Du fixar det här!
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Du måste ange en e-post.
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="mute"
          onClick={onHide}
          data-testid="invite-modal-close-button"
        >
          Avbryt
        </Button>
        <Button variant="primary" disabled={isLoading} onClick={inviteUser}>
          {isLoading ? <Spinner /> : "Skicka"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

InviteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
};

/**
 * Open a modal for invites.
 * @param {onSuccessCallback} onSuccess - Gets called when user successfully invites a user.
 * @param {onFailureCallback} onFailure - Gets called when invitation fails.
 */
function InviteButton({ onSuccess, onFailure, ...props }) {
  const [isVisible, setIsVisible] = useState(false);

  function openInviteModal() {
    setIsVisible(true);
  }

  function hideModal() {
    setIsVisible(false);
  }

  return (
    <Fragment>
      <Button variant="primary" onClick={openInviteModal} {...props}>
        <AddIcon /> Bjud in användare
      </Button>
      {isVisible && (
        <InviteModal
          show={isVisible}
          onHide={hideModal}
          onSuccess={onSuccess}
          onFailure={onFailure}
        />
      )}
    </Fragment>
  );
}

InviteButton.propTypes = {
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
};

export default InviteButton;
