import React from 'react';
import PropTypes from 'prop-types';

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';
import { Navbar, NavDropdown } from 'react-bootstrap';
import RefundButton from './RefundButton';
import SendButton from './SendTicketButton';
import BlacklistTicketButton from './BlacklistTicketButton';
import RescheduleButton from './RescheduleButton';
import { BackButton } from '../../../components/BackButton';
import SendReceiptButton from './SendReceiptButton';

function NavbarWithButtons({ ticketData, onFailure, onSuccess, tripData }) {
  const { instance } = useMsal();

  function getActiveAccount() {
    const activeAccount = instance.getActiveAccount();
    const accounts = instance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      // user is not signed in, throw error
      throw new Error('User is not signed in');
    } else {
      return activeAccount || accounts[0];
    }
  }

  const hasZeroPrice = () => ticketData.amount === '0';

  function getAccountName() {
    const account = getActiveAccount();
    return account.name;
  }

  function handleLogout() {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }
  function isButtonDisabled() {
    var isButtonDisabled = false;
    if (
      ticketData.status == 'Återbetalad' ||
      ticketData.status == 'Återbetalning pågår' ||
      ticketData.status == 'Spärrad' ||
      tripData[tripData.length - 1]?.hasArrived
    ) {
      isButtonDisabled = true;
    }
    // AB#3130 Enable navbar buttons for ticket (temporary fix)
    return false && isButtonDisabled;
  }
  return (
    <>
      <UnauthenticatedTemplate></UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Navbar color="navbar-bg p-0">
          <BackButton />
          {ticketData && tripData && (
            <>
              <RescheduleButton ticketData={ticketData} tripData={tripData} isButtonDisabled={true} />
              <SendButton ticketData={ticketData} onFailure={onFailure} onSuccess={onSuccess} />
              <RefundButton 
                ticketData={ticketData}
                tripData={tripData}
                isButtonDisabled={isButtonDisabled() || hasZeroPrice()}
                onFailure={onFailure}
                onSuccess={onSuccess}
              />
              <BlacklistTicketButton
                ticketData={ticketData}
                onFailure={onFailure}
                isButtonDisabled={true} //{isButtonDisabled()}
                onSuccess={onSuccess}
                tripData={tripData}
              />
              <SendReceiptButton ticketData={ticketData} onFailure={onFailure} onSuccess={onSuccess} />
            </>
          )}
          <Navbar.Collapse id="settings-navbar-nav" className="justify-content-end">
            <NavDropdown title={getAccountName()} id="settings-navbar-dropdown" className="text-dark">
              <NavDropdown.Item href="#logout" onClick={handleLogout}>
                Logga ut
              </NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>
        </Navbar>
      </AuthenticatedTemplate>
    </>
  );
}

NavbarWithButtons.propTypes = {
  onFailure: PropTypes.func,
  onSuccess: PropTypes.func,
  ticketData: PropTypes.object,
  tripData: PropTypes.array,
};

export default NavbarWithButtons;
