import React from "react";
import PropTypes from "prop-types";

import { Badge } from "react-bootstrap";

/**
 * Show a tag to the data in a table cell.
 *
 * @component
 * @param {string} name - Name of the tag.
 * @param {string} bg - Background color.
 * @param {string} text - Text color.
 */
function Tag({ name, bg = "primary", text = "white", className }) {
  return (
    <Badge className={`bg-${bg} text-${text} mr-2 ${className}`}>{name}</Badge>
  );
}

const colors = [
  "primary",
  "secondary",
  "success",
  "danger",
  "warning",
  "info",
  "ligth",
  "dark",
  "muted",
  "white",
];

Tag.propTypes = {
  name: PropTypes.string.isRequired,
  bg: PropTypes.oneOf(colors),
  text: PropTypes.oneOf(colors),
  className: PropTypes.string,
};

export default Tag;
