import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import './assets/css/corporate.css'
import './assets/css/styles.css'
import App from './App';

import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import store from './redux/store'
import { Provider } from 'react-redux'

fetch("/api/v1/system/msal")
  .then((res) => res.json())
  .then((data) =>{
    sessionStorage.setItem("system-msal", JSON.stringify(data));
    const msalInstance = new PublicClientApplication(data);

    ReactDOM.render(
      <React.StrictMode>
        <MsalProvider instance={msalInstance}>
          <Provider store={store}>
            <App />
          </Provider>
        </MsalProvider>
      </React.StrictMode>,
      document.getElementById('root')
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  });
