import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";

import UtilityButton from "./UtilityButton";

/**
 * Open audit log with the attached query.
 *
 * @component
 * @param {string} query - The query to search for in the audit log.
 */
function AuditQueryLink({ query }) {
  const history = useHistory();

  // navigate on click
  function navigateToAudit(query) {
    return () => {
      history.push(`/audit/${query}`);
    };
  }

  return (
    <UtilityButton
      title="Öppna i händelseloggen"
      onClick={navigateToAudit(query)}
    >
      <p style={{ color: "blue" }}>Öppna i händelseloggen</p>
    </UtilityButton>
  );
}

AuditQueryLink.propTypes = {
  query: PropTypes.string.isRequired,
};

export default AuditQueryLink;
