import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import { Button, Modal, Form } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";

import api from "../../../hooks/ticketApi";
import Spinner from "../../../components/Spinner";

/**
 * Callback for hiding the send modal.
 *
 * @callback onHideCallback
 */

/**
 * Callback for successful inviting a user.
 *
 * @callback onSuccessCallback
 */

/**
 * Callback for failed to send a user.
 *
 * @callback onFailureCallback
 * @param {string} errorMessage - A message describing what went wrong.
 */

/**
 * Show an send modal with a form and a submit button.
 * @param {onHideCallback} onHide - Gets called when user closes the modal.
 * @param {onSuccessCallback} onSuccess - Gets called when user successfully sends a user.
 * @param {onFailureCallback} onFailure - Gets called when invitation fails.
 * @returns
 */
function SendModal({ show, onHide, onSuccess, onFailure, id }) {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  const emailRef = useRef();
  const formRef = useRef();

  function validateInput() {
    const form = formRef.current;
    if (form.checkValidity() == true) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  }

  async function sendTicket() {
    // validate the form input
    const form = formRef.current;
    if (form.checkValidity() == false) {
      // form input not valid
    } else {
      // form is valid
      setIsLoading(true);

      try {
        await api.sendTicketAsync(instance, emailRef.current.value, id);
        onSuccess("Biljett skickad till mailkö");
        setIsLoading(false);
        onHide();
      } catch (error) {
        onFailure(error.message);
        setIsLoading(false);
        onHide();
      }
    }
    // form has been validated
    setValidated(true);
  }

  return (
    <Modal show={show} onHide={onHide} data-testid="send-modal">
      <Modal.Header closeButton>
        <Modal.Title>Skicka biljett</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form validated={validated} ref={formRef}>
          <Form.Group controlId="nameInput">
            <Form.Label>E-mail*</Form.Label>
            <Form.Control
              type="email"
              placeholder="E-mail"
              required
              onChange={validateInput}
              pattern="\S+@\S+\.\S+"
              ref={emailRef}
            />
            <Form.Control.Feedback type="valid" />
            <Form.Control.Feedback type="invalid">
              Du måste ange en giltig mailadress.
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="mute"
          onClick={onHide}
          data-testid="send-modal-close-button"
        >
          Avbryt
        </Button>
        <Button variant="primary" disabled={isLoading} onClick={sendTicket}>
          {isLoading ? <Spinner /> : "Skicka"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

SendModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  id: PropTypes.string,
};

/**
 * Open a modal for sends.
 * @param {onSuccessCallback} onSuccess - Gets called when user successfully sends a user.
 * @param {onFailureCallback} onFailure - Gets called when invitation fails.
 */
function SendTicketButton({
  onSuccess,
  onFailure,
  ticketData,
  isButtonDisabled,
}) {
  const [isVisible, setIsVisible] = useState(false);

  function openSendModal() {
    setIsVisible(true);
  }

  function hideModal() {
    setIsVisible(false);
  }

  return (
    <>
      <div className="ml-2">
        <Button disabled={isButtonDisabled} onClick={openSendModal}>
          <span className="material-icons">forward_to_inbox</span>
          <br />
          Skicka biljett
        </Button>
      </div>
      {isVisible && (
        <SendModal
          show={isVisible}
          onHide={hideModal}
          onSuccess={onSuccess}
          onFailure={onFailure}
          id={ticketData.correlationId}
        />
      )}
    </>
  );
}

SendTicketButton.propTypes = {
  isButtonDisabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  ticketData: PropTypes.object,
};

export default SendTicketButton;
