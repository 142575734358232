/**
 * @typedef sortOrder
 * @readonly
 * @enum {string|null}
 */
const sortOrder = {
  NONE: null,
  ASC: 'asc',
  DESC: 'desc',
};

export default sortOrder;
