import React, { Fragment, useState, useEffect } from "react";
import { Container, Card, Form, FormControl, InputGroup, Tooltip, OverlayTrigger, Button } from "react-bootstrap";

import SearchIcon from "@mui/icons-material/Search";
import ErrorDialog from "../../components/ErrorDialog";
import AccessMatrix from "./AccessMatrix";
import Api from "./usersApi";
import InviteButton from "./InviteButton";
import { sleepAsync } from "../../lib/asyncHelpers";
import { useMsal } from "@azure/msal-react";
import PropTypes from "prop-types";

/**
 * Display a user filter.
 * @param {onChangeCallback} onChange - Triggers when the filter updates.
 * @param {boolean} showInactive - Default state of show inactive filter.
 */
function UserFilter({ showInactive, onChange, updateUsers }) {
  function inactiveClick({ target }) {
    onChange({ showInactive: target.checked });
  }

  function freeTextFilterChange({ target }) {
    onChange({ freeTextFilter: target.value });
  }

  function onSubmit(e) {
    e.preventDefault();
    updateUsers(true);
  }

  return (
    <Fragment>
      <Form onSubmit={onSubmit}>
        <Form.Group controlId="freeTextFilter">
          <div className="row">
            <div className="col-12 col-md-12 col-xl-4 mb-2 mb-md-3">
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top" style={{ textAlign: "left" }}>Till ex:<br />
                namn (börjar med),<br />
                e-post (börjar med)<br />
              </Tooltip>}>
                <InputGroup >
                  <InputGroup.Prepend>
                    <InputGroup.Text><SearchIcon /></InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    placeholder="Sök namn eller e-post"
                    aria-label="Sök namn eller e-post"
                    aria-describedby="basic-addon2"
                    size="lg"
                    className="custom-clear-btn"
                    onChange={freeTextFilterChange}
                    type="search"
                  />
                </InputGroup>
              </OverlayTrigger>
            </div>
            <div className="col-12 col-sm col-xl-auto mb-2 mb-md-0">
              <Button className="btn btn-lg" type="submit">
                Sök
              </Button>
            </div>
            <div className="col">
              <Form.Group controlId="inactiveCheckboxGroup" className="float-right">
                <Form.Check
                  data-testid="user-filter-inactive-checkbox"
                  type="switch"
                  label="Visa inaktiva"
                  onChange={inactiveClick}
                  checked={showInactive}
                  className="align-self-center"
                />
              </Form.Group>
            </div>
          </div>
        </Form.Group>
      </Form>
    </Fragment>
  );
}

UserFilter.propTypes = {
  showInactive: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  updateUsers: PropTypes.func,
};

/**
 * This is the Access Control page.
 */
function Page() {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(true);
  const [userPages, setUserPages] = useState([[]]);
  const [error, setError] = useState(null);

  const [skipToken, setSkipToken] = useState(null);
  const [showInactive, setShowInactive] = useState(false);
  const [freeTextFilter, setFreeTextFilter] = useState(null);
  const PAGE_SIZE = 30;

  async function updateUsers(isNewSearch = false) {
    try {
      setIsLoading(true);

      const response = await Api.getUserPageAsync(
        instance,
        freeTextFilter,
        PAGE_SIZE,
        isNewSearch ? null : skipToken // If new search then don't use skipToken
      );

      // If first page load or a new search has been made
      if (!userPages[0].length || isNewSearch) {
        setUserPages([response.users]);
      } else {
        setUserPages([...userPages, response.users]);
      }
      setSkipToken(response.skipToken);
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  }

  async function successfulInvite(invite) {
    try {
      /* invite has a race condition as it is not guaranteed that Active Directory
      has been updated when the script requires a new list of users. So we have added a sleep onSuccess
      to somewhat mitigate that effect.
    */
      for (let i = 0; i < 10; i++) {
        await sleepAsync(2500);
        var updatedUsers = await Api.getAllUsersAsync(
          instance,
          invite.invitedUserDisplayName,
          PAGE_SIZE,
          null
        );

        // has the user turned up in the AD yet?
        if (updatedUsers.find((user) => user.id === invite.invitedUserId)) {
          setFreeTextFilter(invite.invitedUserDisplayName);
          updateUsers(true);
          break;
        } else if (i === 9) {
          throw new Error("Failed to update the user list after invite.");
        }
      }
    } catch (error) {
      setError(error.message);
    }
  }

  // load users when component is mounting
  useEffect(() => {
    updateUsers();
  }, []);

  function clearError() {
    setError(null);
  }

  function setFilter({ freeTextFilter, showInactive }) {
    if (showInactive !== undefined) {
      setShowInactive(showInactive);
    }

    if (freeTextFilter !== undefined) {
      setFreeTextFilter(freeTextFilter.trim().toLowerCase());
    }
  }

  return (
    <Fragment>
      <Container fluid className="p-0">
        <InviteButton
          className="float-end mt-n1"
          onSuccess={successfulInvite}
          onFailure={setError}
        />
        <h1 className="h3 mb-3">Användarhantering</h1>
        {error && (
          <ErrorDialog title="Ett fel inträffade" onClose={clearError}>
            {error}
          </ErrorDialog>
        )}
        <Card>
          <Card.Header>
            <UserFilter
              showInactive={showInactive}
              onChange={setFilter}
              updateUsers={updateUsers}
            />
          </Card.Header>
          <AccessMatrix
            userPages={userPages}
            onFailure={setError}
            onSuccess={updateUsers}
            lastPage={!skipToken}
            showInactive={showInactive}
            isLoading={isLoading}
          />
        </Card>
      </Container>
    </Fragment>
  );
}

export default Page;
