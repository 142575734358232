import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { ticketInfoIconMap } from "../assets/ticket-info-icon-map";

const NOTE_LIST_CLASSNAMES = classNames("list-unstyled", "m-0", "row");
const NOTE_LIST_ITEM_CLASSNAMES = classNames("py-1", "col-sm-5");
// const NOTE_LIST_APPLY_AT_CLASSNAMES = classNames(
//   "text-primary-dark",
//   "font-weight-bold",
//   "mt-3"
// );
const NOTE_CHEVRON_CLASSNAMES = classNames(
  "material-icons",
  "align-middle",
  "mr-4"
);
const TEXT_ALIGNMENT_CLASSNAMES = classNames("align-middle");

const defaultMaterialIconCode = ticketInfoIconMap.get("default");

function TripNotes({ tripNotes }) {
  return (
    <>
      {/* <div className={NOTE_LIST_APPLY_AT_CLASSNAMES}>{departureLocation}</div> */}
      <ul className={NOTE_LIST_CLASSNAMES}>
        {tripNotes.map((note, index) => {
          const noteCode = note.key ?? "default";
          const materialIconCode =
            ticketInfoIconMap.get(noteCode) ?? defaultMaterialIconCode;

          return (
            <li className={NOTE_LIST_ITEM_CLASSNAMES} key={noteCode + index}>
              <span className={NOTE_CHEVRON_CLASSNAMES} aria-hidden>
                {materialIconCode}
              </span>
              <span className={TEXT_ALIGNMENT_CLASSNAMES}>{note.value}</span>
            </li>
          );
        })}
      </ul>
    </>
  );
}

TripNotes.propTypes = {
  //   departureLocation: PropTypes.string,
  tripNotes: PropTypes.array,
};

export default TripNotes;
