import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";

import DownloadButton from "./downloadButton";
import ScheduleButton from "./scheduleButton";
import DeleteButton from "./deleteButton";
import ProductTemplateTable from "./productTemplateTable";
import Spinner from "../../components/Spinner";
const TicketMatrixStatus = {
  // just uploaded, you should never see this status
  Uploaded: "Uploaded",
  isUploaded: (status) => status === TicketMatrixStatus.Uploaded,
  // uploaded but didn't pass validation
  Invalid: "Invalid",
  isInvalid: (status) => status === TicketMatrixStatus.Invalid,
  // uploaded and passed validation, you should never see this status
  Valid: "Valid",
  isValid: (status) => status === TicketMatrixStatus.Valid,
  // uploaded and failed, crashed, while migrating into database
  Error: "Error",
  isError: (status) => status === TicketMatrixStatus.Error,
  // uploaded and successfully migrated into database
  Success: "Success",
  isSuccess: (status) => status === TicketMatrixStatus.Success,
  // active, as in customers can buy tickets from this matrix
  Active: "Active",
  isActive: (status) => status === TicketMatrixStatus.Active,
  // inactive, as was previously active, but has been superseeded
  Inactive: "Inactive",
  isInactive: (status) => status === TicketMatrixStatus.Inactive,
};

/**
 * Callback for successful delete of file.
 *
 * @callback onSuccessCallback
 */

/**
 * Callback for failed deletion of file.
 *
 * @callback onFailureCallback
 * @param {string} errorMessage - A message describing what went wrong.
 */

/**
 * A table row in the ticket matrix table.
 *
 * @param {object} matrix - The ticket matrix record that is to be rendered.
 * @param {onSuccessCallback} onSuccess - Will be called when state has been successfully updated.
 * @param {onFailureCallback} onFailure - Will be called when an operation on the data has failed.
 */
export function TicketMatrixTableRow({ matrix = {}, onSuccess, onFailure }) {
  const [isExpanded, setIsExpanded] = useState(false);

  function dateTimeFormat(dateStr) {
    if (dateStr == null) {
      return "-";
    } else {
      return `${dateFormat(dateStr)} ${new Date(
        Date.parse(dateStr)
      ).toLocaleTimeString("sv-SE", { timeStyle: "short" })}`;
    }
  }

  // format a ISO8601 string into a shorter localized version of a date
  function dateFormat(dateStr) {
    if (dateStr == null) {
      return "-";
    } else {
      return new Date(Date.parse(dateStr)).toLocaleDateString("sv-SE");
    }
  }

  function showMessage(message) {
    return message !== undefined && message !== null && message !== "";
  }

  // expand and collapse the ticket matrix details
  function toggleExpand() {
    setIsExpanded(!isExpanded);
  }

  function statusClassName(status) {
    switch (status) {
      case TicketMatrixStatus.Error:
      case TicketMatrixStatus.Invalid:
        return "table-danger text-danger";
      default:
        return "";
    }
  }

  function translateStatus(status) {
    switch (status) {
      case TicketMatrixStatus.Uploaded:
        return "Uppladdad";
      case TicketMatrixStatus.Invalid:
        return "Felaktig";
      case TicketMatrixStatus.Valid:
        return "Validerad";
      case TicketMatrixStatus.Error:
        return "Misslyckad";
      case TicketMatrixStatus.Success:
        return "Lyckad";
      case TicketMatrixStatus.Active:
        return "Aktiv";
      case TicketMatrixStatus.Inactive:
        return "Inaktiv";
      default:
        // unknown status, just pass it through
        return status;
    }
  }

  return (
    <Fragment>
      <tr
        data-testid="ticket-matrix-table-row"
        onClick={toggleExpand}
        className={statusClassName(matrix.status)}
        style={{ cursor: "pointer" }}
      >
        <td className="pr-0">
          {isExpanded ? (
            <ArrowDropDownRoundedIcon />
          ) : (
            <ArrowRightRoundedIcon />
          )}
        </td>
        <td className="text-truncate">{matrix.source}</td>
        <td>{dateTimeFormat(matrix.created)}</td>
        <td>{matrix.createdBy}</td>
        <td>{translateStatus(matrix.status)}</td>
        <td>{dateFormat(matrix.validFrom)}</td>
        <td className="table-action">
          <DownloadButton fileId={matrix.id} />
          <ScheduleButton
            fileId={matrix.id}
            onSuccess={onSuccess}
            onFailure={onFailure}
            isDisabled={!TicketMatrixStatus.isSuccess(matrix.status)}
          />
          <DeleteButton
            fileId={matrix.id}
            onSuccess={onSuccess}
            onFailure={onFailure}
            isDisabled={
              TicketMatrixStatus.isActive(matrix.status) ||
              TicketMatrixStatus.isInactive(matrix.status)
            }
          />
        </td>
      </tr>
      {isExpanded && (
        <tr className="border-0">
          <td colSpan="7">
            {showMessage(matrix.message) && (
              <div className="row">
                <h4>Meddelanden</h4>
                <pre>{matrix.message}</pre>
              </div>
            )}
            <ProductTemplateTable fileId={matrix.id} onFailure={onFailure} />
          </td>
        </tr>
      )}
    </Fragment>
  );
}

TicketMatrixTableRow.propTypes = {
  matrix: PropTypes.shape({
    id: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    createdBy: PropTypes.string,
    status: PropTypes.oneOf([
      TicketMatrixStatus.Active,
      TicketMatrixStatus.Error,
      TicketMatrixStatus.Inactive,
      TicketMatrixStatus.Invalid,
      TicketMatrixStatus.Success,
      TicketMatrixStatus.Uploaded,
      TicketMatrixStatus.Valid,
    ]).isRequired,
    validFrom: PropTypes.string,
    created: PropTypes.string,
    message: PropTypes.string,
    templates: PropTypes.array,
  }),
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
};

/**
 * Component for displaying the ticket matrix.
 *
 * @param {array} data - An array of matrix objects.
 * @param {onSuccessCallback} onSuccess - Callback when ticket matrix operations are successful.
 * @param {onFailureCallback} onFailure - Callback when ticket matrix operations fail.
 *
 */
function TicketMatrixTable({ data = [], isEmpty, isLoading, onSuccess, onFailure }) {
  return (
    <div className="table-responsive">
      <table className="table table-hover table-striped">
        <thead>
          <tr>
            <th className="pr-0">&nbsp;</th>
            <th>Namn</th>
            <th>Skapad</th>
            <th>Användare</th>
            <th>Status</th>
            <th>Aktiveringsdatum</th>
            <th>Funktioner</th>
          </tr>
        </thead>
        <tbody>
          {isEmpty && isLoading ? (
            <tr> <td colSpan="7"><Spinner /></td></tr>
          ) : (
            data.map((matrix, index) => (
              <TicketMatrixTableRow
                key={index}
                matrix={matrix}
                onSuccess={onSuccess}
                onFailure={onFailure}
              />
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}

TicketMatrixTable.propTypes = {
  data: PropTypes.array,
  isEmpty: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
};

export default TicketMatrixTable;
