import React from "react";
import PropTypes from "prop-types";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

/**
 * Indicate if the user has a role or not.
 *
 * @param {boolean} hasRole - Displays an empty circle if hasRole is false, or checkbox if hasRole is true.
 */
function RoleIndicator({ hasRole, isDisabled }) {
  const disabledClassName = isDisabled ? "text-muted" : "text-primary";

  return hasRole ? (
    <CheckCircleIcon
      className={disabledClassName}
      data-testid="role-indicator-approved"
    />
  ) : (
    <RadioButtonUncheckedIcon
      className={disabledClassName}
      data-testid="role-indicator-denied"
    />
  );
}

RoleIndicator.propTypes = {
  hasRole: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
};

export default RoleIndicator;
