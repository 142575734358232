import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import { Button, Modal, Form } from "react-bootstrap";

/**
 * Callback for hiding the refund modal.
 *
 * @callback onHideCallback
 */

/**

/**
 * Show an refund modal with a information text and a ok button.
 * @param {onHideCallback} onHide - Gets called when user closes the modal.
 * @param {onSuccessCallback} onSuccess - Gets called when customer confirms rebooking.
 * @returns
 */
function RescheduleModal({ show, onHide, onSuccess }) {
  const [validated, setValidated] = useState(false);

  const reasonRef = useRef();
  const formRef = useRef();

  function validateInput() {
    const form = formRef.current;
    if (form.checkValidity() == true) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  }

  // navigate on click
  function navigateToReschedule() {
    onSuccess(reasonRef.current.value);
    // let reason = reasonRef.current.value;
  }
  return (
    <Modal show={show} onHide={onHide} data-testid="refund-modal">
      <Modal.Header closeButton>
        <Modal.Title>Boka om biljett</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <p>
            Är du säker på att du vill boka om biljetten?
            <br />
            Efter genomförd ombokning kommer originalbiljetten spärras.
          </p>
        </div>
        <Form validated={validated} ref={formRef}>
          <Form.Group controlId="reasonInput">
            <Form.Label>Anledning till ombokning</Form.Label>
            <Form.Control
              ref={reasonRef}
              type="text"
              placeholder="Anledning"
              onChange={validateInput}
              required
            />
            <Form.Control.Feedback type="valid" />
            <Form.Control.Feedback type="invalid">
              Du måste ange anledning till ombokning
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="mute"
          onClick={onHide}
          data-testid="refund-modal-close-button"
        >
          Avbryt
        </Button>
        <Button variant="primary" onClick={navigateToReschedule}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

RescheduleModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  // ticketData: PropTypes.object.isRequired,
  // tripData: PropTypes.object.isRequired,
};

export default RescheduleModal;
