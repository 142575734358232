import React from "react";
/**
 * Immutable version of push. Will add item to start of array and return the new array.
 *
 * @param {any} item - Item we want to append.
 * @param {Array} arr - The array we want to append the item to.
 * @returns {Array} With item appended at the end.
 */
export function append(item, arr) {
  let copy = arr.slice();
  copy.unshift(item);
  return copy;
}

// make it easier to chain an append value to an array
// this is not very good, as it will affect all arrays everywhere.
// but it is neccessary
Array.prototype.append = function (item) {
  return append(item, this);
};

/**
 * Format a date string into ISO8601
 * @param {string} dateStr
 * @returns {string} Formatted date string.
 */
export function dateFormat(dateStr) {
  if (dateStr == null) {
    return "-";
  } else {
    return new Date(Date.parse(dateStr)).toLocaleString("sv-SE");
  }
}

var dateTimeOptions = {
  timezone: 'Europe/Stockholm',
  hour12: false,
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};
/**
 * Format a date string into locale date time string
 * @param {string} utcTime
 * @returns {string} Formatted date string yyyy-mm-dd hh:mm.
 */
export function toLocaleDateTimeStringNoSec(utcTime) {
  return new Date(utcTime).toLocaleString("sv-SE", dateTimeOptions);
}

export function adjustForTimezone(date) {
  var timeOffsetInMS = date.getTimezoneOffset() * 60000;
  date.setTime(date.getTime() - timeOffsetInMS);
  return toLocaleDateTimeStringNoSec(date);
}

/*export function utcToSwedishTime(utcTime) {
  
}*/

/**
 * Capitalize the first letter in the string
 * @param {string} str
 * @returns {string} Title case the first character of the string.
 */
export function toTitleCase(str) {
  if (str) {
    return str[0].toUpperCase() + str.substr(1);
  }
  return str;
}

/**
 * Orders the list of tickets based on their index
 * @param {any} tickets
 * @return {string} a list of sorted div elements 
 */
export function orderListDisplay(tickets) {
  let reversedTickets = tickets.slice();
  reversedTickets.reverse();

  if (tickets.length == 0) return <></>;

  return (
    <>
      {reversedTickets.map((ticket, index) => (
        <div className={determineRebookedStatus(ticket, index, ticket.length - 1)} key={ticket.id}>
          {formatTicketNumber(ticket.id)}{" "}
        </div>
      ))}
    </>
  );
}

/**
 * Determines which ticket number is valid
 * @param {any} ticket
 * @returns {string} The active ticket number
 */
export function getValidTicketNumber(ticket) {
  if (ticket.length === 0)
    return '';
  if (ticket[0]?.index !== null) {
    return formatTicketNumber(ticket.find(i => i.index === ticket.length - 1)?.id);
  }
  return formatTicketNumber(ticket[ticket.length - 1].id);
}

function formatTicketNumber(ticketNumber) {
  return ticketNumber.substring(0, 8)
    .toUpperCase()
    .replace(/.{2}(?!$)/g, "$&-");
}

/**
 * Determines if the ticket number should contain the classname rebooked or not
 * @param {any} ticket
 * @param {number} index
 * @param {number} size
 * @returns {string} either rebooked or an empty string
 */
function determineRebookedStatus(ticket, index, size) {
  /* check if blacklisted & borrow style */
  if (ticket.isBlacklisted == true) {
    return "rebooked";
  }

  if (ticket.Index != null) {
    if (ticket.Index < size)
      return "rebooked";
    else
      return "";
  }
  else {
    if (index > 0)
      return "rebooked";
    else
      return "";
  }
}

export default {
  append,
  dateFormat,
  toTitleCase,
  orderListDisplay,
  adjustForTimezone,
};
