import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";

import NavbarWithButtons from "./Navbar/NavbarWithButtons";
import TicketInfo from "./TicketInfo";
import TripDetails from "./TripDetails";
import { Spinner } from "react-bootstrap";

import ErrorDialog from "../../components/ErrorDialog";
import SuccessDialog from "../../components/SuccessDialog";

import { sleepAsync } from "../../lib/asyncHelpers";
import { useMsal } from "@azure/msal-react";
import Api from "../../hooks/ticketApi";

const emptyInformation = {
  values: [],
  total: null,
};
function Page() {
  const { instance } = useMsal();
  const { state } = useLocation();
  const { id } = useParams();
  const [ticketData, setTicketData] = useState(emptyInformation);
  const [ticketTripData, setTicketTripData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  async function updatedTicketData() {
    try {
      /* Race condition for showing the updated status. 2s should be enough for a update
       */
      await sleepAsync(2000);
      setTicketData(await Api.getTicketDataAsync(instance, id));
    } catch (error) {
      setError(error.message);
    }
  }

  async function loadTicketData() {
    try {
      setIsLoading(true);
      setTicketData(await Api.getTicketDataAsync(instance, id));

      // if ticket is preloaded from previous view we do not need to load it again
      if (state?.ticket) {
        setTicketTripData(state.ticket);
      } else {
        setTicketTripData(await Api.getTicketTripDataAsync(instance, id));
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }
  function clearError() {
    setError(null);
  }

  function clearSuccess() {
    setSuccess(null);
  }

  function handleFailure(errorMessage) {
    if (errorMessage) {
      setError(errorMessage);
    }
  }
  async function handleSuccess(successMessage) {
    if (successMessage) {
      await updatedTicketData();
      setSuccess(successMessage);
    }
  }

  useEffect(() => {
    loadTicketData();
  }, []);

  const correlationId =
    ticketData.values[0] != null ? ticketData.values[0].correlationId : null;

  const orderId = correlationId != null && String(correlationId).substr(0, 8);

  return (
    <main className="main">
      {/* ticketData={ticketData.values[0] != null && ticketData.values[0]}
        tripData={ticketTripData[0] != null && ticketTripData} */}

      <NavbarWithButtons
        ticketData={ticketData.values[0]}
        tripData={ticketTripData}
        onFailure={handleFailure}
        onSuccess={handleSuccess}
      />
      <div className="content">
        <div className="d-flex align-items-center">
          <h1 className="h4 mr-3">Bokning</h1>
          {isLoading && <Spinner />}
        </div>
        {error && (
          <div className="row">
            <ErrorDialog title="Något gick fel." onClose={clearError}>
              {error}
            </ErrorDialog>
          </div>
        )}
        {success && (
          <div className="row">
            <SuccessDialog title="Lyckat!" onClose={clearSuccess}>
              {success}
            </SuccessDialog>
          </div>
        )}
        <>
          <h2> {orderId}</h2>
          <TicketInfo
            ticketData={ticketData}
            vat={ticketTripData[0] != null ? Number(ticketTripData[0].vat) : 0}
            validity={
              ticketTripData[0] != null
                ? ticketTripData[ticketTripData.length - 1].arrivalTime
                : "-"
            }
          />
          {correlationId && (
            <TripDetails ticketId={correlationId} tripData={ticketTripData} />
          )}
        </>
      </div>
    </main>
  );
}

export default Page;
