import React, { useState, useEffect, Fragment } from "react";
import { Container, Card, Tab } from "react-bootstrap";

import ErrorDialog from "../../components/ErrorDialog";
import TrafficInfoMatrix from "./TrafficInfoMatrix";
import SearchStationBar from "./SearchStationBar";
import ToggleTabs from "./ToggleTabs";

import Api from "./trafficInfoApi";
import { useMsal } from "@azure/msal-react";

/**
 * This is the Traffic Information page.
 */
const emptyInformation = {
  departureDeviations: [],
  messages: [],
  lastUpdated: null,
};

function Page() {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(true);
  const [trafficInfo, setTrafficInfo] = useState(emptyInformation);
  const [error, setError] = useState(null);
  const [activityTypes, setActivityTypes] = useState([]);
  const [activeTab, setActiveTab] = useState("Avgång");

  async function updateTrafficInfo(stationId) {
    try {
      setIsLoading(true);
      setTrafficInfo(await Api.getTrafficInformation(instance, stationId));
      setActivityTypes(["Avgång", "Ankomst", "Meddelanden"]);
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  }
  function updateActiveTab(tab) {
    setActiveTab(tab);
  }

  useEffect(() => {
    updateTrafficInfo("740000001");
  }, []);

  function clearError() {
    setError(null);
  }

  return (
    <Fragment>
      <Container fluid className="p-0">
        <div className="d-flex align-items-center">
          <h1 className="h3 mb-3">Trafikinformation</h1>
        </div>
        <Card>
          <Card.Header>
              <SearchStationBar
                value={"Stockholm Centralstation"}
                onChange={updateTrafficInfo}
              />
          </Card.Header>
          </Card>
            {error && (
              <ErrorDialog
                title="Hoppsan ett fel inträffade! Försök igen."
                onClose={clearError}
              />
            )}
              <Fragment>
              <div className={"tab "}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="Avgång">
                <ToggleTabs
                  activityTypes={activityTypes}
                  message={trafficInfo.messages}
                  onChange={updateActiveTab}
                />
                  <Tab.Content>
                           <TrafficInfoMatrix
                              information={trafficInfo}
                              isLoading={isLoading}
                              onFailure={setError}
                              activeTab={activeTab}
                            />
                  </Tab.Content>
                </Tab.Container>
                </div>
                </Fragment>
                
      </Container>
    </Fragment>
  );
}

export default Page;
