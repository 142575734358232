import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Table, Pagination, Button } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";

import RoleIndicator from "./RoleIndicator";
import ActionButton from "./ActionButton";
import UserAccessButton from "./UserAccessButton";
import Permission from "./Permission";
import Spinner from "../../components/Spinner";

/**
 * User group.
 * @typedef {Object} UserGroup
 * @property {string} id - The unique ID of the user group.
 * @property {string} displayName - The name of the user group, this has no function in the frontend at the moment other than it helps debugging.
 */

/**
 * Permissions associated with a user.
 * @typedef {Object} Permissions
 * @property {UserGroup[]} memberOf - The user groups that this user is member of.
 * @property {boolean} isAdmin - The user is member of the admin group.
 * @property {boolean} isCustomerService - The user is member of the customer service group.
 * @property {boolean} isInspector - The user is member of the inspector group.
 * @property {boolean} isFinance - The user is member of the finance group.
 */

/**
 * User definition as it is represented in the API.
 * @typedef {Object} User
 * @property {string} id - Unique identifier of the user in the Active Directory
 * @property {string} name - Display name of the user.
 * @property {string} email - E-mail to the user, we're really not using this for anything at the moment.
 * @property {Permissions} permissions - The permissions that this user has associated with it.
 * @property {boolean} isActive - Indicates if this user can login or not.
 */

/**
 * Callback for successful action.
 *
 * @callback onSuccessCallback
 */

/**
 * Callback for failed action.
 *
 * @callback onFailureCallback
 * @param {string} errorMessage - A message describing what went wrong.
 */

/**
 * One table row for each user, showing the user access rights.
 * @param {User} input - The user from the API that should get rendered in the table.
 * @param {boolean} isCurrentUser - The user is also the currently authenticated user.
 * @param {onSuccessCallback} onSuccess - Is called when action is successfully performed.
 * @param {onFailureCallback} onFailure - Is called when an action fails.
 */
function AccessMatrixRow({ input, isCurrentUser, onFailure, ...props }) {
  const [user, setUser] = useState(input);

  function getInactiveTextClassName(isActive) {
    return isActive ? "" : "text-muted text-decoration-line-through";
  }

  // we have this convenience function here to update the table
  // directly, so the user doesn't have to wait for the callback
  function updateUserRow(updatedUser) {
    setUser(updatedUser);
  }

  return (
    <tr data-testid="access-matrix-row" {...props}>
      <td className={getInactiveTextClassName(user.isActive)}>{user.name}</td>
      <td className={getInactiveTextClassName(user.isActive)}>{user.email}</td>
      <td className="text-center">
        <UserAccessButton
          user={user}
          permission={Permission.admin}
          onSuccess={updateUserRow}
          onFailure={onFailure}
          isDisabled={isCurrentUser}
        >
          <RoleIndicator
            hasRole={user.permissions.isAdmin}
            isDisabled={isCurrentUser}
          />
        </UserAccessButton>
      </td>
      <td className="text-center">
        <UserAccessButton
          user={user}
          permission={Permission.customerService}
          onSuccess={updateUserRow}
          onFailure={onFailure}
          isDisabled={isCurrentUser}
        >
          <RoleIndicator
            hasRole={user.permissions.isCustomerService}
            isDisabled={isCurrentUser}
          />
        </UserAccessButton>
      </td>
      <td className="text-center">
        <UserAccessButton
          user={user}
          permission={Permission.inspector}
          onSuccess={updateUserRow}
          onFailure={onFailure}
          isDisabled={isCurrentUser}
        >
          <RoleIndicator
            hasRole={user.permissions.isInspector}
            isDisabled={isCurrentUser}
          />
        </UserAccessButton>
      </td>
      <td className="text-center">
        <UserAccessButton
          user={user}
          permission={Permission.finance}
          onSuccess={updateUserRow}
          onFailure={onFailure}
          isDisabled={isCurrentUser}
        >
          <RoleIndicator
            hasRole={user.permissions.isFinance}
            isDisabled={isCurrentUser}
          />
        </UserAccessButton>
      </td>
      <td className="text-center">
        <ActionButton
          id={user.id}
          isEnabled={user.isActive}
          isDisabled={isCurrentUser}
          onSuccess={updateUserRow}
          onFailure={onFailure}
        />
      </td>
    </tr>
  );
}

AccessMatrixRow.propTypes = {
  input: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
    permissions: PropTypes.shape({
      memberOf: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          displayName: PropTypes.string,
        })
      ),
      isAdmin: PropTypes.bool.isRequired,
      isCustomerService: PropTypes.bool.isRequired,
      isInspector: PropTypes.bool.isRequired,
      isFinance: PropTypes.bool.isRequired,
    }),
    isActive: PropTypes.bool.isRequired,
  }),
  isCurrentUser: PropTypes.boolean,
  onFailure: PropTypes.func,
};

/**
 * Filter.
 * @typedef {Object} Filter
 * @property {boolean} showInactive - Indicates if inactive users should be shown in the user list.
 * @property {string} freeTextFilter - Filter on name and e-mail.
 */

/**
 * Callback when the filter was updated.
 *
 * @callback onChangeCallback
 * @param {Filter} filter - The current filter.
 */

/**
 * Display access rights in a matrix.
 * @param {User[]} users - Users to display in the table.
 * @param {onSuccessCallback} onSuccess - Callback when an action on the matrix succeeds.
 * @param {onFailureCallback} onFailure - Callback when an action on the matrix fails.
 */
function AccessMatrix({ users, onFailure, showInactive, isLoading }) {
  const { instance } = useMsal();

  function getActiveAccount() {
    const activeAccount = instance.getActiveAccount();
    const accounts = instance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      // user is not signed in, throw error
      throw new Error("User is not signed in");
    } else {
      return activeAccount || accounts[0];
    }
  }

  /**
   * Predicate function if the user should be visible.
   * @param {User} user - Should user be visible or not.
   */
  function applyFilter(user) {
    // checkbox show inactive controls if user is visible
    let isVisible = showInactive || user.isActive;

    return isVisible;
  }

  const isCurrentUser = (user) => {
    const currentUserAccount = getActiveAccount();
    return currentUserAccount.localAccountId === user.id;
  };

  return (
    <Table hover>
      <thead>
        <tr>
          <th>Namn</th>
          <th>E-post</th>
          <th className="text-center">Administratör</th>
          <th className="text-center">Kundservice</th>
          <th className="text-center">Inspektör</th>
          <th className="text-center">Ekonomi</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            <td colSpan="7">
              <Spinner />
            </td>
          </tr>
        ) : (
          <Fragment>
            {users.map((user) => (
              <AccessMatrixRow
                input={user}
                onFailure={onFailure}
                key={user.id}
                className={applyFilter(user) ? "" : "d-none"}
                isCurrentUser={isCurrentUser(user)}
              />
            ))}
          </Fragment>
        )}
      </tbody>
    </Table>
  );
}

AccessMatrix.propTypes = {
  users: PropTypes.arrayOf(AccessMatrixRow.propTypes.input),
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  showInactive: PropTypes.bool,
  isLoading: PropTypes.bool,
};

function PaginationComponent({
  currentPage,
  pages,
  lastPage,
  onClick,
  onNextClick,
}) {
  return (
    <div className="align-card-content">
      <Pagination>
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => onClick(currentPage - 1)}
        />
        {pages.map((page) => (
          <Pagination.Item
            onClick={() => onClick(page)}
            key={page}
            active={page === currentPage}
          >
            {page}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => onClick(currentPage + 1)}
          disabled={currentPage == pages.length}
        />

        <Button className="btn ml-2" onClick={onNextClick} disabled={lastPage}>
          Hämta fler
        </Button>
      </Pagination>
    </div>
  );
}

PaginationComponent.propTypes = {
  currentPage: PropTypes.number,
  pages: PropTypes.array,
  lastPage: PropTypes.bool,
  onClick: PropTypes.func,
  onNextClick: PropTypes.func,
};

/**
 * Display access rights in a matrix with pagination.
 * @param {[User[]]} userPages - Pages of users to display in the table.
 * @param {onSuccessCallback} onSuccess - Callback when an action on the matrix succeeds.
 * @param {onFailureCallback} onFailure - Callback when an action on the matrix fails.
 * @param {bool} lastPage - True if last page of users.
 */
function AccessMatrixWithPagination({
  userPages,
  onSuccess,
  onFailure,
  lastPage,
  showInactive,
  isLoading,
}) {
  const [pages, setPages] = useState([1]);
  const [currentPage, setCurrentPage] = useState(1);

  const onClick = (page) => setCurrentPage(page);

  async function onNextClick() {
    await onSuccess();

    const nextPage = userPages.length + 1;
    setPages([...pages, nextPage]);
    setCurrentPage(nextPage);
  }

  useEffect(() => {
    // Clear pages if new search has been made
    if (userPages.length < pages.length) {
      setCurrentPage(1);
      setPages([1]);
    }
  }, [userPages]);

  return (
    <Fragment>
      <PaginationComponent
        currentPage={currentPage}
        pages={pages}
        lastPage={lastPage}
        onClick={onClick}
        onNextClick={onNextClick}
      />
      <AccessMatrix
        users={userPages[currentPage - 1]} // userPages starts with index 0, pages start with index 1
        onFailure={onFailure}
        showInactive={showInactive}
        isLoading={isLoading}
      />
      <br />
      <PaginationComponent
        currentPage={currentPage}
        pages={pages}
        lastPage={lastPage}
        onClick={onClick}
        onNextClick={onNextClick}
      />
    </Fragment>
  );
}

AccessMatrixWithPagination.propTypes = {
  userPages: PropTypes.arrayOf(
    PropTypes.arrayOf(AccessMatrixRow.propTypes.input)
  ),
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  lastPage: PropTypes.bool,
  showInactive: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default AccessMatrixWithPagination;
