import React from "react";
import PropTypes from "prop-types";

import { Badge } from "react-bootstrap";

import operatorSymbolMap from "./operatorSymbolMap";

/**
 * A filter badge that describes how the data is filtered.
 * @param {string} property - The property that we filter on.
 * @param {eq|ne|gt|lt} operator - The operator we filter on.
 * @param {string} value - The value that we filter on.
 */
function CustomFilterBadge({ property, operator, value, ...props }) {
  return (
    <Badge
      {...props}
      className={`bg-primary p-2 ${props.className}`}
      style={{ cursor: "pointer" }}
    >
      {`${property} ${operatorSymbolMap[operator]} ${value}`}
    </Badge>
  );
}

CustomFilterBadge.propTypes = {
  property: PropTypes.string.isRequired,
  operator: PropTypes.oneOf(["eq", "ne", "gt", "lt"]),
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default CustomFilterBadge;
