import React from "react";
import BootstrapSpinner from "react-bootstrap/Spinner";

/**
 * Displays a bootstrap spinner
 */
function Spinner() {
  return (
    <BootstrapSpinner animation="border" size="sm">
      <span className="visually-hidden">Loading...</span>
    </BootstrapSpinner>
  );
}
 {/* {isLoading ? (
                <div className="loadingWrapper" data-title=".dot-flashing" >
                    <div className="loadingContainer">
                        <div className="dot-flashing"></div>
                    </div>
                </div>
            ) : ""
            } */}
export default Spinner;
