import React, { useEffect, useState, Fragment } from "react";
import {Container, Card} from "react-bootstrap";

import { useMsalAuthentication } from "@azure/msal-react";

import TicketMatrixTable from "./ticketMatrixTable";
import ErrorDialog from "../../components/ErrorDialog";
import SuccessDialog from "../../components/SuccessDialog";
import UploadButton from "./uploadButton";
import { useMsal } from "@azure/msal-react";

import Api from "./matrixApi";

/**
 * Main component for the TicketMatrix. Welcome!
 */
function Ticketmatrix() {
  const { instance } = useMsal();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [ticketMatrixData, setTicketMatrixData] = useState([]);
  useMsalAuthentication("redirect");

  function updateTicketMatrixData() {
    setIsLoading(true);

    Api.getMatrixesAsync(instance)
      .then(setTicketMatrixData)
      .catch((error) => {
        setError({
          title: "Nu gick något snett",
          message: error.message ?? error,
        });
      })
      .finally(() => setIsLoading(false));
  }

  function handleSuccess(successMessage) {
    if (successMessage) {
      setSuccess({
        title: successMessage.title,
        message: successMessage.message,
      });
    }
    updateTicketMatrixData();
  }

  function handleFailure(errorMessage) {
    if (errorMessage) {
      setError({
        title: "Nu gick något snett",
        message: errorMessage,
      });
    }
    updateTicketMatrixData();
  }

  function clearError() {
    setError(null);
  }

  function clearSuccess() {
    setSuccess(null);
  }

  // update ticket matrix table on load
  useEffect(() => {
    updateTicketMatrixData();
  }, []);

  function fileUploadSuccess() {
    setSuccess({
      title: "Vi har tagit emot filen!",
      message:
        "Vi kommer läsa in filen vilket tar en liten stund. Ta en kopp kaffe så länge.",
    });
    updateTicketMatrixData();
  }

  const isEmpty = ticketMatrixData.length === 0;

  return (
    <Fragment>
      <Container fluid className="p-0">
        <UploadButton
          className="float-end mt-n1"
            onSuccess={fileUploadSuccess}
            onFailure={handleFailure}
          />
        <h1 className="h3 mb-3">Biljettmatris</h1>
        
      {error && (
        <div className="row">
          <ErrorDialog title={error.title} onClose={clearError}>
            {error.message}
          </ErrorDialog>
        </div>
      )}
      {success && (
        <div className="row">
          <SuccessDialog title={success.title} onClose={clearSuccess}>
            {success.message}
          </SuccessDialog>
        </div>
      )}
        <Card>
          <TicketMatrixTable
            data={ticketMatrixData}
            isEmpty={isEmpty}
            isLoading={isLoading}
            onSuccess={handleSuccess}
            onFailure={handleFailure}
          />
        </Card>
      </Container>
    </Fragment>
  );
}

export default Ticketmatrix;
