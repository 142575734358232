export const ticketInfoIconMap = new Map();

ticketInfoIconMap.set("default", "info_outline");
ticketInfoIconMap.set("A1", "restaurant");
ticketInfoIconMap.set("AA", "looks_two");
ticketInfoIconMap.set("AC", "restaurant");
ticketInfoIconMap.set("AD", "restaurant");
ticketInfoIconMap.set("AK", "family_restroom");
ticketInfoIconMap.set("AL", "theaters");
ticketInfoIconMap.set("AN", "accessible");
ticketInfoIconMap.set("AW", "wifi");
ticketInfoIconMap.set("BG", "luggage");
ticketInfoIconMap.set("D1", "pets");
ticketInfoIconMap.set("D2", "pets");
ticketInfoIconMap.set("D3", "pets");
ticketInfoIconMap.set("FB", "pedal_bike");
ticketInfoIconMap.set("LW", "airline_seat_flat");
ticketInfoIconMap.set("SD", "liquor");
ticketInfoIconMap.set("SF", "free_breakfast");
ticketInfoIconMap.set("SG", "local_bar");
ticketInfoIconMap.set("SH", "info_outline");
ticketInfoIconMap.set("SI", "no_meals");
ticketInfoIconMap.set("SK", "local_cafe");
ticketInfoIconMap.set("SL", "airline_seat_flat");
ticketInfoIconMap.set("SW", "airline_seat_individual_suite");
ticketInfoIconMap.set("SX", "wifi_off");
ticketInfoIconMap.set("SZ", "local_cafe");
