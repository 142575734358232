export default {
  admin: {
    id: "82cfef58-8bd5-43c8-ad29-81858c91e56d",
    displayName: "Admin",
  },
  customerService: {
    id: "0fb24b2f-22e6-4e12-8da3-77f1c9b66ba3",
    displayName: "Customer Service",
  },
  inspector: {
    id: "461d854f-760f-42c7-b9f1-782b546f7368",
    displayName: "Inspector",
  },
  finance: {
    id: "15c974f5-a5f4-40d2-af51-d83e83a6a506",
    displayName: "Finance",
  },
};
