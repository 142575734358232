import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import defaultRoutes, {
  ticketsRouting,
  changelogRouting,
  /*customerRouting,*/
} from "./index";

import SidebarLayout from "../layouts/Sidebar";
import NoNavBarLayout from "../layouts/NoNavBar";

const childRoutes = (Layout, routes) =>
  routes.map(({ route, path, component: Component }, index) => (
    // Route item without children
    <Route
      key={index}
      path={route ?? path}
      exact
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  ));

const Routes = () => (
  <Router>
    <Switch>{childRoutes(SidebarLayout, defaultRoutes)}</Switch>
    <Switch>{childRoutes(NoNavBarLayout, ticketsRouting)}</Switch>
    {/* <Switch>{childRoutes(NoNavBarLayout, customerRouting)}</Switch> */}
    {/* <Switch>{childRoutes(SidebarLayout, travelplannerRouting)}</Switch> */}
    <Switch>{childRoutes(SidebarLayout, changelogRouting)}</Switch>
  </Router>
);

export default Routes;
