import React from "react";
import PropTypes from "prop-types";

import { Badge } from "react-bootstrap";
import FilterListIcon from "@mui/icons-material/FilterList";

/**
 * @typedef Filter
 * @param {string} property - Name of the property to be filtered.
 * @param {string} operator - The operator used for filtering, could be eq, ne, lt, gt
 * @param {string} value - Value of the filter.
 */

/**
 * @callback OnClickCallback
 * @param {Filter} filter - The filter that should be applied.
 */

/**
 * A button that is a shortcut to creating filter.
 *
 * @component
 * @param {string} property - The name of the property
 * @param {string} value - The value of the property.
 * @param {OnClickCallback} onClick - Will be triggered on click.
 * @param {string} bg - Background color. (bootstrap colors)
 * @param {string} text - Text color. (boolstrap colors)
 * @param {string} className - Custom class names.
 */
function FilterButton({
  property,
  value,
  onClick,
  bg = "light",
  text = "dark",
  className,
}) {
  function badgeClickHandler() {
    onClick && onClick({ property, operator: "eq", value });
  }

  return (
    <Badge
      className={`bg-${bg} text-${text} mr-2 ${className}`}
      onClick={badgeClickHandler}
      style={{ cursor: "pointer" }}
      data-testid="filterbutton-badge"
    >
      <FilterListIcon style={{ width: "10px", height: "10px" }} />
    </Badge>
  );
}

const colors = [
  "primary",
  "secondary",
  "success",
  "danger",
  "warning",
  "info",
  "ligth",
  "dark",
  "muted",
  "white",
];

FilterButton.propTypes = {
  property: PropTypes.string.isRequired,
  value: PropTypes.string,
  bg: PropTypes.oneOf(colors),
  text: PropTypes.oneOf(colors),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default FilterButton;
