import React from "react";
import PropTypes from "prop-types";

import { Table } from "react-bootstrap";
import Spinner from "../../components/Spinner";
/**
 * One table row for each user, showing the user access rights.
 * @param {Info} input - The user from the API that should get rendered in the table.
 * @param {onSuccessCallback} onSuccess - Is called when action is successfully performed.
 * @param {onFailureCallback} onFailure - Is called when an action fails.
 */
function TrafficInfoMatrixRow({ input, activeTab, ...props }) {
  function toLocalTime(utcTime) {
    return new Date(utcTime).toLocaleTimeString("sv-SE", {
      hour: "2-digit",
      minute: "2-digit",
    });
  }
  function getNewTimeTextClassName(input) {
    //Strike trough text if a new time or cancellation exist
    if (input.deviationType == 2 || input.newTime) {
      return "text-muted text-decoration-line-through";
    }
    return "";
  }
  function getRowValue(input) {
    // Display the new time in local time if it exist
    if (input.newTime) {
      return toLocalTime(input.newTime);
    }
    // Display a badge if the service is canceled, h1-6 for sizing
    if (input.deviationType == 2) {
      return (
        <h5>
          <span className="badge badge-danger">Inställt</span>
        </h5>
      );
    }
    return "";
  }
  function getDeviationColor(deviationType) {
    //Different cases of color generate different colors for identification
    switch (deviationType) {
      case 2:
        return "bg-danger";
      case 4:
        return "bg-warning";
      default:
        return "";
    }
  }

  return (
    <tr data-testid="access-matrix-row" {...props}>
      <td>
        {
          <span className={"d-block " + getNewTimeTextClassName(input)}>
            {toLocalTime(input.originalTime)}
          </span>
        }
        {<span className={"d-block"}>{getRowValue(input)}</span>}
      </td>
      {activeTab == "Avgång" ? (
        <td>
          {input.toDestination != null
            ? input.toDestination
            : "Rikshållplatsid saknas"}
        </td>
      ) : (
        <td>
          {input.fromDestination != null
            ? input.fromDestination
            : "Rikshållplatsid saknas"}
        </td>
      )}
      <td>
        <span className={getDeviationColor(input.deviationType)}>
          {input.trackId}
        </span>
      </td>
      <td>
        <a
          href={
            "https://www.trafikverket.se/trafikinformation/tag/?Train=" +
            input.serviceId
          }
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          {input.serviceId}
        </a>
      </td>
      <td>
        {input.deviationDescription &&
          input.deviationDescription.map((deviation, index) => (
            <span className={"d-block "} key={index}>
              {deviation}
            </span>
          ))}
      </td>
    </tr>
  );
}
TrafficInfoMatrixRow.propTypes = {
  input: PropTypes.arrayOf({
    serviceId: PropTypes.string,
    deviationDescription: PropTypes.shape(PropTypes.string),
    deviationType: PropTypes.number,
    toDestination: PropTypes.string,
    fromDestination: PropTypes.string,
    trackId: PropTypes.string,
    originalTime: PropTypes.string,
    newTime: PropTypes.string,
  }),
  activeTab: PropTypes.string,
};

/**
 * One table row for each user, showing the user access rights.
 * @param {Info} input - The user from the API that should get rendered in the table.
 * @param {onSuccessCallback} onSuccess - Is called when action is successfully performed.
 * @param {onFailureCallback} onFailure - Is called when an action fails.
 */
function TrafficInfoMessageRow({ input, ...props }) {
  function toLocalTime(utcTime) {
    return new Date(utcTime).toLocaleString("sv-SE", {});
  }

  return (
    <tr data-testid="access-matrix-row" {...props}>
      <td>
        {<h3>{input.header}</h3>}

        {input.time.lastUpdated != null ?? (
          <div>
            <span className={"font-weight-bold"}>Senast uppdaterad: </span>
            <span>{input.time.lastUpdated}</span>
          </div>
        )}

        {<p className={"d-block "}>{input.description}</p>}

        {input.affectedLocations && (
          <div>
            <span className={"font-weight-bold"}>
              Stationer där tågtrafiken kan påverkas:{" "}
            </span>
            <span className={"font-italic"}>
              {input.affectedLocations.join(", ")}
            </span>
          </div>
        )}

        {input.time.startTime && (
          <div>
            <span className={"font-weight-bold"}>Starttid: </span>
            <span>{toLocalTime(input.time.startTime)}</span>
          </div>
        )}

        {input.time.prognosticatedEndTime && (
          <div>
            <span className={"font-weight-bold"}>Prognos för sluttid:</span>
            <span>{toLocalTime(input.time.prognosticatedEndTime)}</span>
          </div>
        )}

        {input.time.endTime && (
          <div>
            <span className={"font-weight-bold"}>Sluttid:</span>
            <span>{toLocalTime(input.time.prognosticatedEndTime)}</span>
          </div>
        )}
      </td>
    </tr>
  );
}
TrafficInfoMessageRow.propTypes = {
  input: PropTypes.shape({
    header: PropTypes.string,
    reason: PropTypes.array,
    description: PropTypes.string,
    time: PropTypes.array,
    affectedLocations: PropTypes.array,
  }),
};

/**
 * Display access rights in a matrix.
 * @param {Information} information - Information to display in the table.
 * @param {onSuccessCallback} onSuccess - Callback when an action on the matrix succeeds.
 * @param {onFailureCallback} onFailure - Callback when an action on the matrix fails.
 */
function TrafficInfoMatrix({ information, isLoading, onFailure, activeTab }) {
  return (
    <>
      {activeTab == "Meddelanden" ? (
        <Table bordered>
          <tbody>
            {information.messages.map((info, index) => (
              <TrafficInfoMessageRow
                input={info}
                key={index}
                onFailure={onFailure}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <Table striped hover bordered>
          <thead>
            <tr>
              {activeTab == "Avgång" ? <th>Avgår</th> : <th>Ankommer</th>}
              {activeTab == "Avgång" ? <th>Till</th> : <th>Från</th>}
              <th>Spår</th>
              <th>Tåg</th>
              <th>Information</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr><td colSpan="5"><Spinner /></td></tr>) : (
            (activeTab == "Avgång"
              ? information.departureDeviations
              : information.arrivalDeviations
            ).map((info, index) => (
              <TrafficInfoMatrixRow
                input={info}
                key={index}
                onFailure={onFailure}
                activeTab={activeTab}
              />
            )))}

          </tbody>
        </Table>
      )}
    </>
  );
}
TrafficInfoMatrix.propTypes = {
  information: PropTypes.arrayOf(TrafficInfoMatrixRow.propTypes.input),
  isLoading: PropTypes.bool,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  activeTab: PropTypes.string,
};

export default TrafficInfoMatrix;
