import React from 'react';
import PropTypes from 'prop-types';

import sortOrder from '../types/sortOrder';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';

/**
 * Toggle sort order between None, Ascending and Descending.
 * @param {ColumnSortOrder} order - asc, desc or null
 */
function ColumnSortOrder({ order }) {
  return (
    <div className="sortComponent">
      <div className={`sortArrow ${order == 'asc' ? ' active' : ''}`}>
        <ArrowDropUpRoundedIcon className="sortArrowIcon" />
      </div>
      <div className={`sortArrow ${order == 'desc' ? ' active' : ''}`}>
        <ArrowDropDownRoundedIcon className="sortArrowIcon" />
      </div>
    </div>
  );
}

ColumnSortOrder.propTypes = {
  order: PropTypes.oneOf([sortOrder.NONE, sortOrder.ASC, sortOrder.DESC]),
};

export default ColumnSortOrder;
