import Dashboard from "../pages/dashboard/dashboard";
import Ticketmatrix from "../pages/ticketmatrix/ticketmatrix";
import { Trafficinfo } from "../pages/trafficinfo";
import { AccessControl } from "../pages/accesscontrol";
import { Bookings } from "../pages/bookings";
import { Tickets } from "../pages/ticket";
import { ChangeLog } from "../pages/changelog";
// import { TravelPlanner } from "../pages/travelplanner";
import { Audit } from "../pages/audit";
//import { Reschedule } from "../pages/reschedule";
// import { Customers } from "../pages/customers";
// import { Customer } from "../pages/customer";

import Permission from "../pages/accesscontrol/Permission";

import {
  ExitToAppOutlined,
  SupervisedUserCircleOutlined,
  ReceiptOutlined,
  ErrorOutlineOutlined,
  ListAltOutlined,
  Warning,
  // RouteOutlined,
  // Person,
} from "@mui/icons-material";

// Routes
const dashboardRoutes = {
  path: "/",
  name: "Dashboard Page",
  component: Dashboard,
  children: null,
  icon: ExitToAppOutlined,
  permissions: [],
};

//const customersRoutes = {
//  path: "/customers",
//  name: "Kunder",
//  component: Customers,
//  children: null,
//  icon: Person,
//  permissions: [Permission.admin.id, Permission.customerService.id],
//};

//const customerRoutes = {
//  path: "/customers/customer/:id",
//  name: "Kund Information",
//  component: Customer,
//  children: null,
//  icon: Person,
//  permissions: [Permission.admin.id, Permission.customerService.id],
//};

const bookingsRoutes = {
  path: "/bookings",
  name: "Bokningar",
  component: Bookings,
  children: null,
  icon: ReceiptOutlined,
  permissions: [
    Permission.admin.id,
    Permission.finance.id,
    Permission.customerService.id,
  ],
};

const ticketRoutes = {
  path: "/bookings/ticket/:id",
  name: "Biljett",
  component: Tickets,
  children: null,
  icon: ReceiptOutlined,
  permissions: [
    Permission.admin.id,
    Permission.customerService.id,
    Permission.finance.id,
  ],
};

/*
const rescheduleRoutes = {
  path: "/bookings/ticket/:id/reschedule",
  name: "Boka om",
  component: Reschedule,
  children: null,
  icon: ReceiptOutlined,
  permissions: [
    Permission.admin.id,
    Permission.customerService.id,
    Permission.finance.id,
  ],
};*/

const trafficinfoRoutes = {
  path: "/trafficinfo",
  name: "Trafikinformation",
  component: Trafficinfo,
  children: null,
  icon: Warning,
  permissions: [
    Permission.admin.id,
    Permission.customerService.id,
    Permission.inspector.id,
  ],
};

const ticketmatrixRoutes = {
  path: "/ticketmatrix",
  name: "Biljettmatris",
  component: Ticketmatrix,
  children: null,
  icon: ListAltOutlined,
  permissions: [Permission.admin.id, Permission.finance.id],
};

const accesscontrolRoutes = {
  path: "/accesscontrol",
  name: "Användarhantering",
  component: AccessControl,
  children: null,
  icon: SupervisedUserCircleOutlined,
  permissions: [Permission.admin.id],
};

const auditRoutes = {
  route: "/audit/:query?",
  path: "/audit",
  name: "Händelselogg",
  component: Audit,
  children: null,
  icon: ErrorOutlineOutlined,
  permissions: [Permission.admin.id, Permission.customerService.id],
};

const changelogRoutes = {
  path: "/changelog",
  name: "Changelog",
  component: ChangeLog,
  children: null,
  icon: ListAltOutlined,
  permissions: [Permission.admin.id, Permission.finance.id],
};

// const travelplannerRoutes = {
//   path: "/travelplanner",
//   name: "Reseplanerare",
//   component: TravelPlanner,
//   children: null,
//   icon: RouteOutlined,
//   permissions: [Permission.admin.id, Permission.finance.id],
// };

// const salesReportRoutes = {
//   path: "https://app.powerbi.com/links/BRi_CTElZZ?ctid=76a2ae5a-9f00-4f6b-95ed-5d33d77c4d61&pbi_source=linkShare",
//   name: "Försäljningsrapport",
//   icon: FileCopy,
//   permissions: [
//     Permission.admin.id,
//     Permission.finance.id],
// }

//export const ticketsRouting = [ticketRoutes, rescheduleRoutes];
export const ticketsRouting = [ticketRoutes];
//export const customerRouting = [customerRoutes];
export const changelogRouting = [changelogRoutes];
// export const travelplannerRouting = [travelplannerRoutes];
export const defaultExtRoutes = [];

// Routes that shows in the sidebar
export default [
  dashboardRoutes,
  bookingsRoutes,
  //customersRoutes,
  trafficinfoRoutes,
  ticketmatrixRoutes,
  accesscontrolRoutes,
  auditRoutes,
  //travelplannerRoutes,
  //salesReportRoutes
];
