import React from "react";
import PropTypes from "prop-types";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";

import { useIsAuthenticated } from "@azure/msal-react";

const Start = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  return (
    isAuthenticated && (
      <Wrapper>
        <Sidebar location={window.location} />
        <Main>
          <>
            <Navbar />
            <main className="content">{children}</main>
          </>
        </Main>
      </Wrapper>
    )
  );
};

Start.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};

export default Start;
