import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import ScheduleIcon from "@mui/icons-material/Schedule";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import UtilityButton from "../../components/UtilityButton";
import Spinner from "../../components/Spinner";
import Api from "./matrixApi";
import { useMsal } from "@azure/msal-react";
/**
 * Callback for hiding the scheduling modal.
 *
 * @callback onHideCallback
 */

/**
 * Callback for successful scheduling the ticket matrix.
 *
 * @callback onSuccessCallback
 */

/**
 * Callback for failed to schedule the ticket matrix.
 *
 * @callback onFailureCallback
 * @param {string} errorMessage - A message describing what went wrong.
 */

/**
 * A modal that pops up when pressing the schedule button. Contains a form for scheduling the ticket matrix file.
 *
 * @param {string} fileId - Unique identifier for the file.
 * @param {onHideCallback} onHide - Will be called when modal becomes hidden.
 * @param {onSuccessCallback} onSuccess - Will be called when ticket matrix was successfully scheduled.
 * @param {onFailure} onFailure - Will be called when fails to schedule ticket matrix.
 */
export function ScheduleModal({ fileId, onHide, onSuccess, onFailure }) {
  const { instance } = useMsal();
  const [hasValidationError, setHasValidationError] = useState(false);
  const [isScheduling, setIsScheduling] = useState(false);
  const inputRef = useRef();

  // hide the modal
  function cancel() {
    if (onHide) {
      onHide();
    }
  }

  function clearValidationError() {
    setHasValidationError(false);
  }

  function scheduledSuccess() {
    setIsScheduling(false);

    if (onSuccess) {
      // call back to update the table with the new state
      onSuccess({
        title: "Nu är filen schemalagd",
        message: "Se i tabellen när filen blir aktiv",
      });
    }

    onHide();
  }

  function scheduledFailure() {
    setIsScheduling(false);
    if (onFailure) {
      onFailure("Misslyckades att schemalägga biljettmatris.");
    }
  }

  function schedule() {
    if (inputRef.current.valueAsDate === null) {
      setHasValidationError(true);
      return false;
    }

    // show spinner and disable the button
    setIsScheduling(true);

    // call the api to schedule the ticket matrix
    Api.scheduleAsync(instance, fileId, inputRef.current.valueAsDate)
      .then(scheduledSuccess)
      .catch(scheduledFailure);
  }

  // stop event propagation on modal, to prevent the table row to act on clicks made in modal
  function stopEventPropagation(ev) {
    ev.stopPropagation();
  }

  return (
    <Modal show={true} onHide={onHide} onClick={stopEventPropagation}>
      <Modal.Header>Schemalägg prislista</Modal.Header>
      <Modal.Body>
        <Form.Group controlId={fileId}>
          <Form.Label className="font-weight-bold">Välj ett datum*</Form.Label>
          <Form.Control
            data-testid="schedule-modal-validFrom-input"
            className={`form-control-sm ${hasValidationError ? "border-danger" : ""
              }`}
            type="date"
            name="validFrom"
            onChange={clearValidationError}
            ref={inputRef}
          />
        </Form.Group>
        <div className="p-2 border border-warning">
          <WarningRoundedIcon className="float-left text-warning" />
          <span className="mx-2">
            Att schemalägga en prislista är oåterkallerligt.
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          data-testid="schedule-modal-cancel-button"
          className="btn btn-secondary"
          onClick={cancel}
        >
          Avbryt
        </button>
        <button
          data-testid="schedule-modal-submit-button"
          className="btn btn-primary"
          onClick={schedule}
          disabled={isScheduling}
        >
          {isScheduling ? <Spinner /> : "Schemalägg"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

ScheduleModal.propTypes = {
  fileId: PropTypes.string.isRequired,
  onHide: PropTypes.func,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
};

/**
 * A button that opens the Schedule Modal.
 *
 * @param {string} fileId - Unique identifier for the file
 * @param {boolean} isDisabled - When true on click will not open scheduling modal
 * @param {onSuccessCallback} onSuccess - Callback when scheduling is successful
 * @param {onFailureCallback} onFailure - Callback when scheduling of ticket matrix fails
 * @returns
 */
function ScheduleButton({ fileId, isDisabled, onSuccess, onFailure }) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  function showModal(ev) {
    setIsModalVisible(true);

    // do not propagate the click to the table row
    // since table row has its own click event that
    // would cause it to expand/collapse when clicking
    // the delete button.
    ev.stopPropagation();
  }

  function hideModal() {
    setIsModalVisible(false);
  }

  return (
    <>
      <UtilityButton
        title="Schemaläggning"
        onClick={showModal}
        isDisabled={isDisabled}
      >
        <ScheduleIcon className="mx-2" />
      </UtilityButton>
      {isModalVisible && (
        <ScheduleModal
          fileId={fileId}
          onHide={hideModal}
          onSuccess={onSuccess}
          onFailure={onFailure}
        />
      )}
    </>
  );
}

ScheduleButton.propTypes = {
  fileId: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
};

export default ScheduleButton;
