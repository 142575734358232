import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { BarChart, Launch } from "@mui/icons-material";
import { Badge, Collapse } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import hubLogo from "../assets/img/transithublogo.svg";
import Api from "../lib/api";
import defaultRoutes, { defaultExtRoutes } from "../routes/index";
import { useMsal } from "@azure/msal-react";

async function getAppVersion() {
  let response = "";
  for await (let line of makeTextFileLineIterator("/api/v1/system/version")) {
    response += line;
  }
  return response;
}

async function* makeTextFileLineIterator(fileURL) {
  const utf8Decoder = new TextDecoder("utf-8");
  const response = await fetch(fileURL);
  const reader = response.body.getReader();
  let { value: chunk, done: readerDone } = await reader.read();
  chunk = chunk ? utf8Decoder.decode(chunk) : "";

  const re = /\n|\r|\r\n/gm;
  let startIndex = 0;

  for (;;) {
    let result = re.exec(chunk);
    if (!result) {
      if (readerDone) {
        break;
      }
      let remainder = chunk.substr(startIndex);
      ({ value: chunk, done: readerDone } = await reader.read());
      chunk = remainder + (chunk ? utf8Decoder.decode(chunk) : "");
      startIndex = re.lastIndex = 0;
      continue;
    }
    yield chunk.substring(startIndex, result.index);
    startIndex = re.lastIndex;
  }
  if (startIndex < chunk.length) {
    // last line didn't end in a newline char
    yield chunk.substr(startIndex);
  }
}

async function getUserGroupRoutes(msalInstance) {
  const activeUser = await Api.getActiveAccount(msalInstance);
  var userGroups = [];
  var allowedRoutes = [];

  activeUser.idTokenClaims.groups.map((groupsPermissions) => {
    userGroups.push(groupsPermissions);
  });
  defaultRoutes?.forEach((route) => {
    if (route.permissions.some((r) => userGroups.includes(r))) {
      allowedRoutes.push(route);
    }
  });

  return allowedRoutes;
}

async function getUserGroupExtRoutes(msalInstance) {
  const activeUser = await Api.getActiveAccount(msalInstance);
  var userGroups = [];
  var allowedRoutes = [];

  activeUser.idTokenClaims.groups.map((groupsPermissions) => {
    userGroups.push(groupsPermissions);
  });
  defaultExtRoutes.forEach((route) => {
    if (route.permissions.some((r) => userGroups.includes(r))) {
      allowedRoutes.push(route);
    }
  });
  return allowedRoutes;
}

const initOpenRoutes = (location, sidebarRoutes) => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname;
  let _routes = {};

  Array.from(sidebarRoutes).forEach((route, index) => {
    const isActive = pathName.indexOf(route.path) === 0;
    const isOpen = route.open;
    const isHome = route.containsHome && pathName === "/" ? true : false;

    _routes = Object.assign({}, _routes, {
      [index]: isActive || isOpen || isHome,
    });
  });

  return _routes;
};

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    to,
  }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <a
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <Icon size={18} className="align-middle mr-3" />
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </a>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon: Icon, location, to }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname.startsWith(path) ? "active" : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

const Sidebar = ({ location }) => {
  const { instance } = useMsal();

  const [appVersion, setAppVersion] = useState(() =>
    getAppVersion().then((response) => setAppVersion(response))
  );
  const [sidebarRoutes, setSidebarRoutes] = useState(() =>
    getUserGroupRoutes(instance).then((response) => setSidebarRoutes(response))
  );
  const [sidebarExtRoutes, setSidebarExtRoutes] = useState(() =>
    getUserGroupExtRoutes(instance).then((response) =>
      setSidebarExtRoutes(response)
    )
  );
  const [openRoutes, setOpenRoutes] = useState(() =>
    initOpenRoutes(location, sidebarRoutes)
  );

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  return (
    <nav id="sidebar" className="sidebar">
      <div className="sidebar-content js-simplebar">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            <img src={hubLogo} alt="Transithub logo" />
          </a>
          <ul className="sidebar-nav">
            {Array.from(sidebarRoutes).map((category, index) => {
              return (
                <React.Fragment key={index}>
                  {category.header ? (
                    <li className="sidebar-header">{category.header}</li>
                  ) : null}

                  {category.children ? (
                    <SidebarCategory
                      name={category.name}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                      icon={category.icon}
                      to={category.path}
                      isOpen={openRoutes[index]}
                      onClick={() => toggle(index)}
                    >
                      {category.children.map((route, index) => (
                        <SidebarItem
                          key={index}
                          name={route.name}
                          to={route.path}
                          badgeColor={route.badgeColor}
                          badgeText={route.badgeText}
                        />
                      ))}
                    </SidebarCategory>
                  ) : (
                    <SidebarItem
                      name={category.name}
                      to={category.path}
                      icon={category.icon}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                    />
                  )}
                </React.Fragment>
              );
            })}
            {Array.from(sidebarExtRoutes).map((route, index) => {
              return (
                <li className={"sidebar-item"} key={"ext" + index}>
                  <a
                    className={"sidebar-link"}
                    rel="noopener noreferrer"
                    href={route.path}
                    target="_blank"
                  >
                    <BarChart size={18} className="align-middle mr-3" />
                    <span className="align-middle">{route.name} </span>
                    <Badge
                      color={""}
                      size={18}
                      className="sidebar-badge"
                      style={{ top: "12px" }}
                    >
                      <Launch
                        size={10}
                        className="ml-2"
                        style={{ fontSize: "1rem" }}
                      />
                    </Badge>
                  </a>
                </li>
              );
            })}
          </ul>
        </PerfectScrollbar>
      </div>
      <div className="sidebar-bottom">
        <NavLink
          to="/changelog"
          className="sidebar-link"
          activeClassName="active"
        >
          {Array.from(appVersion).map((version) => {
            return version;
          })}
        </NavLink>
      </div>
    </nav>
  );
};

Sidebar.propTypes = {
  location: PropTypes.object,
};

export default withRouter(connect(() => ({}))(Sidebar));
