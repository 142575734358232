import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Form, InputGroup } from 'react-bootstrap';

import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

/**
 * @typedef DateRange
 * @param {Date} fromDateTime - Filter from datetime.
 * @param {Date} toDateTime - Filter to datetime.
 */

/**
 * @callback OnDateRangeChange
 * @param {DateRange} dateRange - The range that was updated.
 */

/**
 * A filter that represente to-from datetimes.
 * @param {OnDateRangeChange} onChange
 */
function DateRange({ onChange }) {
  const [fromDatePickerIsOpen, setFromDatePickerIsOpen] = useState(false);
  const [toDatePickerIsOpen, setToDatePickerIsOpen] = useState(false);
  const [fromDateTime, setFromDateTime] = useState(null);
  const [toDateTime, setToDateTime] = useState(null);

  /**
   * A function for when the from/to DateTime is updated. Calls functions for setting DateTime state and closeing DatePicker
   * @param {*} selectedDateTime -  The selected Datetime value
   * @param {*} isFrom - Bool for representing if from/to has been updated
   */
  function dateTimeUpdated(selectedDateTime, isFrom) {
    if (isFrom) {
      setFromDateTime(selectedDateTime);
      openFromDatePicker();
      onChange &&
        onChange({
          from: selectedDateTime,
          to: toDateTime,
        });
    } else {
      setToDateTime(selectedDateTime);
      openToDatePicker();
      onChange &&
        onChange({
          from: fromDateTime,
          to: selectedDateTime,
        });
    }
  }
  // Functions needed for deciding what DatePicker to open/close
  function openFromDatePicker() {
    setFromDatePickerIsOpen(!fromDatePickerIsOpen);
  }
  function openToDatePicker() {
    setToDatePickerIsOpen(!toDatePickerIsOpen);
  }

  return (
    <div className="row">
      <div className="col-12 col-md col-sm-6 col-xl-auto mb-2 mb-md-0">
        <Form.Group>
          <InputGroup onClick={fromDatePickerIsOpen ? '' : openFromDatePicker} className="ml-0i">
            <InputGroup.Prepend>
              <InputGroup.Text>
                <RestoreOutlinedIcon />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <DatePicker
              title="fromDatePicker"
              className="form-control form-control-lg"
              wrapperClassName="datepicker-with-icon-left"
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="d MMM, yyyy @ HH:mm"
              onChange={(e) => {
                dateTimeUpdated(e, true);
              }}
              placeholderText="dd / MM / YY"
              selected={fromDateTime}
              onClickOutside={() => openFromDatePicker()}
              maxDate={toDateTime}
            />
          </InputGroup>
        </Form.Group>
      </div>
      <div className="col-12 col-md col-sm-6 col-xl-auto mb-2 mb-md-0">
        <Form.Group>
          <InputGroup onClick={toDatePickerIsOpen ? '' : openToDatePicker} className="ml-0i">
            <InputGroup.Prepend>
              <InputGroup.Text>
                <UpdateOutlinedIcon />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <DatePicker
              className="form-control form-control-lg"
              wrapperClassName="datepicker-with-icon-left"
              title="toDatePicker"
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="d MMM, yyyy @ HH:mm"
              placeholderText="dd / MM / YY"
              onChange={(e) => {
                dateTimeUpdated(e, false);
              }}
              selected={toDateTime}
              onClickOutside={() => openToDatePicker()}
              minDate={fromDateTime}
            />
          </InputGroup>
        </Form.Group>
      </div>
    </div>
  );
}

DateRange.propTypes = {
  onChange: PropTypes.func,
};

export default DateRange;
