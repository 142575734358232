import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import { Button, Modal, Form } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";

import api from "../../../hooks/ticketApi";
import Spinner from "../../../components/Spinner";

/**
 * Callback for hiding the refund modal.
 *
 * @callback onHideCallback
 */

/**
 * Callback for successful refunding a ticket.
 *
 * @callback onSuccessCallback
 */

/**
 * Callback for failed to refund a ticket.
 *
 * @callback onFailureCallback
 * @param {string} errorMessage - A message describing what went wrong.
 */

/**
 * Show an refund modal with a information text and a ok button.
 * @param {onHideCallback} onHide - Gets called when user closes the modal.
 * @param {onSuccessCallback} onSuccess - Gets called when customer service successfully refunds a ticket.
 * @param {onFailureCallback} onFailure - Gets called when refund fails.
 * @returns
 */
function RefundModal({
  ticketData,
  tripData,
  show,
  onHide,
  onFailure,
  onSuccess,
}) {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  const reasonRef = useRef();
  const formRef = useRef();

  let request = {
    amount: ticketData.amount,
    correlationId: ticketData.correlationId,
    fullRefund: true,
    reason: "",
  };
  function validateInput() {
    const form = formRef.current;
    if (form.checkValidity() == true) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  }
  console.log(tripData);
  async function refundTicket() {
    // validate the form input
    const form = formRef.current;
    if (form.checkValidity() == false) {
      // form input not valid
    } else {
      // form is valid
      setIsLoading(true);
      request.reason = reasonRef.current.value;
      try {
        await api.refundTicketAsync(instance, request);

        onSuccess("Biljett skickad för processering av återbetalning!");
        setIsLoading(false);
        onHide();
      } catch (error) {
        onFailure(error.message);
        setIsLoading(false);
        onHide();
      }
    }
    // form has been validated
    setValidated(true);
  }
  return (
    <Modal show={show} onHide={onHide} data-testid="refund-modal">
      <Modal.Header closeButton>
        <Modal.Title>Återbetala biljett</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p>Är du säker på att du vill återbetala biljetten?</p>
        <p>Efter genomförd återbetalning kommer biljetten spärras.</p>
        <b>Återbetalningsbelopp: {ticketData.amount} KR</b>
        <p>
          Beloppet återbetalas till samma betalningskanal som användes vid köpet
        </p>
        <p>Anledning till återbetalning</p>
        <Form validated={validated} ref={formRef}>
          <Form.Group controlId="reasonInput">
            <Form.Control
              ref={reasonRef}
              type="text"
              placeholder="Anledning"
              onChange={validateInput}
              required
            />
            <Form.Control.Feedback type="valid" />
            <Form.Control.Feedback type="invalid">
              Du måste ange anledning till återbetalning
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="mute"
          onClick={onHide}
          data-testid="refund-modal-close-button"
        >
          Avbryt
        </Button>
        <Button variant="primary" disabled={isLoading} onClick={refundTicket}>
          {isLoading ? <Spinner /> : "OK"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

RefundModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  ticketData: PropTypes.array,
  tripData: PropTypes.array,
};

/**
 * Open a modal for refunds.
 * @param {onSuccessCallback} onSuccess - Gets called when customer service successfully refunds a ticket.
 * @param {onFailureCallback} onFailure - Gets called when refund fails.
 */
function RefundButton({
  ticketData,
  tripData,
  onSuccess,
  onFailure,
  isButtonDisabled,
}) {
  const [isVisible, setIsVisible] = useState(false);

  function openRefundModal() {
    setIsVisible(true);
  }

  function hideModal() {
    setIsVisible(false);
  }

  return (
    <>
      <div className="ml-2">
        <Button disabled={isButtonDisabled} onClick={openRefundModal}>
          <span className="material-icons">payments</span>
          <br />
          Återbetalning
        </Button>
      </div>
      {isVisible && (
        <RefundModal
          show={isVisible}
          onHide={hideModal}
          onSuccess={onSuccess}
          onFailure={onFailure}
          ticketData={ticketData}
          tripData={tripData}
        />
      )}
    </>
  );
}

RefundButton.propTypes = {
  isButtonDisabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  ticketData: PropTypes.object,
  tripData: PropTypes.array,
};

export default RefundButton;
