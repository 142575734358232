import React from 'react';
import PropTypes from 'prop-types';

import { InputGroup, Button, FormControl, Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import SearchIcon from '@mui/icons-material/Search';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import Spinner from '../../components/Spinner';

export default class SearchBookingsBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDatePickerIsOpen: false,
      toDatePickerIsOpen: false,
      activeTab: parseInt(props.timeSpanOption) || 1,
      disableUpdate: false,
    };
    this.openFromDatePicker = this.openFromDatePicker.bind(this);
    this.openToDatePicker = this.openToDatePicker.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  openFromDatePicker() {
    this.setState({
      fromDatePickerIsOpen: !this.state.fromDatePickerIsOpen,
    });
  }
  openToDatePicker() {
    this.setState({
      toDatePickerIsOpen: !this.state.toDatePickerIsOpen,
    });
  }

  handleSelect(selectedTab) {
    // The active tab must be set into the state so that
    // the Tabs component knows about the change and re-renders.
    let show = selectedTab == 1 ? true : false;
    this.setState({
      activeTab: selectedTab,
      disableUpdate: show,
    });
    this.props.setTimeSpanOption(selectedTab);
  }
  render() {
    return (
      <div className="row">
        <div className="col-12 col-md-12 col-xl-auto mb-2 mb-md-3">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <SearchIcon />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              value={this.props.searchQuery}
              placeholder="Sök biljettnr. Bokninsgnr. eller pris"
              aria-label="Sök biljettnr. Bokningsnr. eller pris"
              aria-describedby="basic-addon2"
              size="lg"
              onChange={(e) => this.props.editQuery(e.target.value, 'query')}
              type="search"
              className="custom-clear-btn"
            />
          </InputGroup>
        </div>
        <div className="col-md-auto">
          <div className="row">
            <div className="col-12 col-md col-sm-6 col-xl-auto mb-2 mb-md-0">
              <InputGroup onClick={this.state.fromDatePickerIsOpen ? '' : this.openFromDatePicker} className="ml-0i">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <RestoreOutlinedIcon />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <DatePicker
                  className="form-control form-control-lg pr-0"
                  wrapperClassName="datepicker-with-icon-left"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="d MMM, yy @ HH:mm"
                  onChange={(e) => {
                    this.openFromDatePicker();
                    this.handleSelect(1);
                    this.props.editQuery(e, 'from');
                  }}
                  selected={this.props.fromTime}
                  onClickOutside={this.openFromDatePicker}
                  maxDate={this.props.toTime}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-md col-sm-6 col-xl-auto mb-2 mb-md-0">
              <InputGroup onClick={this.state.toDatePickerIsOpen ? '' : this.openToDatePicker} className="ml-0i">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <UpdateOutlinedIcon />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <DatePicker
                  className="form-control form-control-lg pr-0"
                  wrapperClassName="datepicker-with-icon-left"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="d MMM, yy @ HH:mm"
                  onChange={(e) => {
                    this.openToDatePicker();
                    this.handleSelect(1);
                    this.props.editQuery(e, 'to');
                  }}
                  selected={this.props.toTime}
                  onClickOutside={this.openToDatePicker}
                  minDate={this.props.fromTime}
                />
              </InputGroup>
            </div>
          </div>
          <div className="mb-3 mt-2 row">
            <Nav
              className="nav-pills justify-content-center"
              activeKey={this.state.activeTab}
              onSelect={this.handleSelect}
            >
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Rullande tid för de senaste 7 dagarna </Tooltip>}
              >
                <Nav.Item>
                  <Nav.Link eventKey={'2'} className="nav-link pb-1 pl-2 pr-2 pt-1 rounded-pill small">
                    7 dagar
                  </Nav.Link>
                </Nav.Item>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Rullande tid för de senaste 48 timmarna </Tooltip>}
              >
                <Nav.Item>
                  <Nav.Link eventKey={'3'} className="nav-link pb-1 pl-2 pr-2 pt-1 rounded-pill small">
                    48 timmar
                  </Nav.Link>
                </Nav.Item>
              </OverlayTrigger>
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Från 00:00 detta dygn.</Tooltip>}>
                <Nav.Item>
                  <Nav.Link eventKey={'4'} className="nav-link pb-1 pl-2 pr-2 pt-1 rounded-pill small">
                    Idag
                  </Nav.Link>
                </Nav.Item>
              </OverlayTrigger>
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Från måndag 00:00</Tooltip>}>
                <Nav.Item>
                  <Nav.Link
                    eventKey={'5'}
                    className="nav-link pb-1 pl-2 pr-2 pt-1 rounded-pill small"
                    title="Från måndag 00:00"
                  >
                    Denna vecka
                  </Nav.Link>
                </Nav.Item>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Från den 1:a denna månad kl 00:00 </Tooltip>}
              >
                <Nav.Item>
                  <Nav.Link
                    eventKey={'6'}
                    className="nav-link pb-1 pl-2 pr-2 pt-1 rounded-pill small"
                    title="Från den 1:a denna månad kl 00:00"
                  >
                    Denna månad
                  </Nav.Link>
                </Nav.Item>
              </OverlayTrigger>
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Eget datum och tid</Tooltip>}>
                <Nav.Item>
                  <Nav.Link
                    eventKey={'1'}
                    className="nav-link pb-1 pl-2 pr-2 pt-1 rounded-pill small"
                    title="Eget datum och tid"
                  >
                    Eget datum
                  </Nav.Link>
                </Nav.Item>
              </OverlayTrigger>
            </Nav>
          </div>
        </div>
        <div className="col-12 col-sm col-xl-auto mb-2 mb-md-0">
          <Button
            className="btn btn-block btn-lg btn-primary btn-reLoad"
            disabled={this.state.disableUpdate}
            onClick={() => this.props.editDateSpan(this.state.activeTab)}
          >
            <div className="align-items-center d-flex justify-content-center">
              {this.props.isLoading == true ? <Spinner /> : <RefreshIcon />}
              Uppdatera
            </div>
          </Button>
        </div>
      </div>
    );
  }
}
SearchBookingsBar.propTypes = {
  editQuery: PropTypes.func,
  searchQuery: PropTypes.string,
  fromTime: PropTypes.object,
  toTime: PropTypes.object,
  setTimeSpanOption: PropTypes.func,
  editDateSpan: PropTypes.func,
  timeSpanOption: PropTypes.string,
  disableUpdate: PropTypes.bool,
  isLoading: PropTypes.bool,
};
