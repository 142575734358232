import React from "react";
import PropTypes from "prop-types";

import { Form } from "react-bootstrap";

/**
 * @callback OnChangePageSizeCallback
 * @param {number} newPageSize - The new page size set by the user.
 */

/**
 * Display controls for updating the size of a page.
 *
 * @param {number} currentPageSize - Currently selected page size.
 * @param {number} currentPage - Currently selected page.
 * @param {OnChangePageSizeCallback} onChangePageSize - Called when user is updating the page size.
 * @param {string} className - Custom styling.
 * @param {string} pageSizesOption - Array of values for dropdown.
 * @param {string} totalSize - Total amount of rows.
 */
function PageSizeInput({
  currentPageSize,
  currentPage,
  onChangePageSize,
  className,
  pageSizesOption,
  totalSize,
}) {
  function selectOnChange({ target }) {
    // we trust the dropdown input completely
    const pageSize = parseInt(target.value);
    onChangePageSize && onChangePageSize(pageSize);
  }

  // sizes displayed in the select box
  const availableSizes = pageSizesOption;

  return (
    <div className="d-flex">
      <Form className={className}>
        <Form.Group className="d-flex align-items-center mb-0">
          <Form.Label className="my-0 ml-0 mr-2">Resultat per sida</Form.Label>
          <select
            value={currentPageSize}
            className="form-select w-auto py-0 "
            onChange={selectOnChange}
            data-testid="pagesizeinput-select"
          >
            {availableSizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
          {/* Alot of logic for calculating correct number of rows. 
      Depending on if it's on the first page, or on the last page and showing fewer results that total size, or something inbetween 
      */}
      <span className="my-0 ml-2 mr-2 form-label">
        Visar rad{" "}
        {currentPage == 1 ? 1 : (currentPage - 1) * currentPageSize + 1} till{" "}
        {currentPage * currentPageSize > totalSize ? totalSize : currentPageSize * currentPage}
        {" "} av {totalSize}
      </span>
        </Form.Group>
      </Form>

      
    </div>
  );
}

PageSizeInput.propTypes = {
  currentPageSize: PropTypes.number.isRequired,
  onChangePageSize: PropTypes.func,
  className: PropTypes.string,
  pageSizesOption: PropTypes.array,
  currentPage: PropTypes.number,
  totalSize: PropTypes.number,
};

export default PageSizeInput;
