import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Dropdown from "react-bootstrap/Dropdown";

import Spinner from "../../components/Spinner";
import Api from "./matrixApi.js";
import { useMsal } from "@azure/msal-react";
/**
 * Callback when a station has been selected
 *
 * @callback onSelectCallback
 * @param {string} stationName - The item in the list that was selected
 */

/**
 * A control to select station in a list
 *
 * @param {string} id - Unique name for this control
 * @param {string} title - Button text
 * @param {string[]} data - A string list of stations
 * @param {onSelectCallback} onSelect - Calling this callback when a station has been selected
 */
export function SelectStation({ id, title, data = [], onSelect }) {
  function triggerOnSelected(href) {
    if (onSelect) {
      onSelect(href.substr(2));
    }
  }

  return (
    <Dropdown className="col-2 my-2" onSelect={triggerOnSelected}>
      <Dropdown.Toggle variant="primary" id={id}>
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu renderOnMount={true}>
        {data.sort().map((item, i) => (
          <Dropdown.Item key={i} href={`#/${item}`}>
            {item}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

SelectStation.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
};

/**
 * Load the matrix details.
 *
 * @param {string} fileId - Unique ticket matrix identifier.
 * @param {onFailureCallback} onFailure - Callback when ticket matrix details fails to load.
 * @returns
 */
function ProductTemplateTable({ fileId, onFailure }) {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(true);
  const [templateData, setTemplateData] = useState();

  function filterOrigin(station) {
    setTemplateData(
      templateData.map((item) => ({
        ...item,
        visible: item.from === station,
      }))
    );
  }

  function filterDestination(station) {
    setTemplateData(
      templateData.map((item) => ({
        ...item,
        visible: item.to === station,
      }))
    );
  }

  function resetFilter() {
    setTemplateData(
      templateData.map((item) => ({
        ...item,
        visible: true,
      }))
    );
  }

  function successTicketMatrixDetails(data) {
    if (data.templates) {
      setTemplateData(
        data.templates.map((item) => ({
          from: item.description.split(" - ")[0],
          to: item.description.split(" - ")[1],
          fullPrice: item.fareDefinitions[0].amountInclusive,
          reducedPrice: item.fareDefinitions[1].amountInclusive,
          visible: true,
        }))
      );
    }

    setIsLoading(false);
  }

  function failureTicketMatrixDetails() {
    setIsLoading(false);
    if (onFailure) {
      onFailure("Misslyckades att hämta detaljer för biljettmatris");
    }
  }

  // load template data
  useEffect(() => {
    Api.getDetailsAsync(instance, fileId)
      .then(successTicketMatrixDetails)
      .catch(failureTicketMatrixDetails);
  }, [fileId]);

  return (
    <div>
      {isLoading && <Spinner />}
      {!isLoading && (
        <div className="">
          {templateData && (
            <>
              <div className="row">
                <h4>Biljettmatris</h4>
                <SelectStation
                  id="originSelector"
                  title="Välj från"
                  data={[...new Set(templateData.map((item) => item.from))]}
                  onSelect={filterOrigin}
                />
                <SelectStation
                  id="destinationSelector"
                  title="Välj till"
                  data={[...new Set(templateData.map((item) => item.to))]}
                  onSelect={filterDestination}
                />
                <button
                  type="button"
                  className="btn btn-link mx-2 col-2"
                  onClick={resetFilter}
                >
                  Återställ
                </button>
              </div>
              <table className="table table-bordered table-sm">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Från</th>
                    <th scope="col">Till</th>
                    <th scope="col">Hel. pris</th>
                    <th scope="col">Red. pris</th>
                  </tr>
                </thead>
                <tbody>
                  {templateData
                    .filter((item) => item.visible)
                    .map((item, i) => (
                      <tr key={i} data-testid="product-template-table-row">
                        <td>{item.from}</td>
                        <td>{item.to}</td>
                        <td>{item.fullPrice} kr</td>
                        <td>{item.reducedPrice} kr</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      )}
    </div>
  );
}

ProductTemplateTable.propTypes = {
  fileId: PropTypes.string.isRequired,
  onFailure: PropTypes.func,
};

export default ProductTemplateTable;
