import api from "../../lib/api";

const trafficInfoUrl = "/api/v1/trafficinformation";

/**
 * Get all information at a station from the API.
 *
 * @returns {Promise<array>} - All information at a station from the API.
 */
async function getTrafficInformation(msalInstance, id) {
  // Get all information at a specific station
  const accessToken = await api.getAccessTokenAsync(msalInstance);
  const response = await fetch(`${trafficInfoUrl}/trafficinfo/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  var result = await response.json();
  result.departureDeviations.sort(
    (x, y) => new Date(x.originalTime) - new Date(y.originalTime)
  );
  result.arrivalDeviations.sort(
    (x, y) => new Date(x.originalTime) - new Date(y.originalTime)
  );

  if (api.isSuccessful(response)) {
    return result;
  } else {
    throw new Error("Failed to fetch traffic info from the API.");
  }
}

async function fetchTypeaheadData(msalInstance, searchInput, signal) {
  const accessToken = await api.getAccessTokenAsync(msalInstance);
  var response = await fetch(`${trafficInfoUrl}/typeahead/${searchInput}`, {
    method: "GET",
    header: {
      Authorization: `Bearer ${accessToken}`,
    },
    signal
  });

  const content = await response.text();
  const result = content.length > 0 ? JSON.parse(content) : {}

  if (api.isSuccessful(response)) {
    return result;
  } else {
    throw new Error(`Failed to fetch bookings data}`);
  }
}

export default {
  getTrafficInformation,
  fetchTypeaheadData,
};
