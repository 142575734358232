import api from "../../lib/api";

const baseUrl = "/api/v1/products/matrix";

/**
 * Delete a ticket matrix.
 *
 * @param {string} id - Unique identifier of the ticket matrix.
 * @returns {boolean} - True if successful, otherwise throws an error.
 */
async function deleteAsync(msalInstance, id) {
  const accessToken = await api.getAccessTokenAsync(msalInstance);

  // call the API to delete
  const response = await fetch(`${baseUrl}/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (api.isSuccessful(response)) {
    return true;
  } else {
    throw new Error(`Failed to delete the file: ${id}`);
  }
}

/**
 * Schedule a ticket matrix.
 * @param {string} id - Unique identifier for the ticket matrix.
 * @param {Date} validFrom - Date when this ticket matrix is valid.
 * @returns {boolean} - True if successful, otherwise throws an error.
 */
async function scheduleAsync(msalInstance, id, validFrom) {
  const accessToken = await api.getAccessTokenAsync(msalInstance);

  // call the API to schedule the matrix
  const response = await fetch(`${baseUrl}/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ validFrom }),
  });

  if (api.isSuccessful(response)) {
    return true;
  } else {
    throw new Error(`Failed to schedule ticket matrix: ${id}`);
  }
}

/**
 * Get all the details, including template data for the ticket matrix
 *
 * @param {string} id - Unique ticket matrix identifier
 */
async function getDetailsAsync(msalInstance, id) {
  const accessToken = await api.getAccessTokenAsync(msalInstance);

  // call the API to get the details
  const response = await fetch(`${baseUrl}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (api.isSuccessful(response)) {
    return await response.json();
  } else {
    throw new Error(`Failed to get ticket matrix details: ${id}`);
  }
}

/**
 * Callback for successful delete of file.
 *
 * @callback onProgressCallback
 * @param {number} progress - A number between 0-100 indicating the progress.
 */

/**
 * Upload a new file to the server.
 *
 * @param {File} file - The file that is going to be uploaded.
 * @param {string} createdBy - Name of the person that is uploading the file.
 * @param {onProgressCallback} onProgress - Callback that will trigger while uploading file.
 */
function uploadFileAsync(msalInstance, file, createdBy, onProgress) {
  return new Promise((resolve, reject) => {
    api
      .getAccessTokenAsync(msalInstance)
      .then((accessToken) => {
        let formData = new FormData();
        formData.append("createdBy", createdBy);
        formData.append("File", file);

        // going old school here because I want upload progress indicator
        let request = new XMLHttpRequest();
        request.open("POST", baseUrl);
        request.setRequestHeader("Authorization", `Bearer ${accessToken}`);

        // upload progress event
        request.upload.addEventListener("progress", (e) => {
          if (onProgress) {
            onProgress(Math.round(100 * (e.loaded / e.total)));
          }
        });

        // request finished
        request.addEventListener("load", () => {
          if (api.isSuccessful(request)) {
            resolve(true);
          } else {
            reject(
              `Fel vid uppladdning av fil ${file.name}. Klicka på filen i tabellen för detaljerad information.`
            );
          }
        });

        // request couldn't be made
        request.addEventListener("error", () => reject("Anslutningsfel"));

        // request was aborted
        request.addEventListener("abort", () => reject("Anslutningen avbröts"));

        request.send(formData);
      })
      .catch((error) => reject(error));
  });
}

/**
 * Get all matrixes from the API.
 *
 * @returns {array} - All matrixes from the API.
 */
async function getMatrixesAsync(msalInstance) {
  const accessToken = await api.getAccessTokenAsync(msalInstance);

  // get all matrixes from the API
  const response = await fetch(baseUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (api.isSuccessful(response)) {
    return await response.json();
  } else {
    throw new Error(`Failed to fetch ticket matrixes from API`);
  }
}

async function getActiveAccount(msalInstance)
{
  return await api.getActiveAccount(msalInstance);
}

export default {
  deleteAsync,
  scheduleAsync,
  getDetailsAsync,
  uploadFileAsync,
  getMatrixesAsync,
  getActiveAccount,
};
