import React, { useRef } from "react";
import PropTypes from "prop-types";

import { Form, Button } from "react-bootstrap";

import operatorSymbolMap from "./operatorSymbolMap";

/**
 * @typedef Filter
 * @param {string} property - Name of the property to be filtered.
 * @param {string} operator - The operator used for filtering, could be eq, ne, lt, gt
 * @param {string} value - Value of the filter.
 */

/**
 * @callback OnSubmitCallback
 * @param {Filter} filter - The filter that was submitted.
 */

/**
 * A form for adding custom filters.
 * @param {string[]} facets - Facets that will be available for filtering.
 * @param {OnSubmitCallback} onSubmit - Callback triggered on submitting the new filter.
 * @returns
 */
function CustomFilterInput({ facets, onSubmit }) {
  const propertyRef = useRef();
  const operatorRef = useRef();
  const valueRef = useRef();

  function createFilter() {
    const filter = {
      property: propertyRef.current.value,
      operator: operatorRef.current.value,
      value: valueRef.current.value,
    };
    onSubmit && onSubmit(filter);
  }

  return (
    <Form onSubmit={createFilter}>
      <Form.Group className="d-flex flex-row gap-2 align-items-stretch">
        <select
          className="form-select"
          aria-label="Default select facet"
          ref={propertyRef}
          role="property"
        >
          {facets.map((facet) => (
            <option key={facet} value={facet}>
              {facet}
            </option>
          ))}
        </select>
        <select
          className="form-select"
          aria-label="Default select operator"
          ref={operatorRef}
          role="operator"
        >
          <option value="eq">{operatorSymbolMap["eq"]}</option>
          <option value="ne">{operatorSymbolMap["ne"]}</option>
          <option value="lt">{operatorSymbolMap["lt"]}</option>
          <option value="gt">{operatorSymbolMap["gt"]}</option>
        </select>
        <Form.Control
          className="h-100"
          type="text"
          placeholder="value.."
          ref={valueRef}
          role="value"
          size="lg"
        />
        <Button onClick={createFilter} role="submitCustomFilter">
          +
        </Button>
      </Form.Group>
    </Form>
  );
}

CustomFilterInput.propTypes = {
  facets: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func,
};

export default CustomFilterInput;
