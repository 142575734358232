/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { default as Select } from "react-select";
import { components } from "react-select";


// const generateValue = (options, defaultValue, isMulti = false) => {
//   if (defaultValue != undefined){
//     const value = isMulti ? options.filter(option => defaultValue.includes(option.value))
//   : options.find(option => option.value === defaultValue);
//   return value;
//   }
//   else return ''
// };

const CustomOption = (props) => {
  return(
    <div>
        <components.Option {...props}>
          <input type="checkbox" checked={props.isSelected} />{" "}
          <label>{props.label}</label>
        </components.Option>
    </div>
  )
}

function ColumnFilter ({...props}) {
  return <Select {...props} 
      classNamePrefix={'mdt-column-filter'}
      components = {{Option:CustomOption}}
      styles={{
          option : (styles) => ({
            ...styles,
            fontSize: "0.75em !important"
            })

        }}
        value={props.value}
  />;
}

ColumnFilter.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    category: PropTypes.string
  })
};
export default ColumnFilter;