import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Api from '../../lib/api';
import sortOrder from '../../types/sortOrder';

import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Table } from 'react-bootstrap';
import ColumnFilter from '../../components/ColumnFilter.js';
import { useMsal } from '@azure/msal-react';
import { orderListDisplay } from '../../lib/utils';
import ColumnSortOrder from '../../components/ColumnSortOrder';

const filterOptionsTicketType = [{ value: 'enkel', label: 'Enkelbiljett', category: 'biljettyp' }];

const filterOptionsPayment = [
  { value: 'card', label: 'Betalkort', category: 'payment' },
  { value: 'swish', label: 'Swish', category: 'payment' },
];

const filterOptionsChannel = [
  { value: 'MDT App', label: 'MDT App', category: 'channel' },
  { value: 'MDT Kundtjänst', label: 'MDT Kundtjänst', category: 'channel' },
  { value: 'MDT Web', label: 'MDT Webb', category: 'channel' },
];

const filterOptionsStatus = [
  { value: 'TicketClaimed', label: 'Biljett hämtad', category: 'status' },
  { value: 'PaymentSucceeded', label: 'Betalning lyckad', category: 'status' },
  { value: 'PaymentPending', label: 'Betalning väntar', category: 'status' },
  {
    value: 'PaymentTimedOut',
    label: 'Betalningstid passerad',
    category: 'status',
  },
  { value: 'TicketIssueSucceeded', label: 'Lyckad', category: 'status' },
  { value: 'TicketIssueFailed', label: 'Misslyckades', category: 'status' },
  { value: 'Blacklisted', label: 'Spärrad', category: 'status' },
  { value: 'RefundSucceeded', label: 'Återbetalad', category: 'status' },
  { value: 'RefundPending', label: 'Återbetalning pågår', category: 'status' },
  {
    value: 'RefundFailed',
    label: 'Återbetalning misslyckades',
    category: 'status',
  },
  {
    value: 'TransactionStarted',
    label: 'Överföring startad',
    category: 'status',
  },
  { value: 'RebookingSucceeded', label: 'Ombokad', category: 'status' },
  {
    value: 'RebookingFailed',
    label: 'Ombokning misslyckades',
    category: 'status',
  },
];

const useStickyState = (key = 'sticky', initialState = null) => {
  const [state, setState] = useState(() => {
    const jsontemp = sessionStorage.getItem(key);
    const storedState = JSON.parse(jsontemp);
    return storedState ?? initialState;
  });

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  const clearState = () => sessionStorage.removeItem(key);

  return [state, setState, clearState];
};

/**
 *
 */
function BookingsMatrixRow({ input, customerService, ...props }) {
  var dateTimeOptions = {
    hour12: false,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  function toLocalTime(utcTime) {
    return new Date(utcTime).toLocaleString('sv-SE', dateTimeOptions);
  }

  return (
    <tr data-testid="access-matrix-row" {...props}>
      <td>{toLocalTime(input.timestamp)}</td>
      <td>{input.productCategory}</td>
      <td>{toLocalTime(input.validity)}</td>
      <td>{input.travellers}</td>
      <td>{input.amount} KR</td>
      <td>{input.channel.name}</td>
      <td>{input.status}</td>
      <td>{input.paymentMethod}</td>
      <td>{input.orderId}</td>
      <td>{orderListDisplay(input.tickets)}</td>
      {customerService && (
        <td>
          <Link title="Öppna biljett" to={'/bookings/ticket/' + input.correlationId}>
            <ConfirmationNumberIcon />
          </Link>
        </td>
      )}
    </tr>
  );
}
BookingsMatrixRow.propTypes = {
  input: PropTypes.shape({
    amount: PropTypes.string,
    correlationId: PropTypes.string,
    orderId: PropTypes.string,
    tickets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        isBlackListed: PropTypes.bool,
        status: PropTypes.number,
      }),
    ),
    currency: PropTypes.string,
    id: PropTypes.string,
    paymentReference: PropTypes.string,
    paymentMethod: PropTypes.string,
    validity: PropTypes.string,
    productCategory: PropTypes.string,
    status: PropTypes.string,
    timestamp: PropTypes.string,
    travellers: PropTypes.string,
    channel: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  customerService: PropTypes.bool,
};

/**
 * Display ticket bookings in a matrix.
 * @param {Data} data - Bookings data to display in the table.
 * @param {onChangeCallback} onChange - Callback when an action is made on the matrix.
 */
function BookingsMatrix({ data, onChange, sortOrderCurrent }) {
  const { instance } = useMsal();
  const [columnFilterTicketType, setColumnFilterTicketType] = useStickyState('bookingsFilterTicketType', []);
  const [columnFilterChannel, setColumnFilterChannel] = useStickyState('bookingsFilterChannel', []);
  const [columnFilterStatus, setColumnFilterStatus] = useStickyState('bookingsFilterStatus', []);
  const [columnFilterPayment, setColumnFilterPayment] = useStickyState('bookingsFilterPayment', []);
  const [customerService, setcustomerService] = useState(false);

  async function isCustomerService() {
    const activeUser = await Api.getActiveAccount(instance);
    if (activeUser.idTokenClaims.groups.includes('0fb24b2f-22e6-4e12-8da3-77f1c9b66ba3')) {
      setcustomerService(true);
    }
  }
  isCustomerService();

  useEffect(() => {
    onChange('queryTicketType', columnFilterTicketType);
  }, [columnFilterTicketType]);
  useEffect(() => {
    onChange('queryStatus', columnFilterStatus);
  }, [columnFilterStatus]);
  useEffect(() => {
    onChange('queryPayment', columnFilterPayment);
  }, [columnFilterPayment]);
  useEffect(() => {
    onChange('queryChannel', columnFilterChannel);
  }, [columnFilterChannel]);
  return (
    <Fragment>
      <Table className="order-table" hover striped>
        <thead>
          <tr>
            <th
              onClick={() => {
                onChange('sortField', 'timestamp');
                onChange('sortOrder', sortOrderCurrent == 'asc' ? 'desc' : 'asc');
              }}
            >
              <a>
                Köpdatum <ColumnSortOrder order={sortOrderCurrent == 'asc' ? sortOrder.ASC : sortOrder.DESC} />
              </a>
            </th>
            {/* {sortOrderCurrent == 'asc' ? (
              <th
                onClick={() => {
                  onChange('sortField', 'timestamp');
                  onChange('sortOrder', 'desc');
                }}
              >
                <a>
                  Köpdatum <ColumnSortOrder order={sortOrder} />
                </a>
              </th>
            ) : (
              <th
                onClick={() => {
                  onChange('sortField', 'timestamp');
                  onChange('sortOrder', 'asc');
                }}
              >
                <a>
                  Köpdatum <KeyboardArrowDownIcon />{' '}
                </a>
              </th>
            )} */}
            <th>
              Biljettyp
              <ColumnFilter
                options={filterOptionsTicketType}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                value={columnFilterTicketType}
                onChange={setColumnFilterTicketType}
                placeholder="Visa endast.."
                isSearchable={false}
              />
            </th>
            <th>Giltig t.o.m</th>
            <th>Resenärer</th>
            <th>Pris</th>
            <th>
              Säljkanal
              <ColumnFilter
                options={filterOptionsChannel}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                value={columnFilterChannel}
                onChange={setColumnFilterChannel}
                placeholder="Visa endast.."
                isSearchable={false}
              />
            </th>
            <th>
              Status
              <ColumnFilter
                options={filterOptionsStatus}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                value={columnFilterStatus}
                onChange={setColumnFilterStatus}
                placeholder="Visa endast.."
                isSearchable={false}
              />
            </th>
            <th>
              Betalsätt
              <ColumnFilter
                options={filterOptionsPayment}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                value={columnFilterPayment}
                onChange={setColumnFilterPayment}
                placeholder="Visa endast.."
                isSearchable={false}
              />
            </th>
            <th>Bokningsnr.</th>
            <th>Biljettnr.</th>

            {customerService && <th></th>}
          </tr>
        </thead>
        <tbody>
          {data.map((info, index) => (
            <BookingsMatrixRow input={info} key={index} onChange={onChange} customerService={customerService} />
          ))}
        </tbody>
      </Table>
    </Fragment>
  );
}
BookingsMatrix.propTypes = {
  data: PropTypes.arrayOf(BookingsMatrixRow.propTypes.input),
  onChange: PropTypes.func,
  sortOrderCurrent: PropTypes.string,
};

export default BookingsMatrix;
