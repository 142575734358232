import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";

import { Row } from "react-bootstrap";

import Search from "./Search";
import DateRange from "./DateRange";
import CustomFilter from "./CustomFilter";

// these properties are part of the base entity of an event and will not appear
// in the custom properties. They are still relevant for filters.
const stringProperties = [
  "Category",
  "RoleName",
  "RoleId",
  "TargetName",
  "TargetId",
  "OperationName",
  "OperationId",
];
const isStringProperty = ({ property }) => stringProperties.includes(property);

// bool properties cannot be compared with `'true'`, instead must use unquoted `true`
const boolProperties = ["Success"];
const isBoolProperty = ({ property, value }) =>
  (value === true ||
    value === false ||
    value === "true" ||
    value === "false") &&
  boolProperties.includes(property);

// same with number properties
const numberProperties = ["ResultCode"];
const isNumberProperty = ({ property, value }) =>
  !isNaN(parseInt(value)) && numberProperties.includes(property);

/**
 * @typedef Filter
 * @param {string} property - Name of the property to be filtered.
 * @param {string} operator - The operator used for filtering, could be eq, ne, lt, gt
 * @param {string} value - Value of the filter.
 */

/**
 * @typedef SearchOptions
 * @param {string} query - The free text search query
 * @param {string} filter - The applied search filter expression
 */

/**
 * @callback OnChangeCallback
 * @param {SearchOptions} - Search options to use running the search query.
 */

/**
 * The combined search and filter controls.
 * @param {any}} facets - Filterable properties from the search result.
 * @param {OnChangeCallback} onChange - Triggers when a search or filter property was changed.
 * @param {Filter} filter - Set this property if you want to add filter from the outside.
 */
function SearchFilter({ facets = {}, onChange, filter }) {
  // We use this to store the filter expressions before we pass them to onChange callback.
  // This is because from, to and custom filters are managed by other components
  // We'll make sure to parse them into filter expressions here before we pass them on.
  const [filterDefinition, setFilterDefinition] = useState(null);

  // when filter definition is updated, trigger onChange
  useEffect(() => {
    if (filterDefinition) {
      onChange && onChange(filterDefinition);
    }
  }, [filterDefinition]);

  // search query has been changed
  function updateSearchQuery(query) {
    if (query == "") {
      setFilterDefinition({ ...filterDefinition, query });
    } else {
      // Add * to allow the query to search for all substrings of the query
      let wildcardQuery = query + "*";
      setFilterDefinition({ ...filterDefinition, query: wildcardQuery });
    }
  }

  // update the date range filter
  function updateDateFilter({ from, to }) {
    const fromExpression = from ? `TimeStamp gt ${from.toISOString()}` : null;
    const toExpression = to ? `TimeStamp lt ${to.toISOString()}` : null;

    setFilterDefinition({
      ...filterDefinition,
      from: fromExpression,
      to: toExpression,
    });
  }

  // turn custom filters into filter expressions
  function updateCustomFilters(filters) {
    // turn a string property to a filter expression
    const stringPropertyExpression = ({ property, operator, value }) =>
      `${property} ${operator} '${value}'`;

    // turn a bool property to a filter expression
    const boolPropertyExpression = ({ property, operator, value }) =>
      `${property} ${operator} ${value}`;

    // turn a number property to a filter expression
    const numberPropertyExpression = ({ property, operator, value }) =>
      `${property} ${operator} ${value}`;

    // is this a custom property that we have in our facets?
    const isCustomProperty = ({ property }) => {
      const customProperties = facets["Properties/Key"] ?? [];
      return customProperties.find((f) => f.value === property);
    };

    // map it to a custom property expression
    const customPropertyExpression = ({ property, operator, value }) =>
      `Properties/any(p: p/Key eq '${property}' and p/Value ${operator} '${value}')`;

    const filterExpressions = [
      ...filters.filter(isStringProperty).map(stringPropertyExpression),
      ...filters.filter(isBoolProperty).map(boolPropertyExpression),
      ...filters.filter(isNumberProperty).map(numberPropertyExpression),
      ...filters.filter(isCustomProperty).map(customPropertyExpression),
    ];

    filterExpressions; //?

    setFilterDefinition({ ...filterDefinition, filters: filterExpressions });
  }

  // select what properties should be filterable
  function prepareFilterProperties(facets) {
    let properties = [
      ...stringProperties,
      ...boolProperties,
      ...numberProperties,
    ];

    if (facets) {
      properties = [...properties, ...facets.map((f) => f.value)];
    }

    // mutating sort
    properties.sort();
    return properties;
  }

  return (
    <Fragment>
        <Row>
          <div className="col-12 col-md-12 col-xl-4 mb-2 mb-md-3">
            <Search onSearch={updateSearchQuery} />
          </div>
          <div className="col-md-auto">
            <DateRange onChange={updateDateFilter} />
          </div>
        </Row>
        <Row>
          <div className="col-12 col-md-12 col-xl-6 mb-2 mb-md-3">
          <CustomFilter
              facets={prepareFilterProperties(facets["Properties/Key"])}
              filter={filter}
              onChange={updateCustomFilters}
            />
          </div>
        </Row>
    </Fragment>
  );
}

SearchFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  facets: PropTypes.object,
  filter: PropTypes.shape({
    property: PropTypes.string.isRequired,
    operator: PropTypes.oneOf(["eq", "ne", "gt", "lt"]),
    value: PropTypes.string.isRequired,
  }),
};

export default SearchFilter;
