import React, { useState } from "react";
import PropTypes from "prop-types";

import DeleteIcon from "@mui/icons-material/Delete";

import UtilityButton from "../../components/UtilityButton";
import Spinner from "../../components/Spinner";
import Api from "./matrixApi";
import { useMsal } from "@azure/msal-react";
/**
 * Callback for successful delete of file.
 *
 * @callback onSuccessCallback
 */

/**
 * Callback for failed deletion of file.
 *
 * @callback onFailureCallback
 * @param {string} errorMessage - A message describing what went wrong.
 */

/**
 * Shows a delete button icon, that will call delete on the product matrix when clicked.
 *
 * @param {string} fileId - Unique identifier for a ticket matrix file
 * @param {boolean} isDisabled - When true will not trigger on click event. Default is false.
 * @param {onSuccessCallback} onSuccess - Is called when delete was successful.
 * @param {onFailureCallback} onFailure - Is called when failed to delete the file.
 */
function DeleteButton({ fileId, isDisabled, onSuccess, onFailure }) {
  // this flag controls if the icon or the spinner should be visible
  const [isDeleting, setIsDeleting] = useState(false);
  const { instance } = useMsal();
  // on successful deletion
  function deletedFileSuccess() {
    setIsDeleting(false);
    if (onSuccess) {
      onSuccess();
    }
  }

  // when deletion fails
  function deletedFileFailure() {
    setIsDeleting(false);
    if (onFailure) {
      onFailure("Misslyckades att ta bort prismatris");
    }
  }

  function deleteFile(id, ev) {
    setIsDeleting(true);
    Api.deleteAsync(instance, id).then(deletedFileSuccess).catch(deletedFileFailure);

    // do not propagate the click to the table row
    // since table row has its own click event that
    // would cause it to expand/collapse when clicking
    // the delete button.
    ev.stopPropagation();
  }

  return (
    <UtilityButton
      title="Radera fil"
      onClick={(ev) => deleteFile(fileId, ev)}
      isDisabled={isDisabled || isDeleting}
    >
      {isDeleting ? <Spinner /> : <DeleteIcon className="mx-2" />}
    </UtilityButton>
  );
}

DeleteButton.propTypes = {
  fileId: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
};

export default DeleteButton;
