import React, { useState } from "react";
import {
  UnauthenticatedTemplate,
  AuthenticatedTemplate,
} from "@azure/msal-react";

import ErrorDialog from "../../components/ErrorDialog";

function Dashboard() {
  const [error, setError] = useState(null);

  const clearError = () => setError(null);

  return (
    <>
      <UnauthenticatedTemplate></UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <div className="text-center mt-4">
          <h1 className="mb-4">Välkommen till Transithub!</h1>

          {error && (
            <ErrorDialog
              title="Hoppsan, nu blev det lite tokigt!"
              onClose={clearError}
            >
              <p>{error.message}</p>
            </ErrorDialog>
          )}
        </div>
      </AuthenticatedTemplate>
    </>
  );
}

export default Dashboard;
