function ToNotesInformation(code) {
  switch (code) {
    case "A1":
      return "Mat 1 klass";
    case "A6":
      return "Affärs Plus finns";
    case "A7":
      return "Djur ej tillåtet";
    case "AA":
      return "Endast 2 klass";
    case "AB":
      return "2 kl har ej reservering";
    case "AC":
      return "Servering";
    case "AD":
      return "Bistrovagn";
    case "AE":
      return "Ej reservering";
    case "AF":
      return "Obligatorisk platsbokning";
    case "AG":
      return "Frivillig platsbokning";
    case "AK":
      return "Familjevagn";
    case "AL":
      return "Biovagn";
    case "AM":
      return "Särtillägg";
    case "AN":
      return "Rullstolslyft";
    case "AO":
      return "Ej kortdistans";
    case "AP":
      return "Länstrafikpris vid lokal resa";
    case "AU":
      return "Panoramavagn";
    case "AW":
      return "WiFi";
    case "AZ":
      return "Förbeställd frukost";
    case "BF":
      return "1 kl har ej reservering";
    case "BG":
      return "Bagage";
    case "D1":
      return "Djur tillåtna, se regler";
    case "D2":
      return "Djur tillåtna, se regler.";
    case "D3":
      return "Djur tillåtna, se regler..";
    case "EL":
      return "Eluttag, bokas";
    case "ES":
      return "Ej sittvagn";
    case "FB":
      return "Cyklar tillåtna";
    case "FC":
      return "Cykelplats(Obl.bokning)";
    case "FM":
      return "1 kl u mat har ej reservering";
    case "KI":
      return "Skidor";
    case "KU":
      return "Kulturvagn";
    case "LW":
      return "Liggvagn";
    case "PE":
      return "Dags-/ Kvällspress i 1 klass";
    case "PS":
      return "Sällskapsvagn";
    case "S4":
      return "4 - bäddskupé i sovvagn";
    case "SA":
      return "Stena Line buffé";
    case "SD":
      return "Öl, vin och spritservering";
    case "SF":
      return "Frukost 1 klass";
    case "SG":
      return "Krog";
    case "SH":
      return "Sänkt hastighet. Kan försenas.";
    case "SI":
      return "Servering finns ej";
    case "SK":
      return "Kafé";
    case "SL":
      return "Sov-och liggvagn";
    case "SM":
      return "Maträtt";
    case "SN":
      return "Måltid Plus";
    case "SQ":
      return "Förbeställd mat";
    case "SR":
      return "Förbeställd Frukost";
    case "SS":
      return "Servering vid sittplats 1 kl";
    case "SU":
      return "1 klass med extra komfort";
    case "SV":
      return "Varm mat kan förbeställas";
    case "SW":
      return "Sovvagn";
    case "SX":
      return "Ej wifi";
    case "SY":
      return "Ej luftkonditionering";
    case "SZ":
      return "Kaffebuffé 1 klass";
    case "TG":
      return "GRÖNA TÅGET";
    case "V1":
      return "X-Tåget";
    case "V3":
      return "SJ3000";
    case "VA":
      return "Arlanda Express";
    case "VC":
      return "SJ / NSB";
    case "VF":
      return "Östgötapendeln";
    case "VG":
      return "Pågatågen";
    case "VI":
      return "Kustpilen";
    case "VK":
      return "Krösatåg";
    case "VO":
      return "Norrtåg";
    case "VP":
      return "Upptåget";
    case "VS":
      return "Blå Tåget - Skandinaviska Jernbanor";
    case "VT":
      return "Tåg I Bergslagen";
    case "VV":
      return "Västtågen";
    case "VW":
      return "Arctic Circle";
    case "VX":
      return "X2000";
    default:
      return "Okänd";
  }
}

function ToMoms(amount) {
  return parseFloat(amount) * (0.0566);
}

export default {
  ToNotesInformation,
  ToMoms,
};
