import React from "react";
import { connect } from "react-redux";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { Navbar, NavDropdown } from "react-bootstrap";

const NavbarComponent = () => {
  const { instance } = useMsal();

  function getActiveAccount() {
    const activeAccount = instance.getActiveAccount();
    const accounts = instance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      // user is not signed in, throw error
      throw new Error("User is not signed in");
    } else {
      return activeAccount || accounts[0];
    }
  }

  function getAccountName() {
    const account = getActiveAccount();
    return account.name;
  }

  function handleLogout() {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }

  return (
    <>
      <UnauthenticatedTemplate></UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Navbar color="navbar-bg">
          <Navbar.Collapse
            id="settings-navbar-nav"
            className="justify-content-end"
          >
            <NavDropdown
              title={getAccountName()}
              id="settings-navbar-dropdown"
              className="text-dark"
            >
              <NavDropdown.Item href="#logout" onClick={handleLogout}>
                Logga ut
              </NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>
        </Navbar>
      </AuthenticatedTemplate>
    </>
  );
};

export default connect((store) => ({
  app: store.app,
}))(NavbarComponent);
