import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

import AuditTableDetails from './AuditTableDetails';
import Tag from './Tag';
import { dateFormat } from '../../lib/utils';

/**
 * Icon that indicate if the table row is expanded or not.
 * @param {boolean} isExpanded - True will display a down icon, otherwise a right icon.
 */
function ExpandedIcon({ isExpanded }) {
  return isExpanded ? <ArrowDropDownRoundedIcon /> : <ArrowRightRoundedIcon />;
}

ExpandedIcon.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
};

/**
 * @typedef Filter
 * @param {string} property - Name of the property to be filtered.
 * @param {string} operator - The operator used for filtering, could be eq, ne, lt, gt
 * @param {string} value - Value of the filter.
 */

/**
 * @callback OnFilterCallback
 * @param {Filter} filter
 */

/**
 * A table row.
 *
 * @component
 * @param {any} document - The document that should be rendered on this row.
 * @param {OnFilterCallback} onFilter - Called when a filter button is pressed.
 */
function AuditTableRow({ document, onFilter }) {
  const [isExpanded, setIsExpanded] = useState(false);

  function toggleExpanded() {
    setIsExpanded(!isExpanded);
  }

  return (
    <>
      <tr onClick={toggleExpanded} style={{ cursor: 'pointer' }} data-testid="audittablerow-tr">
        <td className="pr-0" style={{ paddingLeft: '0.7rem' }}>
          <ExpandedIcon isExpanded={isExpanded} />
        </td>
        <td className="pl-0">{dateFormat(document.timeStamp)}</td>
        <td>
          <Tag name={document.roleName} />
          {document.roleId}
        </td>
        <td>
          <Tag name={document.operationName} />
          {document.operationId}
        </td>
        <td>
          <Tag name={document.targetName} />
          {document.targetId}
        </td>
        <td>
          <Tag name={document.category} bg="info" />
        </td>
        <td>
          {document.success ? (
            <Tag name="success" bg="success" />
          ) : (
            <>
              <Tag name="failure" bg="danger" />
              <Tag name={`${document.resultCode}`} bg="secondary" />
            </>
          )}
        </td>
      </tr>
      {isExpanded && <AuditTableDetails document={document} onFilter={onFilter} />}
    </>
  );
}

AuditTableRow.propTypes = {
  document: PropTypes.object.isRequired,
  onFilter: PropTypes.func,
};

export default AuditTableRow;
