import React from "react";
import PropTypes from "prop-types";

import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TicketHistory from "./TicketHistory";
import TripNotes from "../../components/TripNotes";

function Details({ tripData }) {
  var dateTimeOptions = {
    hour12: false,
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return (
    <>
      <div
        style={{
          padding: "1.25rem",
          borderBottom: "1px solid rgba(0,0,0,.125)",
        }}
      >
        <div className="d-flex">
          <div className="mr-4">
            <p className="m-0">Från</p>
            <h5>{tripData[0]?.origin}</h5>
          </div>
          <ArrowForwardIcon fontSize="large" className="mt-2 mx-3" />
          <div className="ml-4 mr-4">
            <p className="m-0">Till</p>
            <h5>{tripData[tripData.length - 1]?.destination}</h5>
          </div>
          <div className="ml-5">
            <p className="m-0">Byten</p>
            <h5>{tripData.length - 1}</h5>
          </div>
        </div>
      </div>
      {tripData.map((leg, index) => (
        <div
          key={index}
          style={{
            padding: "1.25rem",
            borderBottom: "1px solid rgba(0,0,0,.125)",
          }}
        >
          <div className="d-flex">
            <div className="">
              <p className="m-0">Tågnr</p>
              <h5>{leg.serviceNr}</h5>
              <p className="m-0">Operatör</p>
              <h5>{leg.operator}</h5>
            </div>
            <div className="ml-4 mr-4">
              <p className="m-0">Avgång</p>
              <h5>
                {new Date(leg.departureTime).toLocaleString(
                  "sv-SE",
                  dateTimeOptions
                )}
              </h5>
              <p className="m-0">Ankomst</p>
              <h5>
                {new Date(leg.arrivalTime).toLocaleString(
                  "sv-SE",
                  dateTimeOptions
                )}
              </h5>
            </div>
            <div className="ml-5">
              <p className="m-0">Från</p>
              <h5> {leg.origin}</h5>
              <p className="m-0">Till</p>
              <h5>{leg.destination}</h5>
            </div>
            <div className="ml-5">
              <p className="m-0">Tåginfo</p>
              <TripNotes tripNotes={leg.trainInfo} />
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
Details.propTypes = {
  tripData: PropTypes.array,
};

function TripDetails({ ticketId, tripData }) {
  return (
    <>
      <div className={"tab"}>
        <Tab.Container id="left-tabs-example" defaultActiveKey="details">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="details">Resedetaljer</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="history">Biljetthistorik</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content style={{ padding: "0px" }}>
            <Tab.Pane eventKey="details">
              <Details tripData={tripData} />
            </Tab.Pane>
            <Tab.Pane eventKey="history">
              <TicketHistory ticketId={ticketId} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
}
TripDetails.propTypes = {
  tripData: PropTypes.array,
  ticketId: PropTypes.string,
};
export default TripDetails;
