import React, {Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import {Container, Card} from "react-bootstrap";

import SearchFilter from "./SearchFilter";
import AuditTable from "./AuditTable";
import Api from "./auditApi";
import Pagination from "../../components/Pagination";
import PageSizeInput from "../../components/PageSizeInput";
import ErrorDialog from "../../components/ErrorDialog";
import { useMsal } from "@azure/msal-react";
/**
 * Audit log page.
 * @component
 */
function Page() {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const { query } = useParams();
  const [searchOptions, setSearchOptions] = useState({
    query,
    pageSize: 50,
    skip: 0,
  });
  const pageSizesOption = [50, 200, 1000];
  const [currentPage, setCurrentPage] = useState(1);
  const [searchResult, setSearchResult] = useState({});
  const [filter, setFilter] = useState(null);

  async function searchAsync(options) {
    setIsLoading(true);
    try {
      const response = await Api.queryAsync(instance, options);
      setSearchResult(response);
    } catch (err) {
      setError("Misslyckades att hämta händelseloggen.");
    } finally {
      setIsLoading(false);
    }
  }

  // create a search options object to send to API
  function searchWithOptionsAsync({ query, from, to, filters }) {
    let filterQuery = "";

    // write "and" to query if not the first condition in filter
    let and = () => (filterQuery.length > 0 ? " and " : "");

    if (from) {
      filterQuery += from;
    }

    if (to) {
      filterQuery += `${and()}${to}`;
    }

    if (filters) {
      for (let i = 0; i < filters.length; i++) {
        filterQuery += `${and()}${filters[i]}`;
      }
    }

    // this will trigger a render of the component and reevaluating useEffect
    setSearchOptions({ ...searchOptions, query: query, filter: filterQuery });
  }

  // search will be perfomed when search options changes.
  useEffect(() => {
    searchAsync(searchOptions);
  }, [searchOptions]);

  // a filter button in the table has been pressed
  function onFilter(filter) {
    setFilter(filter);
  }

  // create an order expression
  function onSorting(sortOrder) {
    let orderExpression = "";
    let delimitor = () => (orderExpression.length > 0 ? "," : "");
    for (const column in sortOrder) {
      if (sortOrder[column]) {
        orderExpression += `${delimitor()}${column} ${sortOrder[column]}`;
      }
    }
    setSearchOptions({ ...searchOptions, orderBy: orderExpression });
  }

  // page size has been changed
  function onChangePageSize(pageSize) {
    // recalculate current page based on new pageSize
    setCurrentPage(1);
    setSearchOptions({ ...searchOptions, pageSize });
  }

  // page has been changed
  function onChangeCurrentPage(newCurrentPage) {
    setCurrentPage(newCurrentPage);
    setSearchOptions({
      ...searchOptions,
      skip: (newCurrentPage - 1) * searchOptions.pageSize,
    });
  }

  // remove the error from the page
  function clearError() {
    setError(null);
  }

  const displayPageSize = searchResult ? searchResult.totalCount > 50 : false;

  const displayPagination = searchResult
    ? searchResult.totalCount > searchOptions.pageSize
    : false;

  return (
    <Fragment>
      <Container fluid className="p-0">
      <h1 className="h3 mb-3">Händelselogg</h1>
      <Card>
      <Card.Header>
      {error && (
        <ErrorDialog title="Ett fel inträffade" onClose={clearError}>
          <p>{error}</p>
        </ErrorDialog>
      )}
      <SearchFilter
        facets={searchResult.facets}
        onChange={searchWithOptionsAsync}
        filter={filter}
      />
      </Card.Header>
      <div className="d-flex justify-content-between align-card-content">
        {displayPageSize && (
          <PageSizeInput
            currentPageSize={searchOptions.pageSize}
            onChangePageSize={onChangePageSize}
            pageSizesOption={pageSizesOption}
            currentPage={currentPage}
            totalSize={searchResult.totalCount}
            className="w-auto m-2"
          />
        )}
        {displayPagination && (
          <Pagination
            pageSize={searchOptions.pageSize}
            totalSize={searchResult.totalCount}
            currentPage={currentPage}
            onChangeCurrentPage={onChangeCurrentPage}
            className="w-auto"
          />
        )}
      </div>
      <AuditTable
        isLoading={isLoading}
        documents={searchResult.documents}
        onFilter={onFilter}
        onSorting={onSorting}
      />

        <div className="d-flex justify-content-between align-card-content mt-3">
          {displayPageSize && (
            <PageSizeInput
              currentPageSize={searchOptions.pageSize}
              onChangePageSize={onChangePageSize}
              pageSizesOption={pageSizesOption}
              currentPage={currentPage}
              totalSize={searchResult.totalCount}
              className="w-auto mb-2 mt-2"
            />
          )}
          {displayPagination && (
            <Pagination
              pageSize={searchOptions.pageSize}
              totalSize={searchResult.totalCount}
              currentPage={currentPage}
              onChangeCurrentPage={onChangeCurrentPage}
              className="w-auto"
            />
          )}
        </div>
      </Card>
      </Container>
    </Fragment>
  );
}

export default Page;
