import React from "react";
import PropTypes from "prop-types";

import { Table } from "react-bootstrap";

import FilterButton from "./FilterButton";
import { toTitleCase } from "../../lib/utils";

/**
 * Message that is associated with the audit log.
 * @param {string} message - The message for display.
 */
function Message({ message }) {
  return (
    <Table bordered className="text-sm">
      <thead className="bg-primary text-white">
        <tr>
          <th>Meddelande</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{message}</td>
        </tr>
      </tbody>
    </Table>
  );
}

Message.propTypes = {
  message: PropTypes.string.isRequired,
};

/**
 * @typedef Filter
 * @param {string} property - Name of the property to be filtered.
 * @param {string} operator - The operator used for filtering, could be eq, ne, lt, gt
 * @param {string} value - Value of the filter.
 */

/**
 * @callback OnFilterCallback
 * @param {Filter} filter
 */

/**
 * @typedef CustomProperty
 * @param {string} name -  Friendly name of the property.
 * @param {string} key - The property identifier.
 * @param {string} value - Value of the property.
 */

/**
 * Display custom properties in a table for the given title.
 * @param {string} title - Title of the table, 'Role', 'Target', 'Operation'
 * @param {CustomProperty[]} properties - A list of properties.
 * @param {OnFilterCallback} onFilter - Event that adds filter for a specific property
 * @returns
 */
function PropertyTable({ title, properties, onFilter }) {
  return (
    <Table bordered className="text-sm w-25">
      <thead className="bg-primary text-white">
        <tr>
          <th>{title}</th>
        </tr>
      </thead>
      <tbody>
        {properties.map((property) => (
          <tr key={property.key}>
            <td>
              <strong className="text-md">{property.name} </strong>
              <FilterButton
                property={property.key}
                value={property.value}
                className="float-right"
                onClick={onFilter}
              />
              <span className="d-block">{property.value}</span>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

PropertyTable.propTypes = {
  title: PropTypes.string.isRequired,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  onFilter: PropTypes.func,
};

/**
 * When you click on a table row it is expanded to display all extended information like custom properties.
 *
 * @component AuditTableDetails
 * @param {EventDocument} document - The document that we want to render extended information for.
 * @param {OnFilterCallback} onFilter - Called when a filter button is pushed.
 */
function AuditTableDetails({ document, onFilter }) {
  // make a nicley formatted property name
  function formatPropertyName(prefix) {
    return (property) => ({
      ...property,
      name: property.key.replace(prefix, ""),
    });
  }

  // event properties are those with no dot '.'
  const eventProperties = document.properties
    .filter((p) => !p.key.includes("."))
    .map(formatPropertyName(""))
    .append({
      name: "Vad",
      key: "TargetName",
      value: document.targetName,
    })
    .append({
      name: "Gjorde",
      key: "OperationName",
      value: document.operationName,
    })
    .append({
      name: "Vem",
      key: "RoleName",
      value: document.roleName,
    })
    .append({
      name: "Resultatkod",
      key: "ResultCode",
      value: `${document.resultCode}`,
    })
    .append({
      name: "Lyckad",
      key: "Success",
      value: `${document.success}`,
    })
    .append({
      name: "Kategori",
      key: "Category",
      value: document.category,
    });

  // role properties are those starting with Role.
  const roleProperties = document.properties
    .filter((p) => p.key.startsWith("Role."))
    .map(formatPropertyName("Role."))
    .append({
      name: toTitleCase(document.roleName),
      key: "RoleId",
      value: document.roleId,
    });

  // target properties are those starting with Target.
  const targetProperties = document.properties
    .filter((p) => p.key.startsWith("Target."))
    .map(formatPropertyName("Target."))
    .append({
      name: toTitleCase(document.targetName),
      key: "TargetId",
      value: document.targetId,
    });

  // operation properties are those starting with Operation
  const operationProperties = document.properties
    .filter((p) => p.key.startsWith("Operation."))
    .map(formatPropertyName("Operation."))
    .append({
      name: toTitleCase(document.operationName),
      key: "OperationId",
      value: document.operationId,
    });

  return (
    <tr className="border-0">
      <td colSpan="7" className="px-0">
        {document.message && <Message message={document.message} />}
        <div className="d-flex flex-row gap-3 align-items-start">
          <PropertyTable
            title="Händelse"
            properties={eventProperties}
            onFilter={onFilter}
          />
          <PropertyTable
            title="Vem"
            properties={roleProperties}
            onFilter={onFilter}
          />
          <PropertyTable
            title="Gjorde"
            properties={operationProperties}
            onFilter={onFilter}
          />
          <PropertyTable
            title="Vad"
            properties={targetProperties}
            onFilter={onFilter}
          />
        </div>
      </td>
    </tr>
  );
}

AuditTableDetails.propTypes = {
  document: PropTypes.object.isRequired,
  onFilter: PropTypes.func,
};

export default AuditTableDetails;
