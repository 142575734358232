import React, { useState } from "react";
import PropTypes from "prop-types";

import UtilityButton from "../../components/UtilityButton";
import Spinner from "../../components/Spinner";
import api from "./usersApi";
import { useMsal } from "@azure/msal-react";
/**
 * Will toggle user access to a specific permission as you press it.
 */
function UserAccessButton({
  user,
  permission,
  children,
  onSuccess,
  onFailure,
  isDisabled,
}) {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState();

  async function togglePermissions() {
    let userPermissions = user.permissions.memberOf.map(
      (permission) => permission.id
    );

    if (userPermissions.includes(permission.id)) {
      // remove the permission
      const index = userPermissions.indexOf(permission.id);
      userPermissions.splice(index, 1);
    } else {
      // add the permission
      userPermissions.push(permission.id);
    }

    try {
      setIsLoading(true);
      const updatedUser = await api.updateUserGroupsAsync(
        instance,
        user.id,
        userPermissions
      );
      // report success
      onSuccess && onSuccess(updatedUser);
    } catch (error) {
      // report failure
      onFailure && onFailure(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <UtilityButton
      title="Uppdatera behörigheter"
      isDisabled={!user.isActive || isLoading || isDisabled}
      onClick={togglePermissions}
    >
      {isLoading ? <Spinner /> : children}
    </UtilityButton>
  );
}

const memberOfPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  displayName: PropTypes.string,
});

UserAccessButton.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    permissions: PropTypes.shape({
      memberOf: PropTypes.arrayOf(memberOfPropTypes),
    }),
    isActive: PropTypes.bool.isRequired,
  }),
  permission: memberOfPropTypes,
  children: PropTypes.element.isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default UserAccessButton;
