import React, { useState } from "react";
import PropTypes from "prop-types";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import UtilityButton from "../../components/UtilityButton";
import Spinner from "../../components/Spinner";
import Api from "./usersApi";
import { useMsal } from "@azure/msal-react";
/**
 * Callback for successful deletion.
 *
 * @callback onSuccessCallback
 * @param {object} updatedUser - The user after it has been updated on the api.
 */

/**
 * Callback for failed deletion.
 *
 * @callback onFailureCallback
 * @param {string} errorMessage - A message describing what went wrong.
 */

/**
 * Shows a delete button icon, that will delete the user when clicked.
 *
 * @param {string} id - Unique identifier for a user.
 * @param {boolean} isEnabled - Indicates if the user is enabled or disabled.
 * @param {onSuccessCallback} onSuccess - Is called when delete was successful.
 * @param {onFailureCallback} onFailure - Is called when failed to delete the user.
 * @param {boolean} isDisabled - The button should be disabled.
 */
function ActionButton({ id, isEnabled, onSuccess, onFailure, isDisabled }) {
  // this flag controls if the icon or the spinner should be visible
  const [isLoading, setIsLoading] = useState(false);
  const { instance } = useMsal();
  // on successful deletion
  function actionSuccess(updatedUser) {
    setIsLoading(false);
    onSuccess && onSuccess(updatedUser);
  }

  // when deletion fails
  function actionFailure() {
    setIsLoading(false);
    onFailure && onFailure("Misslyckades aktivera/inaktivera användare");
  }

  function callDisable(id, ev) {
    setIsLoading(true);
    Api.disableAsync(instance, id).then(actionSuccess).catch(actionFailure);

    // do not propagate the click to the table row
    ev.stopPropagation();
  }

  function callEnable(id, ev) {
    setIsLoading(true);
    Api.enableAsync(instance, id).then(actionSuccess).catch(actionFailure);

    // do not propagate the click to the table row
    ev.stopPropagation();
  }

  function DisableButton() {
    const colorClassName = isDisabled ? "text-muted" : "text-danger";

    return (
      <UtilityButton
        title="Inaktivera"
        onClick={(ev) => callDisable(id, ev)}
        data-testid="disable-button"
        isDisabled={isDisabled}
      >
        {isLoading ? <Spinner /> : <DeleteIcon className={colorClassName} />}
      </UtilityButton>
    );
  }

  function EnableButton() {
    const colorClassName = isDisabled ? "text-muted" : "text-success";

    return (
      <UtilityButton
        title="Aktivera"
        onClick={(ev) => callEnable(id, ev)}
        data-testid="enable-button"
        isDisabled={isDisabled}
      >
        {isLoading ? <Spinner /> : <AddIcon className={colorClassName} />}
      </UtilityButton>
    );
  }

  return isEnabled ? <DisableButton /> : <EnableButton />;
}

ActionButton.propTypes = {
  id: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default ActionButton;
