import React from "react";
import PropTypes from "prop-types";

import BootstrapPagination from "react-bootstrap/Pagination";

// generate a range of numbers
const range = function range(start, end) {
  const length = end - start;
  return Array.from({ length }, (_, i) => start + i);
};

/**
 * @callback OnChangeCurrentPageCallback
 * @param {number} newCurrentPage - The page we want to navigate to.
 */

/**
 * Display controls for page size and navigating between pages.
 *
 * @param {number} pageSize - Number of items displayed on each page.
 * @param {number} totalSize - Total number of items.
 * @param {number} currentPage - The page that is currently displayed.
 * @param {OnChangeCurrentPageCallback} onChangeCurrentPage - Triggered when user navigate to different page.
 * @param {number} windowSize - The size of the area around the current page in the navigation. Number must be odd.
 * @param {string} className - Custom css styling.
 */
function Pagination({
  pageSize,
  totalSize,
  currentPage,
  onChangeCurrentPage,
  windowSize = 9,
  className,
}) {
  if (windowSize % 2 === 0) {
    throw new Error(`windowSize should be an odd number but was ${windowSize}`);
  }

  // the page window is how large area around the current page that should
  // be displayed. Current page might move around the window if the window
  // is rendered at the beginning or end of the list of pages
  //
  // Please keep it an odd number so currentPage can be in the middle
  const padding = (windowSize - 1) / 2;

  // calculate how many pages there are
  const totalPages = Math.ceil(totalSize / pageSize);

  // sanity check
  if (currentPage < 1 || currentPage > totalPages) {
    throw new Error(
      `Pagination: currentPage must be within range 1 <= x <= ${totalPages} but was ${currentPage}`
    );
  }

  // we want to stay consistent in the size of the page window
  // so there are things to consider regarding the start index
  // 1. Never lower than 2
  // 2. If window is in middle of sequence, then currentPage - PADDING
  // 3. If window is at end of sequence, then totalPages - WINDOW
  const pageWindowStart = Math.max(
    2,
    currentPage + padding < totalPages
      ? currentPage - padding
      : totalPages - windowSize + 1
  );

  // when there is a gap between first page and the first
  // page in the window that we show in the pagination
  // only if number of pages is more than window size
  const showStartEllipsis =
    totalPages > windowSize + 1 && currentPage - padding > 2;

  // there are several things to consider figuring out how far the page window goes
  // 1. Never further than the last page
  // 2. If window in the middle of sequence, then currentPage + PADDING
  // 3. If window at start of sequence, then WINDOW
  const pageWindowEnd = Math.min(
    totalPages,
    currentPage - padding > 1 ? currentPage + padding + 1 : windowSize + 1
  );

  // we will display the end ellipsis if there is a space between
  // the pageWindow end and the last page
  // but not if number of pages is less than window size
  const showEndEllipsis =
    totalPages > windowSize + 1 && currentPage + padding < totalPages - 1;

  // generate the page numbers close to current page
  const pageWindow = range(pageWindowStart, pageWindowEnd);

  function changeCurrentPage(newCurrentPage) {
    return () => onChangeCurrentPage && onChangeCurrentPage(newCurrentPage);
  }

  return (
    <BootstrapPagination className={className}>
      <BootstrapPagination.Prev
        disabled={currentPage === 1}
        onClick={changeCurrentPage(currentPage - 1)}
      />
      <BootstrapPagination.Item
        active={currentPage === 1}
        onClick={changeCurrentPage(1)}
      >
        1
      </BootstrapPagination.Item>
      {showStartEllipsis && <BootstrapPagination.Ellipsis disabled={true} />}
      {pageWindow.map((pageNumber) => (
        <BootstrapPagination.Item
          key={pageNumber}
          active={currentPage === pageNumber}
          onClick={changeCurrentPage(pageNumber)}
        >
          {pageNumber}
        </BootstrapPagination.Item>
      ))}
      {showEndEllipsis && <BootstrapPagination.Ellipsis disabled={true} />}
      <BootstrapPagination.Item
        active={currentPage === totalPages}
        onClick={changeCurrentPage(totalPages)}
      >
        {totalPages}
      </BootstrapPagination.Item>
      <BootstrapPagination.Next
        disabled={currentPage === totalPages}
        onClick={changeCurrentPage(currentPage + 1)}
      />
    </BootstrapPagination>
  );
}

Pagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onChangeCurrentPage: PropTypes.func,
  windowSize: PropTypes.number,
  className: PropTypes.string,
};

export default Pagination;
