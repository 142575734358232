import React, { Fragment } from "react";

function Page() {
    return (
        <Fragment>
                <div className="container-fluid p-0">
                    <h1 className="h3 mb-3">Change log</h1>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">08 Mars 2022</h5>
                                </div>
                                <div className="card-body">
                                    <h2 className="h1 mb-4"><span className="badge bg-primary">v1.10.2</span> Buggfixar</h2>
                                    <h3>Buggfixar</h3>
                                    <ul>
                                        <li>
                                            <i><b>Generellt | </b></i>Hovrings inforuta/tool tip &quot;Uppdatera behörighet&quot; eller tooltip, fastnar inte längre. 
                                        </li>
                                        <li>
                                            <i><b>Trafikinformation | </b></i>Användare kunde inte skriva andra bokstäver än det som passade MDTs stationer, nu kan man det
                                        </li>
                                    </ul>
                                    <h3>Kända buggar</h3>
                                    <ul>
                                        <li>
                                            <i><b>Biljettstatus | </b></i>När en biljett är ombokad får den status &quot;Spärrad&quot;
                                        </li>
                                        <li>
                                            <i><b>Pdf | </b></i>När en kund bokar in flera tåg med mycket text, ser informationen på pdf:en inte helt korrekt ut. Footern blir överskriven
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">25 Januari 2022</h5>
                                </div>
                                <div className="card-body">
                                    <h2 className="h1 mb-4"><span className="badge bg-primary">v1.10.1</span> Uppdaterat design och bättre tidsintervaller i bokningar</h2>
                                    <h3>Nya</h3>
                                    <ul>
                                        <li>
                                            <i><b>Generellt | </b></i>BackOffice har fått en uppdaterad, finslipad design.
                                        </li>
                                        <li>
                                            <i><b>Bokningar | </b></i>Användare kan nu välja fördefinerade tidsintervall som även visas i datumfälten.
                                        </li>
                                        <li>
                                            <i><b>Bokningar | </b></i>Default-värde, två dagar bakåt från nutid - ändrad till 7 dagar
                                        </li>
                                    </ul>
                                    <h3>Buggfixar</h3>
                                    <ul>
                                        <li>
                                            <i><b>Bokningar | </b></i>Uppdaterad användarupplevelse vid biljettdetaljer, användaren hamnar i samma-läge vid tillbaka steg. 
                                        </li>
                                        <li>
                                            <i><b>Biljettsida | </b></i> Biljettdetaljer är nu förtydligat när &quot;Movingo gäller&quot;
                                        </li>
                                        <li>
                                            <i><b>Användarhantering | </b></i>Uppdateraring av behörigheter & inaktivering av egen (admin roll) är numera ej möjligt. 
                                        </li>
                                        <li>
                                            <i><b>Användarhantering | </b></i>Kundtjänst ska kunna se fler än 100 användare
                                        </li>
                                        <li>
                                            <i><b>Trafikinformation | </b></i>Ny version för sökfältet där användaren får resultat redan efter två bokstäver skrivs in i sökfältet
                                        </li>
                                        <li>
                                            <i><b>Händelseloggen | </b></i>Händelseloggen har fått en mer konsekvent och korrekt tidstämpel 
                                        </li>
                                        <li>
                                            <i><b>Backend</b></i> (relaterad till Pdf tjänsten, redan produktionssatt)
                                        </li>
                                        <li>
                                            <i><b>Startsida | </b></i>Reseplaneraren: Kundtjänst kan nu se rätt avgångstider för specikt sök
                                        </li>
                                        <ul>
                                            <li>
                                                <i><b>Pdf | </b></i>Renskrivning av ord/ begrepp –så som: Admin -Biljettnummer, biljettnr
                                            </li>
                                            <li>
                                                <i><b>Pdf | </b></i>Om en kund har köpt en resa som inkluderar fler än ett tåg, finns det detaljer om tågnummer på alla de tåg som gäller för hela resan
                                            </li>
                                            <li>
                                                <i><b>Pdf | </b></i>Rätt moms på biljetter
                                            </li>
                                            <li>
                                                <i><b>Pdf | </b></i>Allmän uppdatering av kvittomall 
                                            </li>
                                        </ul>
                                    </ul>
                                    <h3>Kända buggar</h3>
                                    <ul>
                                        <li>
                                            <i><b>Generellt | </b></i>Vid svep över olika taggar i backoffice hänger tooltips kvar när användaren flyttar på musen.
                                        </li>
                                        <li>
                                            <i><b>Biljettstatus | </b></i>När en biljett är ombokad får den status &quot;Spärrad&quot;
                                        </li>
                                        <li>
                                            <i><b>Pdf | </b></i>När en kund bokar in flera tåg med mycket text, ser informationen på pdf:en inte helt korrekt ut. Footern blir överskriven
                                        </li>
                                       
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </Fragment>
    );
}

export default Page;
