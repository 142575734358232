import api from '../lib/api';

const baseUrl = '/api/v1';

/**
 * Get information of ticket given the correlationId
 */
async function getTicketDataAsync(msalInstance, ticketId) {
  const accessToken = await api.getAccessTokenAsync(msalInstance);
  const response = await fetch(`${baseUrl}/transactions/?Query=${ticketId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
  var result = await response.json();

  if (api.isSuccessful(response)) {
    return result;
  } else {
    throw new Error(`Failed to fetch bookings data}`);
  }
}
/**
 * Get information for legs of specific trip
 */
async function getTicketTripDataAsync(msalInstance, ticketId) {
  const accessToken = await api.getAccessTokenAsync(msalInstance);
  const response = await fetch(`${baseUrl}/ticket/${ticketId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
  var result = await response.json();

  if (api.isSuccessful(response)) {
    return result;
  } else {
    throw new Error(`Failed to fetch ticket data}`);
  }
}

/**
 * Refund ticket
 */
async function refundTicketAsync(msalInstance, request) {
  const accessToken = await api.getAccessTokenAsync(msalInstance);
  // call the API to refund ticket price to the user
  const response = await fetch(`${baseUrl}/ticket/refund`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(request),
  });

  if (api.isSuccessful(response)) {
    return await response.json();
  } else {
    throw new Error(`Failed to refund ticket with: ${request.correlationId} id.`);
  }
}

/**
 * Send receipt
 */
async function sendReceiptAsync(msalInstance, mail, correlationId) {
  const accessToken = await api.getAccessTokenAsync(msalInstance);
  // call the API to send a receipt to the customer
  const request = { email: mail, transactionId: correlationId };
  const response = await fetch(`${baseUrl}/ticket/send/receipt/email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(request),
  });

  if (api.isSuccessful(response)) {
    return 'Kvitto skickat till mailkö! Det kan ta en stund innan kunden får sitt mail.';
  } else {
    throw new Error(`Failed to send receipt.`);
  }
}
/**
 * Send ticket
 */
async function sendTicketAsync(msalInstance, mail, correlationId) {
  const accessToken = await api.getAccessTokenAsync(msalInstance);
  const request = { email: mail, transactionId: correlationId };
  // call the API to refund ticket price to the user
  const response = await fetch(`${baseUrl}/ticket/send/ticket`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(request),
  });
  if (api.isSuccessful(response)) {
    return 'Biljett skickad till mailkö! Det kan ta en stund innan kunden får sitt mail.';
  } else {
    throw new Error(`Failed to send ticket.`);
  }
}

/**
 * Blacklist ticket
 */
async function blacklistTicketAsync(msalInstnace, ticketData, tripData, blacklistReason) {
  const accessToken = await api.getAccessTokenAsync(msalInstnace);
  const activeUser = await api.getActiveAccount(msalInstnace);
  try {
    const request = {
      BlacklistEntryId: 0,
      TicketId: ticketData.tickets[0]?.id,
      TravellerId: '',
      TokenId: '',
      Expire: tripData[tripData.length - 1].arrivalTime,
      UserId: activeUser.localAccountId,
      Reason: blacklistReason,
    };
    const response = await fetch(`${baseUrl}/ticket/blacklist`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(request),
    });
    if (api.isSuccessful(response)) {
      return 'Biljett spärrad!';
    } else {
      throw new Error(`Failed to blacklist ticket.`);
    }
  } catch (error) {
    throw new Error(`Failed to blacklist ticket.`);
  }
}

/**
 * Rebook ticket
 */
async function rebookTicketAsync(msalInstnace, productIds, ticketid, rebookReason, customerId) {
  const accessToken = await api.getAccessTokenAsync(msalInstnace);
  const activeUser = await api.getActiveAccount(msalInstnace);

  const request = {
    ProductIds: productIds,
    TicketId: ticketid,
    CustomerId: customerId,
    UserId: activeUser.localAccountId,
    Reason: rebookReason,
  };
  const response = await fetch(`${baseUrl}/ticket/rebook`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(request),
  });
  if (api.isSuccessful(response)) {
    const resp = await response.json();
    return resp;
  } else {
    throw new Error(`Failed to rebook ticket.`);
  }
}

export default {
  getTicketDataAsync,
  getTicketTripDataAsync,
  refundTicketAsync,
  sendReceiptAsync,
  sendTicketAsync,
  blacklistTicketAsync,
  rebookTicketAsync,
};
