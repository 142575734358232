import React from "react";
import PropTypes from "prop-types";

import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

/**
 * An action button we use on each row of the ticket matrix table.
 *
 * @param {string} title - Tooltip that shows when you hover the button.
 * @param {Component} children - Content rendered inside the button.
 * @param {Event} onClick - Triggered when someone clicks the button.
 * @param {boolean} isDisabled - True if the button should raise onClick event when clicked.
 * @param {string} className - Attach these class names to the button.
 */
function UtilityButton({
  title,
  children,
  onClick,
  isDisabled = false,
  className,
  ...props
}) {
  function handleClick(ev) {
    ev.stopPropagation();
    onClick && onClick(ev);
  }
  var component = (
    <button
      type="button"
      className={`bg-transparent border-0 ${isDisabled ? "text-muted" : "text-dark"
        } ${className ? className : ""}`}
      onClick={handleClick}
      disabled={isDisabled}
      title={isDisabled ? "" : title}
      {...props}
    >
      {children}
    </button>
  );
  if (isDisabled) {
    return component;
  }
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="utility-button-tooltip">{title}</Tooltip>}
      trigger="hover"
    >
      {component}
    </OverlayTrigger>
  );
}

UtilityButton.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};

export default UtilityButton;
