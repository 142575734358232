import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";

import RescheduleModal from "./RescheduleModal";

function RescheduleButton({ ticketData, tripData, ...props }) {
  const [isVisible, setIsVisible] = useState(false);
  const history = useHistory();

  //const isDisabled = new Date(ticketData.validity) <= new Date();

  function openRescheduleModal() {
    setIsVisible(true);
  }

  function hideModal() {
    setIsVisible(false);
  }

  function handleSuccess(reason) {
    hideModal();

    history.push(`/bookings/ticket/${ticketData.correlationId}/reschedule`, {
      ticketData,
      tripData,
      reason: reason || "test reason",
    });
  }

  return (
    <>
      <div className="ml-2">
        <Button {...props} onClick={openRescheduleModal} disabled={true}>
          <span className="material-icons">cached</span>
          <br />
          Ombokning
        </Button>
      </div>
      {isVisible && (
        <RescheduleModal
          show={isVisible}
          onHide={hideModal}
          onSuccess={handleSuccess}
          ticketData={ticketData}
          tripData={tripData}
        />
      )}
    </>
  );
}

RescheduleButton.propTypes = {
  ticketData: PropTypes.object.isRequired,
  tripData: PropTypes.array.isRequired,
};

export default RescheduleButton;
