import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import UploadIcon from "@mui/icons-material/Publish";
import ProgressBar from "react-bootstrap/ProgressBar";

import Api from "./matrixApi";
import { useMsal } from "@azure/msal-react";

/**
 * Callback for successful delete of file.
 *
 * @callback onSuccessCallback
 */

/**
 * Callback for failed deletion of file.
 *
 * @callback onFailureCallback
 * @param {string} errorMessage - A message describing what went wrong.
 */

/**
 * A button that triggers a Select File dialog and uploads the file.
 *
 * @param {onSuccessCallback} onSuccess - Called when upload was successful.
 * @param {onFailureCallback} onFailure - Called when upload failed.
 */
function UploadButton({ onSuccess, onFailure }) {
  const { instance } = useMsal();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef();

  // triggers the browser select file dialog
  function selectFile() {
    fileInputRef.current.click();
  }

  async function uploadFileAsync(ev) {
    // reset state
    setUploadProgress(0);
    setIsUploading(true);
    const file = ev.target.files[0];
    const { name } = Api.getActiveAccount(instance);

    try {
      await Api.uploadFileAsync(instance, file, name, setUploadProgress);
      setIsUploading(false);
      if (onSuccess) {
        onSuccess();
      }
    } catch (errorMessage) {
      setIsUploading(false);
      if (onFailure) {
        onFailure(errorMessage);
      }
    }
  }

  function visibilityClassName(isVisible) {
    return isVisible ? "visible" : "invisible";
  }

  return (
    <>
      <input
        type="file"
        name="file"
        hidden="hidden"
        accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={uploadFileAsync}
        ref={fileInputRef}
      />
      <ProgressBar
        striped
        variant="info"
        now={uploadProgress}
        className={`my-2 ${visibilityClassName(isUploading)} w-100`}
      />
      <button
        type="button"
        className="btn btn-primary small p-1 px-2 float-end mt-n1"
        onClick={selectFile}
      >
        <UploadIcon className="align-middle mr-2" data-feather="upload" />
        Ladda upp ny biljettmatris
      </button>
    </>
  );
}

UploadButton.propTypes = {
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
};

export default UploadButton;
