import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Table } from "react-bootstrap";

import Api from "../audit/auditApi";
import Spinner from "../../components/Spinner";
import AuditQueryLink from "../../components/AuditQueryLink";
import { dateFormat, toTitleCase } from "../../lib/utils";
import { useMsal } from "@azure/msal-react";
/**
 * Render the table rows of the ticket history log.
 *
 * @param {EventDocument} document - An event from the audit log.
 * @returns
 */
function TicketHistoryTableRow({ document }) {
  // map custom properties into a Map that is easier to work with
  const properties = new Map();
  for (let i = 0; i < document.properties.length; i++) {
    properties.set(document.properties[i].key, document.properties[i].value);
  }

  // customize the target message
  function getTargetMessage(document) {
    let message = "";

    // concerning a transaction
    if (document.targetName === "transaction") {
      // output the transaction price
      message += `${properties.get("Target.Amount")} ${properties.get(
        "Target.Currency"
      )}`;

      // if it was paid, output transaction id
      if (document.operationName === "paid") {
        message += `, payment reference: ${properties.get(
          "Target.PspGatewayId"
        )}`;
      }

      // output the status of the transaction
      if (
        document.operationName === "paid" ||
        document.operationName === "created"
      ) {
        message += `, status: ${properties.get("Target.Status")}`;
      }
    } else if (document.targetName === "refund") {
      // if a refund was created, output the reason
      message += `reason: ${properties.get("Target.Reason")}`;
    }

    // fallback
    if (!message && document.message) {
      return document.message;
    }

    return message;
  }
  const catClassName =
    document.category === "rebook" ? "rebooked-highlight" : "";
  return (
    <tr>
      <td>{dateFormat(document.timeStamp)}</td>
      <td className={catClassName}>{`${toTitleCase(
        document.operationName
      )} ${toTitleCase(document.targetName)}`}</td>
      <td>{`${toTitleCase(document.roleName)} ${document.roleId}`}</td>
      <td colSpan={2}>{getTargetMessage(document)}</td>
    </tr>
  );
}

TicketHistoryTableRow.propTypes = {
  document: PropTypes.object.isRequired,
};

/**
 * Display a table of the ticket history.
 *
 * @component
 * @param {string} ticketId - Used to display the audit event link.
 * @param {SearchResult} searchResult - The search result set from the audit api.
 */
function TicketHistoryTable({ ticketId, searchResult = {} }) {
  return (
    <Table className="mb-0" id="TicketHistoryTable">
      <thead>
        <tr>
          <th>Tidpunkt</th>
          <th>Händelse</th>
          <th>Användare</th>
          <th>Övrigt</th>
          <th className="text-right">
            <AuditQueryLink query={ticketId} />
          </th>
        </tr>
      </thead>
      <tbody>
        {searchResult.documents &&
          searchResult.documents.map((document) => (
            <TicketHistoryTableRow key={document.id} document={document} />
          ))}
      </tbody>
    </Table>
  );
}

TicketHistoryTable.propTypes = {
  ticketId: PropTypes.string.isRequired,
  searchResult: PropTypes.object,
};

/**
 * Display the ticket history by searching for the ticket id in the audit log.
 *
 * @component
 * @param {string} ticketId - The id of the ticket to search for in the audit log.
 * @param {OnFailureCallback} - Callback if this component fails to load the audit log.
 */
function TicketHistory({ ticketId, onFailure }) {
  const { instance } = useMsal();
  const [searchResult, setSearchResult] = useState();
  const [isLoading, setIsLoading] = useState(true);

  async function queryAuditEventsAsync(ticketId) {
    try {
      setIsLoading(true);
      const searchOptions = {
        query: ticketId,
        // default order on TimeStamp ascending
        orderBy: "TimeStamp desc",
      };

      const result = await Api.queryAsync(instance, searchOptions);
      setSearchResult(result);
    } catch (err) {
      onFailure && onFailure(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    // perform search on component mount
    queryAuditEventsAsync(ticketId);
  }, [ticketId]);

  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <TicketHistoryTable ticketId={ticketId} searchResult={searchResult} />
      )}
    </div>
  );
}

TicketHistory.propTypes = {
  ticketId: PropTypes.string.isRequired,
  onFailure: PropTypes.func,
};

export default TicketHistory;
