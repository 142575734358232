import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Container, Card } from 'react-bootstrap';

import ErrorDialog from '../../components/ErrorDialog';

import SearchBookingsBar from './SearchBookingsBar';
import BookingsMatrix from './BookingsMatrix';
import Pagination from '../../components/Pagination';
import PageSizeInput from '../../components/PageSizeInput';
import { useMsal } from '@azure/msal-react';
import Api from './bookingsApi';

const useStickyState = (key = 'sticky', initialState = null) => {
  const [state, setState] = useState(() => {
    const storedState = sessionStorage.getItem(key);

    return storedState ?? initialState;
  });

  useEffect(() => {
    sessionStorage.setItem(key, state);
  }, [key, state]);

  const clearState = () => sessionStorage.removeItem(key);

  return [state, setState, clearState];
};

const useStickyStateDate = (key = 'stickydate', initialState = null) => {
  const [state, setState] = useState(() => {
    const storedState = sessionStorage.getItem(key);

    return storedState != null ? new Date(storedState) : initialState;
  });

  useEffect(() => {
    sessionStorage.setItem(key, state.toString());
  }, [key, state]);

  const clearState = () => sessionStorage.removeItem(key);

  return [state, setState, clearState];
};

/**
 * This is the Bookings page.
 */
const emptyInformation = {
  values: [],
  total: null,
};
function Page() {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { query } = useParams();
  const [ticketData, setTicketData] = useState(emptyInformation);
  const [searchQuery, setSearchQuery] = useStickyState('searchQuery', '');
  const [searchOptions, setSearchOptions] = useState({
    query,
    pageSize: 25,
    skip: 0,
  });
  const [filterListTicketType, setFilterListTicketType] = useState('');
  const [filterListStatus, setFilterListStatus] = useState('PaymentSucceeded');
  const [filterListPayment, setFilterListPayment] = useState('');
  const [filterListChannel, setFilterListChannel] = useState('');

  const [sortField, setSortField] = useState('timestamp');
  const [sortOrder, setSortOrder] = useState('desc');
  const [fromTime, setFromTime] = useStickyStateDate(
    'fromTime',
    new Date(new Date().setDate(new Date().getDate() - 2)),
  );
  const [toTime, setToTime] = useStickyStateDate('toTime', new Date());
  const [timeSpanOption, setTimeSpanOption] = useStickyState('timeSpan', 2);
  const [dateRangeUpdated, setDateRangeUpdated] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSizesOption = [25, 50, 100];

  function clearError() {
    setError(null);
  }

  async function generateQuery() {
    try {
      searchOptions.query = searchQuery + '*';
      searchOptions.filter =
        'From=' +
        fromTime.toISOString() +
        ';To=' +
        toTime.toISOString() +
        ';' +
        (filterListTicketType ? 'TicketTypeFilter=' + filterListTicketType + ';' : '') +
        (filterListPayment ? 'PaymentFilter=' + filterListPayment + ';' : '') +
        (filterListStatus ? 'StatusFilter=' + filterListStatus + ';' : '') +
        (filterListChannel ? 'ChannelFilter=' + filterListChannel + ';' : '');
      searchOptions.Orderby = sortField + ' ' + sortOrder;
      searchOptions.IncludeTotal = true;
      searchOptions.Page = currentPage;

      setIsLoading(true);
      setTicketData(await Api.getBookingsDataAsync(instance, searchOptions));
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  }

  async function editQuery(value, type) {
    setCurrentPage(1);
    switch (type) {
      case 'from':
        setFromTime(value);
        setDateRangeUpdated(Math.random());
        break;
      case 'to':
        setToTime(value);
        setDateRangeUpdated(Math.random());
        break;
      case 'span':
        setFromTime(value[0]);
        setToTime(value[1]);
        setDateRangeUpdated(Math.random());
        break;
      case 'query':
        setSearchQuery(value);
        break;
    }
  }

  function updateDateSpan(param) {
    let dstart = new Date();
    let dend = new Date();
    switch (param.toString()) {
      case '2': {
        dstart.setDate(dstart.getDate() - 7);
        editQuery([dstart, dend], 'span');
        break;
      }
      case '3': {
        dstart.setHours(dstart.getHours() - 48);
        editQuery([dstart, dend], 'span');
        break;
      }
      case '4':
        dstart.setHours(0, 0, 0, 0);
        editQuery([dstart, dend], 'span');
        break;
      case '5':
        {
          dstart.setHours(0, 0, 0, 0);
          let currentWeekDay = dstart.getDay();
          let lessDays = currentWeekDay == 0 ? 6 : currentWeekDay - 1;
          editQuery([new Date(new Date(dstart).setDate(dstart.getDate() - lessDays)), dend], 'span');
        }
        break;
      case '6':
        editQuery([new Date(dstart.getFullYear(), dstart.getMonth(), 1), dend], 'span');
        break;

      default:
        break;
    }
  }

  function handleTableChange(type, value) {
    setCurrentPage(1);
    var list = [];
    switch (type) {
      case 'page':
        setCurrentPage(value != null ? value : currentPage);
        break;
      case 'pageSize':
        setSearchOptions(value != null ? value : searchOptions.pageSize);
        break;
      case 'sortOrder':
        setSortOrder(value != null ? value : sortOrder);
        break;
      case 'sortField':
        setSortField(value != null ? value : sortField);
        break;
      case 'queryStatus':
        if (value != null && value.length > 0) {
          value.forEach((query) => {
            list.push(query.value);
          });
        }
        var t = list.join(',');
        setFilterListStatus(t);
        break;
      case 'queryPayment':
        if (value != null && value.length > 0) {
          value.forEach((query) => {
            list.push(query.value);
          });
        }
        setFilterListPayment(list.join(','));
        break;
      case 'queryChannel':
        if (value != null && value.length > 0) {
          value.forEach((query) => {
            list.push(query.value);
          });
        }
        setFilterListChannel(list.join(','));
        break;
      case 'queryTicketType':
        if (value != null && value.length > 0) {
          value.forEach((query) => {
            list.push(query.value);
          });
        }
        setFilterListTicketType(list.join(','));
        break;
    }
  }
  // page size has been changed
  function onChangePageSize(pageSize) {
    // recalculate current page based on new pageSize
    setCurrentPage(1);
    setSearchOptions({ ...searchOptions, pageSize });
  }

  // page has been changed
  function onChangeCurrentPage(newCurrentPage) {
    setCurrentPage(newCurrentPage);
    setSearchOptions({
      ...searchOptions,
      skip: (newCurrentPage - 1) * searchOptions.pageSize,
    });
  }

  useEffect(() => {
    updateDateSpan(timeSpanOption);
  }, [timeSpanOption]);

  useEffect(() => {
    generateQuery();
  }, [
    dateRangeUpdated,
    searchQuery,
    currentPage,
    filterListTicketType,
    filterListStatus,
    filterListPayment,
    filterListChannel,
    searchOptions.pageSize,
    sortField,
    sortOrder,
    searchOptions,
  ]);

  const displayPageSize = true;
  const displayPagination = ticketData ? ticketData.total > searchOptions.pageSize : false;

  return (
    <React.Fragment>
      {/*
        <div className="loadingWrapper" data-title=".dot-flashing" >
          <div className="loadingContainer">
                <div className="dot-flashing"></div>
          </div>
        </div>*/}
      <Container fluid className="p-0">
        <div className="d-flex align-items-center">
          <h1 className="h3 mb-3">Bokningar</h1>
        </div>
        <Card>
          <Card.Header>
            <SearchBookingsBar
              editQuery={(e, type) => editQuery(e, type)}
              searchQuery={searchQuery}
              fromTime={fromTime}
              toTime={toTime}
              setTimeSpanOption={setTimeSpanOption}
              editDateSpan={updateDateSpan}
              timeSpanOption={timeSpanOption.toString()}
              isLoading={isLoading}
            />
          </Card.Header>
          {error && <ErrorDialog title="Hoppsan ett fel inträffade! Försök igen." onClose={clearError} />}
          <div className="d-flex justify-content-between align-card-content">
            {displayPageSize && (
              <PageSizeInput
                currentPageSize={searchOptions.pageSize}
                onChangePageSize={onChangePageSize}
                pageSizesOption={pageSizesOption}
                currentPage={currentPage}
                totalSize={ticketData.total}
                className="w-auto"
              />
            )}
            {displayPagination && (
              <Pagination
                pageSize={searchOptions.pageSize}
                totalSize={ticketData.total}
                currentPage={currentPage}
                onChangeCurrentPage={onChangeCurrentPage}
                className="w-auto"
              />
            )}
          </div>
          <BookingsMatrix
            data={ticketData.values}
            onChange={(type, value) => handleTableChange(type, value)}
            sortOrderCurrent={sortOrder}
          />
          <div className="d-flex justify-content-between align-card-content mt-3">
            {displayPageSize && (
              <PageSizeInput
                currentPageSize={searchOptions.pageSize}
                onChangePageSize={onChangePageSize}
                pageSizesOption={pageSizesOption}
                currentPage={currentPage}
                totalSize={ticketData.total}
                className="w-auto mb-2 mt-2"
              />
            )}
            {displayPagination && (
              <Pagination
                pageSize={searchOptions.pageSize}
                totalSize={ticketData.total}
                currentPage={currentPage}
                onChangeCurrentPage={onChangeCurrentPage}
                className="w-auto"
              />
            )}
          </div>
        </Card>
      </Container>
    </React.Fragment>
  );
}

export default Page;
