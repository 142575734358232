import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import FilterListIcon from "@mui/icons-material/FilterList";

import UtilityButton from "../../components/UtilityButton";
import CustomFilterBadge from "./CustomFilterBadge";
import CustomFilterInput from "./CustomFilterInput";

/**
 * @typedef Filter
 * @param {string} property - Name of the property to be filtered.
 * @param {string} operator - The operator used for filtering, could be eq, ne, lt, gt
 * @param {string} value - Value of the filter.
 */

/**
 * @callback OnChangeCallback
 * @param {Filter[]} filters - Updated list of filters.
 */

/**
 * Control for adding custom filters.
 *
 * @component
 * @param {string[]} facets - The properties that we allow for filtering.
 * @param {Filter} filter - Set this property if you want to add filter from the outside.
 * @param {OnChangeCallback} onChange - Triggered when a new filter is added.
 */
function CustomFilter({ facets = [], filter, onChange }) {
  const [filters, setFilters] = useState([]);
  const [filterInputVisible, setFilterInputVisible] = useState(false);

  // if incoming filter has changed, append it to filters
  // this is a scary way of affecting the filter state from
  // outside this component, but I know not another way save
  // from implementing Redux and that I cannot care for.
  useEffect(() => {
    if (filter) {
      addFilter(filter);
    }
  }, [filter]);

  // adding a filter, hiding the custom filter input
  function addFilter(filter) {
    // update list of filters
    const updated = [...filters, filter];
    setFilters(updated);
    // trigger that filters have been updated
    onChange && onChange(updated);
    // hide the input
    setFilterInputVisible(false);
  }

  // remove filter at a specific filter
  function removeFilter(index) {
    return () => {
      // make a copy of the filter array
      const filterCopy = filters.slice();
      // remove element at index
      filterCopy.splice(index, 1);
      setFilters(filterCopy);
      // trigger that filters have been updated
      onChange && onChange(filterCopy);
    };
  }

  // toggle the visibility of the filter input form
  function toggleFilterInputVisible() {
    setFilterInputVisible(!filterInputVisible);
  }

  return (
    <div>
      <div>
        <label>Filter</label>
        <UtilityButton
          className="mr-2"
          onClick={toggleFilterInputVisible}
          title="Filter"
        >
          <FilterListIcon />
        </UtilityButton>
        {filterInputVisible && (
          <CustomFilterInput facets={facets} onSubmit={addFilter} />
        )}
      </div>
      <div>
        <div
          className="d-flex flex-row my-2 text-lg flex-wrap"
          data-testid="filters"
        >
          {filters.map((filter, i) => (
            <CustomFilterBadge
              key={i}
              property={filter.property}
              operator={filter.operator}
              value={filter.value}
              className="mr-2 mb-2"
              onClick={removeFilter(i)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

CustomFilter.propTypes = {
  facets: PropTypes.arrayOf(PropTypes.string),
  filter: PropTypes.shape({
    property: PropTypes.string.isRequired,
    operator: PropTypes.oneOf(["eq", "ne", "gt", "lt"]),
    value: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func,
};

export default CustomFilter;
