import api from "../../lib/api";
const baseUrl = "/api/v1/transactions/query";

/**
 * @typedef SearchOptions
 * @param {string} query - A free text search query.
 * @param {string[]} filters -  Array of search filters.
 * @param {number} skip - Skip a number of items, used for pagination.
 * @param {number} pageSize - The size of the page of data to acquire.
 * @param {string} orderBy - How to sort the result.
 * @returns
 */

/**
 * Query the API for events.
 *
 * @param {SearchOptions} options - Search query options.
 * @returns {Promise<array>} - Top transactions
 */

async function getBookingsDataAsync(msalInstance, options = {}) {
  const accessToken = await api.getAccessTokenAsync(msalInstance);

  const response = await fetch(baseUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(options)
  });

  if (api.isSuccessful(response)) {
    return await response.json();
  } else {
    throw new Error(`Failed to fetch bookings data}`);
  }
}

export default {
  getBookingsDataAsync,
};
