import React from "react";
import PropTypes from "prop-types";

export default class Typeahead extends React.Component {
  render() {
    const { items, isOpen } = this.props;

    //Do not show Typeahead
    if (!isOpen) return null;
    return (
      <div className="pr-2">
        <div
          className="card shadow "
          style={{ position: "absolute", zIndex: 2, width: "100%" }}
          id="auto-complete-departure"
        >
          <ul className="list-group list-group-flush">
            {items && (items.length > 0) &&
              items.map((item, idx) => {
                return (
                  <li
                    className="list-group-item"
                    key={idx}
                    onClick={() =>
                      this.props.updateValueCB(this.props.items[idx])
                    }
                  >
                    <a href="#" className="stretched-link">
                      {item.name}
                    </a>
                  </li>
                );
              })}
            {!items && <div className="warning">Inget resultat</div>}
          </ul>
        </div>
      </div>
    );
  }
}
Typeahead.propTypes = {
  updateValueCB: PropTypes.func,
  isOpen: PropTypes.bool,
  items: PropTypes.array,
};
