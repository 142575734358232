import React from "react";
import PropTypes from "prop-types";

import GetAppIcon from "@mui/icons-material/GetApp";

import UtilityButton from "../../components/UtilityButton";

/**
 * A button for downloading the product matrix.
 * @param {string} fileId - Primary identifier of the file to be downloaded
 * @param {bool} isDisabled - Block click event when true, default value is false
 * @returns
 */
function DownloadButton({ fileId, isDisabled = false }) {
  function downloadFile(id, ev) {
    // open the URL to the download link will trigger the Save As dialogue
    window.open(`/api/v1/products/matrix/${id}/file`, "_self");
    // should not trigger the open/close event on the table row
    ev.stopPropagation();
  }

  return (
    <UtilityButton
      title="Ladda ner fil"
      onClick={(ev) => downloadFile(fileId, ev)}
      isDisabled={isDisabled}
    >
      <GetAppIcon className="mx-2" />
    </UtilityButton>
  );
}

DownloadButton.propTypes = {
  fileId: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default DownloadButton;
