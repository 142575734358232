import React from "react";
import PropTypes from "prop-types";

import { Card } from "react-bootstrap";
import { getValidTicketNumber } from "../../lib/utils";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import formatter from "./formatter";

function TicketInfo({ ticketData, validity }) {
  var dateTimeOptions = {
    hour12: false,
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return (
    <>
      {ticketData.values.map((info, index) => (
        <Card className="bg-white" key={index}>
          <div className="d-flex m-4">
            <div className="d-flex align-items-center">
              <ConfirmationNumberIcon fontSize="large" />
              <h4 className="mb-0"> {info.productCategory}</h4>
            </div>
            <div className="ml-4">
              <p className="m-0">Resenärer</p>
              <h5>{info.travellers}</h5>
            </div>
            <div className="ml-4">
              <p className="m-0">Giltig</p>
              <h5>
                {new Date(validity).toLocaleString("sv-SE", dateTimeOptions)}
              </h5>
            </div>
            <div className="ml-4">
              <p className="m-0">Pris</p>
              <h5>{info.amount} kr</h5>
            </div>
            <div className="ml-4">
              <p className="m-0">
                Moms (6%)
              </p>
              <h5>{formatter.ToMoms(info.amount).toFixed(2)} kr</h5>
            </div>
            <div className="ml-4">
              <p className="m-0">Biljettnr.</p>
              <h5>
                {getValidTicketNumber(info.tickets)}
              </h5>
            </div>
            <div className="ml-4">
              <p className="m-0">Status</p>
              <h5>{info.status}</h5>
            </div>
          </div>
        </Card>
      ))}
    </>
  );
}
TicketInfo.propTypes = {
  ticketData: PropTypes.object,
  validity: PropTypes.string,
};

export default TicketInfo;
