import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router";

import { Form, InputGroup } from "react-bootstrap";

import SearchIcon from "@mui/icons-material/Search";

/**
 * @callback OnSearchCallback
 * @param {string} query - The string we want to search for.
 */

/**
 * A search form.
 * @param {OnSearchCallback} onSearch - Trigger when a search is performed.
 */
function Search({ onSearch }) {
  const { query } = useParams();
  const [searchValue, setSearchValue] = useState(query ?? "");
  const [hasCleared, setHasCleared] = useState(false);

  useEffect(() => {
    // perform a new search whenever search input is changed
    if ((searchValue && onSearch) || hasCleared) {
      setHasCleared(true);
      onSearch(searchValue);
    }
  }, [searchValue]);

  function submit(ev) {
    // do not reload page on ENTER button
    ev.preventDefault();
  }

  function change(ev) {
    setSearchValue(ev.target.value);
  }

  return (
    <Form className="flex-grow-1">
      <Form.Group className="d-flex">
        <InputGroup className="w-100">
          <InputGroup.Prepend>
            <InputGroup.Text><SearchIcon /></InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="search"
            placeholder="Skriv en sökfras, exempelvis e-post, biljettnr"
            size="lg"
            onSubmit={submit}
            onChange={change}
            role="search"
            value={searchValue}
            className="custom-clear-btn"
          />
        </InputGroup>
      </Form.Group>
    </Form>
  );
}

Search.propTypes = {
  onSearch: PropTypes.func,
};

export default Search;
