import React from "react";
import { useMsalAuthentication } from "@azure/msal-react";

import Routes from "./routes/Routes"

function App () {
  useMsalAuthentication("redirect");
  return(
  <Routes />
  )
}

export default App;
