import React, { useState } from 'react';
import PropTypes from 'prop-types';

import sortOrder from '../../types/sortOrder';
import { Table } from 'react-bootstrap';
import AuditTableRow from './AuditTableRow';
import ColumnSortOrder from '../../components/ColumnSortOrder';
import Spinner from '../../components/Spinner';

/**
 * @typedef Filter
 * @param {string} property - Name of the property to be filtered.
 * @param {string} operator - The operator used for filtering, could be eq, ne, lt, gt
 * @param {string} value - Value of the filter.
 */

/**
 * @callback OnFilterCallback
 * @param {Filter} filter
 */

/**
 * @typedef Order
 * @param {ColumnSortOrder} TimeSpan - The sort order for TimeSpan
 * @param {ColumnSortOrder} RoleId - The sort order for RoleId
 * @param {ColumnSortOrder} TargetId - The sort order for TargetId
 * @param {ColumnSortOrder} OperationId - The sort order for OperationId
 * @param {ColumnSortOrder} Category - The sort order for Category
 * @returns
 */

/**
 * @callback OnSortingCallback
 * @param {Order} order - The sort order.
 */

/**
 * Table with the search data.
 * @param {any} documents - Search result.
 * @param {OnFilterCallback} onFilter - Called when a filter button is clicked.
 * @param {OnSortingCallback} onSorting - Called when a column is sorted.
 */
function AuditTable({ documents = [], isLoading, onFilter, onSorting }) {
  const [order, setOrder] = useState({});

  function toggleSortOrder(column) {
    return () => {
      let current = order[column];
      let newOrder = order;

      // null -> "asc" -> "desc" -> null
      switch (current) {
        case sortOrder.ASC:
          newOrder[column] = sortOrder.DESC;
          break;
        case sortOrder.DESC:
          delete newOrder[column];
          break;
        default:
          newOrder[column] = sortOrder.ASC;
      }

      setOrder(newOrder);
      onSorting && onSorting(newOrder);
    };
  }

  return (
    <Table>
      <thead>
        <tr>
          <th
            colSpan="2"
            onClick={toggleSortOrder('TimeStamp')}
            className="cursor-pointer"
            style={{ minWidth: '208px' }}
          >
            Tidpunkt <ColumnSortOrder order={order.TimeStamp} />
          </th>
          <th onClick={toggleSortOrder('RoleId')} className="cursor-pointer">
            Vem <ColumnSortOrder order={order.RoleId} />
          </th>
          <th onClick={toggleSortOrder('OperationId')} className="cursor-pointer">
            Gjorde <ColumnSortOrder order={order.OperationId} />
          </th>
          <th onClick={toggleSortOrder('TargetId')} className="cursor-pointer">
            Vad <ColumnSortOrder order={order.TargetId} />
          </th>
          <th onClick={toggleSortOrder('Category')} className="cursor-pointer">
            Kategori <ColumnSortOrder order={order.Category} />
          </th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {isLoading && (
          <tr>
            <td colSpan="7">
              <Spinner />
            </td>
          </tr>
        )}
        {documents.map((document) => (
          <AuditTableRow key={document.id} document={document} onFilter={onFilter} />
        ))}
      </tbody>
    </Table>
  );
}

AuditTable.propTypes = {
  documents: PropTypes.array,
  isLoading: PropTypes.bool,
  onFilter: PropTypes.func,
  onSorting: PropTypes.func,
};

export default AuditTable;
