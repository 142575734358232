import api from "../../lib/api";

const baseUrl = "/api/v1/users";

/**
 * Get all users from the API.
 *
 * @returns {Promise<array>} - All users from the API.
 */
async function getAllUsersAsync(msalInstance) {
  const accessToken = await api.getAccessTokenAsync(msalInstance);

  // get all users from the API
  const response = await fetch(baseUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (api.isSuccessful(response)) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch users from the API.");
  }
}

/**
 * Get specific users by id from the API.
 *
 * @returns {Promise<array>} - Specific user by id from the API.
 */
async function getUserByIdAsync(msalInstance, id) {
  const accessToken = await api.getAccessTokenAsync(msalInstance);

  // get specific user by id from the API
  const response = await fetch(`${baseUrl}/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });

  if (api.isSuccessful(response)) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch user from the API.");
  }
}

/**
 * Get a set number of users from the API.
 *
 * @param {string} query - The query used to filter the search.
 * @param {number} pageSize - Number of users to get from the API.
 * @param {string} skipToken - Skip all users before the user with the specified skip token.
 * @returns {Promise<object>} - An object containing the set number of users from the API, the next skip token, and the current page.
 */
async function getUserPageAsync(msalInstance, query, pageSize, skipToken) {

  const accessToken = await api.getAccessTokenAsync(msalInstance);

  const response = await fetch(
    `${baseUrl}/page?pageSize=${pageSize}${query ? `&query=${query}` : ""}${skipToken ? `&skipToken=${skipToken}` : ""
    }`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (api.isSuccessful(response)) {
    return await response.json();
  } else {
    throw new Error(
      `Failed to fetch user page from the API with pageSize ${pageSize} and skipToken ${skipToken}.`
    );
  }
}

/**
 * Disable a user.
 *
 * @param {string} id - Unique identifier of the user.
 * @returns {object} The updated user.
 */
async function disableAsync(msalInstance, id) {
  const accessToken = await api.getAccessTokenAsync(msalInstance);

  // call the API to update the user
  const response = await fetch(`${baseUrl}/${id}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ isActive: false }),
  });

  if (api.isSuccessful(response)) {
    return await response.json();
  } else {
    throw new Error(`Failed to disable user ${id}`);
  }
}

/**
 * Enable a user.
 *
 * @param {string} id - Unique identifier of the user.
 * @returns {object} The updated user.
 */
async function enableAsync(msalInstance, id) {
  const accessToken = await api.getAccessTokenAsync(msalInstance);

  // call the API to update the user
  const response = await fetch(`${baseUrl}/${id}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ isActive: true }),
  });

  if (api.isSuccessful(response)) {
    return await response.json();
  } else {
    throw new Error(`Failed to enable user ${id}`);
  }
}

/**
 * Invite a user to backoffice.
 */
async function inviteUserAsync(msalInstance, invitation) {
  const accessToken = await api.getAccessTokenAsync(msalInstance);

  // call the API to update the user
  const response = await fetch(`${baseUrl}/invite`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(invitation),
  });

  if (api.isSuccessful(response)) {
    return await response.json();
  } else {
    throw new Error(
      `Failed to invite user ${invitation.displayName}, ${invitation.email}.`
    );
  }
}

/**
 * Update user groups for a user.
 * @param {string} id - User id.
 * @param {array} userGroups - An array of group id's.
 * @returns {object} The updated user.
 */
async function updateUserGroupsAsync(msalInstance, id, userGroups) {
  const accessToken = await api.getAccessTokenAsync(msalInstance);

  // call the API to update the user
  const response = await fetch(`${baseUrl}/${id}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userGroups }),
  });

  if (api.isSuccessful(response)) {
    return await response.json();
  } else {
    throw new Error(`Failed to update user groups of user ${id}.`);
  }
}

export default {
  getAllUsersAsync,
  disableAsync,
  enableAsync,
  inviteUserAsync,
  updateUserGroupsAsync,
  getUserByIdAsync,
  getUserPageAsync,
};
