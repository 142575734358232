import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const BackButton = () => {
  let history = useHistory();
  return (
    <>
      <div className="ml-2">
        <Button onClick={() => history.goBack()}>
          <ArrowBackIcon />
          <br />
          Tillbaka
        </Button>
      </div>
    </>
  );
};
