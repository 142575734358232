import React, { Component } from "react";
import PropTypes from "prop-types";

import { InputGroup, FormControl, Tooltip, OverlayTrigger } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";

import Api from "./trafficInfoApi";
import Typeahead from "./Typeahead";

export default class SearchStationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "Stockholm Centralstation",
      startId: "",
      isTypeaheadOpen: false,
      stations: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handelSearchInputChange = this.handelSearchInputChange.bind(this);
  }

  handelSearchInputChange(event) {
    const value = event.target.value;
    this.setState({ search: value });
    this.handleInputChange(value);
  }

  async handleInputChange(value) {
    const searchInput = value;
    if (searchInput && searchInput.length >= 1) {
      this.controller?.abort();
      this.controller = new AbortController();
      const signal = this.controller.signal;
      var locations = await Api.fetchTypeaheadData(searchInput, signal);
      this.setState({ stations: locations });
      this.setState({ isTypeaheadOpen: true });
    } else {
      this.setState({ isTypeaheadOpen: false });
    }
  }

  typeheadClicked(value) {
    this.setState({ search: value.name, isTypeaheadOpen: false });
    this.props.onChange(value.id);
  }

  //Update the value of the input based on what typeahead was used
  typeaheadValue(value) {
    this.setState({
      start: value.name,
      startId: value.id,
      isTypeaheadOpen: false,
    });
  }

  render() {
    return (
      <div className="row mb-2">
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text><SearchIcon /></InputGroup.Text>
          </InputGroup.Prepend>
          <div className="col-4 order-md-1 p-0">
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Sökning endast på mälartåg stationer.</Tooltip>}>

              <FormControl
                onChange={this.handelSearchInputChange}
                value={this.state.search}
                placeholder={this.state.search}
                aria-label="Sök station"
                aria-describedby="basic-addon2"
                size="lg"
                type="search"
                className="custom-clear-btn"
                style={{ borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
              />
            </OverlayTrigger>
            <Typeahead
              id="search"
              isOpen={this.state.isTypeaheadOpen}
              items={this.state.stations}
              updateValueCB={(e) => this.typeheadClicked(e)}
            />
          </div>
        </InputGroup>
      </div>
    );
  }
}
SearchStationBar.propTypes = {
  onChange: PropTypes.func,
};
