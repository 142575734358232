import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import { Button, Modal, Form } from "react-bootstrap";

import Spinner from "../../../components/Spinner";
import api from "../../../hooks/ticketApi";
import { useMsal } from "@azure/msal-react";
/**
 * Callback for hiding the block ticket modal.
 *
 * @callback onHideCallback
 */

/**
 * Callback for successful blocking a ticket.
 *
 * @callback onSuccessCallback
 */

/**
 * Callback for failed to block a ticket.
 *
 * @callback onFailureCallback
 * @param {string} errorMessage - A message describing what went wrong.
 */

/**
 *
 * @param {onHideCallback} onHide - Gets called when user closes the modal.
 * @param {onSuccessCallback} onSuccess - Gets called when user successfully blocks a ticket.
 * @param {onFailureCallback} onFailure - Gets called when block of ticket fails.
 * @returns
 */
function BlacklistTicketModal({
  show,
  onHide,
  onSuccess,
  onFailure,
  ticketData,
  tripData,
}) {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  const reasonRef = useRef();
  const formRef = useRef();

  function validateInput() {
    const form = formRef.current;
    if (form.checkValidity() == true) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  }

  async function blacklistTicket() {
    const form = formRef.current;
    if (form.checkValidity() == false) {
      // form input not valid
    } else {
      setIsLoading(true);
      try {
        await api.blacklistTicketAsync(
          instance,
          ticketData,
          tripData,
          reasonRef.current.value
        );
        onSuccess("Biljett spärrad!");
        setIsLoading(false);
        onHide();
      } catch (error) {
        onFailure(error.message);
        setIsLoading(false);
        onHide();
      }
    }
    // form has been validated
    setValidated(true);
  }

  return (
    <Modal show={show} onHide={onHide} data-testid="send-modal">
      <Modal.Header closeButton>
        <Modal.Title>Är du säker på att du vill spärra biljetten?</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p>
          För att spärra biljetten krävs en anledning till varför den ska
          spärras.
        </p>
        <Form validated={validated} ref={formRef}>
          <Form.Group controlId="reasonInput">
            <Form.Control
              ref={reasonRef}
              type="text"
              placeholder="Anledning"
              required
              onChange={validateInput}
            />
            <Form.Control.Feedback type="valid" />
            <Form.Control.Feedback type="invalid">
              Du måste ange anledning
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
        <p>Efter genomförd spärrning kommer biljetten inte vara brukbar.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={onHide}
          data-testid="send-modal-close-button"
        >
          Avbryt
        </Button>
        <Button
          variant={validated == true ? "success" : "secondary"}
          disabled={isLoading}
          onClick={blacklistTicket}
        >
          {isLoading ? <Spinner /> : "Ja, jag vill spärra biljetten"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

BlacklistTicketModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  ticketData: PropTypes.array,
  tripData: PropTypes.array,
};

/**
 * Open a modal for blocking ticket.
 * @param {onSuccessCallback} onSuccess - Gets called when a ticket is successfully blocked.
 * @param {onFailureCallback} onFailure - Gets called when a ticket block fails.
 */
function BlacklistTicketButton({
  onSuccess,
  onFailure,
  ticketData,
  tripData,
  isButtonDisabled,
}) {
  const [isVisible, setIsVisible] = useState(false);

  function openBlockModal() {
    setIsVisible(true);
  }

  function hideModal() {
    setIsVisible(false);
  }
  return (
    <>
      <div className="ml-2">
        <Button disabled={isButtonDisabled} onClick={openBlockModal}>
          <span className="material-icons">block</span>
          <br />
          Spärra biljett
        </Button>
      </div>
      {isVisible && (
        <BlacklistTicketModal
          show={isVisible}
          onHide={hideModal}
          onSuccess={onSuccess}
          onFailure={onFailure}
          ticketData={ticketData}
          tripData={tripData}
        />
      )}
    </>
  );
}
BlacklistTicketButton.propTypes = {
  isButtonDisabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  ticketData: PropTypes.object,
  tripData: PropTypes.array,
};

export default BlacklistTicketButton;
