import React from "react";
import PropTypes from "prop-types";

/**
 * Callback for closing the success message.
 * @callback onCloseCallback
 */

/**
 * Display an success message with title and close button.
 *
 * @param {string} title - Heading of the dialog.
 * @param {element} children - The contents of the success message.
 * @param {onCloseCallback} onClose - Gets called when close button is pushed.
 */
function SuccessDialog({ title, children, onClose }) {
  return (
    <div className="col-md-12">
      <div className="alert alert-success alert-dismissible" role="alert">
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
          onClick={onClose}
        >
          <span aria-hidden="true">×</span>
        </button>
        <div className="alert-message">
          <strong>{title}</strong> {children}
        </div>
      </div>
    </div>
  );
}

SuccessDialog.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export default SuccessDialog;
