import React from "react";
import PropTypes from "prop-types";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";

import { useIsAuthenticated } from "@azure/msal-react";

const Start = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  return (
    isAuthenticated && (
      <Wrapper>
        <Sidebar location={window.location} />
        {children}
      </Wrapper>
    )
  );
};

Start.propTypes = {
  children: PropTypes.oneOf([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};

export default Start;
